<template>
    <v-container v-if="$ability.can('access', 'settings')">
        <data-table-card
        title="Venues"
        :data_items="venues"
        :data_headers="venueTableHeaders"
        :edit_button="$ability.can('edit', 'settings')"
        :delete_button="$ability.can('edit', 'settings')"
        :add_button="$ability.can('edit', 'settings')"
        :restore_button="$ability.can('edit', 'settings')"
        showDeletedItemsCheckbox
        @deleteItem="deleteVenue"
        @editItem="editVenue"
        @addItem="addVenue"
        @showDeleted="getVenues"
        @restoreItem="restoreVenue"> 
        </data-table-card>   
        <v-dialog
        persistent
        max-width="450"
        v-model="showVenueDialog">
            <v-card>
                <v-card-title>New Venue</v-card-title>
                <v-card-text>
                    <v-select
                    label="Group"                    
                    :items="groups"
                    item-text="displayed_as"
                    item-value="id"
                    v-model="newVenue.group_id"></v-select>
                    <v-text-field
                    v-model="newVenue.displayed_as"
                    label="Name"></v-text-field>
                    <v-text-field
                    v-model="newVenue.shortcode"
                    label="Shortcode"></v-text-field>
                    <v-color-picker
                    label="Colour"
                    v-model="newVenue.colour"
                    dot-size="25"
                    swatches-max-height="200"
                    ></v-color-picker>
                    <v-text-field
                    v-model="newVenue.comment"
                    label="Comment"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    fab
                    dark
                    @click="showVenueDialog = false"
                    color="pink">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                    fab
                    @click="saveVenue"
                    color="primary">
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>     
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>            
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>

    import DataTableCard from '../../components/EpDataTableCard';

    const blankVenue = {
        group_id: null,
        displayed_as: null,
        shortcode: null,
        colour: '#ffffff',
        comment: null,
        deleted_at: null
    }   

    export default{
        name: "Venues",

        data: function(){
            return {
                venues: [],
                venueTableHeaders: config.venues.venueTableHeaders,
                myEos: new EosPortalApi(config.api.root),
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,
                showVenueDialog: false,
                venueEditing: null,
                newVenue: blankVenue, 
                groups: []                  
            }
        }, 

        components: {           
            DataTableCard
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);
            this.getVenues();
            this.getGroups();
        },

        methods: {

            getGroups(){
                if(!this.busy)  {
                    this.busy = true;
                }                 
                this.myEos.Groups().Relations([]).get()
                .then(({data}) => {
                    this.busy = false;
                    this.groups = data;
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            getVenues(withTrashed = false)
            {
                if(!this.busy)  {
                    this.busy = true;
                }                 
                this.myEos.Venues().Relations(['Group']).WithTrashed(withTrashed).get()
                .then(({data}) => {
                    this.busy = false;
                    this.venues = data;
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            addVenue()
            {
                this.newVenue = blankVenue;
                this.showVenueDialog = true;
            },

            editVenue(venue)
            {
                this.$router.push(`/venue/${venue.id}`);
            },

            deleteVenue(venue)
            {
                if(!this.busy)  {
                    this.busy = true;
                } 
                this.myEos.Venue(venue.id)
                .delete()
                .then(({data}) => {
                    this.busy = false;                    
                    this.removeVenueFromTable(venue.id);
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            saveVenue()
            {
                if(!this.busy)  {
                    this.busy = true;
                } 
                this.myEos.Venue()
                .Relations(['Group'])
                .put(this.newVenue)
                .then(({data}) => {
                    this.busy = false;  
                    this.showVenueDialog = false;
                    this.venues.splice(0,0, data);
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            restoreVenue(venue)
            {
                if(!this.busy)  {
                    this.busy = true;
                } 
                this.myEos.Venue(venue.id)
                .restore()
                .then(({data}) => {
                    this.busy = false;
                    this.updateVenueTable(data);
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            updateVenueTable(venue)
            {
                var index = this.venues.findIndex(item => {
                    return item.id === venue.id;
                });
                if(index !== -1)
                    this.venues.splice(index, 1, venue);
            },

            removeVenueFromTable(id)
            {
                var index = this.venues.findIndex(item => {
                    return item.id === id;
                });
                if(index !== -1)
                    this.venues.splice(index, 1);
            }
        }
    }

</script>