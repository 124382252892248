<template>
    <v-container
    v-bind:value="value">
        <v-row>
            <v-col cols=10>
                <template
                v-for="([index, item]) in Object.entries(this.fields)">
                    <v-text-field
                    :disabled="!value"
                    v-if="item.textfield"
                    :label="item.label"                    
                    v-model="item.model"></v-text-field>
                    <v-select
                    :disabled="!value"
                    v-if="item.select"
                    :label="item.label"
                    :items="item.items"
                    item-text="text"
                    item-value="value"
                    v-model="item.model"></v-select>
                    <v-text-field
                    :disabled="!value"
                    v-if="item.password"
                    :type="item.showPass? 'text': 'password'"
                    :append-icon="item.showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="item.showPass = !item.showPass"
                    :label="item.label"                    
                    v-model="item.model"></v-text-field>
                </template>
                    
            </v-col>
            <v-col>
                <v-btn
                fab
                v-if="!value"
                @click="$emit('input', true)"                
                color="primary"
                icon>
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>                            
                <v-btn
                fab
                icon
                dark
                @click="$emit('input', false)"
                v-if="value"
                color="pink">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn
                fab
                icon
                @click="$emit('contentSaved', mapFields())"
                v-if="value"
                color="primary">
                    <v-icon>mdi-content-save</v-icon>
                </v-btn>
            </v-col>
        </v-row>          
    </v-container>
</template>
<script>
    
    export default{
        name: "TextFieldForm",
        props: {
            fields: Object,
            value: Boolean
        },

        methods: {
            mapFields(){
                var fields = {};
                Object.entries(this.fields).forEach(([key, value]) => {
                    fields[key] = value.model;
                }); 
                return fields;               
            }
        }        

    }

</script>