<template>
    <v-container v-if="$ability.can('access', 'settings')">
        <data-table-card
        title="Groups"
        :data_items="groups"
        :data_headers="groupTableHeaders"
        :edit_button="$ability.can('edit', 'settings')"
        :delete_button="$ability.can('edit', 'settings')"
        :add_button="$ability.can('edit', 'settings')"
        :restore_button="$ability.can('edit', 'settings')"
        showDeletedItemsCheckbox
        @deleteItem="deleteGroup"
        @editItem="editGroup"
        @addItem="addGroup"
        @showDeleted="getGroups"
        @restoreItem="restoreGroup">            
        </data-table-card>        
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <dialog-form
        persistent
        v-model="showGroupDialog"
        :fields="groupDialog"
        :title="groupDialogTitle"
        :closeButton="groupDialogButtons.close"
        :saveContentButton="groupDialogButtons.save"
        @close="showGroupDialog = false"
        @saveContent="saveGroup">            
        </dialog-form>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>

    import DataTableCard from '../../components/EpDataTableCard'
    import DialogForm from '../../components/EpDialogForm'    

    const blankGroup = {
        id: null,
        displayed_as: null, 
        deleted_at: null
    }

    export default{
        name: "Groups",
        
        data: function(){
            return {
                groups: [],
                groupTableHeaders: config.groups.groupTableHeaders,
                myEos: new EosPortalApi(config.api.root),
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,
                groupDialog: {
                    group_name: {
                        type: "text",
                        label: "Group Name",
                        model: null
                    }
                },
                groupDialogTitle: "New Group",
                groupDialogButtons: config.dialogButtons,
                showGroupDialog: false,
                groupEditing: null                    
            }
        },

        components: {
            DialogForm,
            DataTableCard
        },

        computed: {
            currentUser: function()
            {
                return this.$store.state.user;
            }
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);
            this.getGroups();
        },

        methods: {
            getGroups(withTrashed = false){
                if(!this.busy)  {
                    this.busy = true;
                }                 
                this.myEos.Groups().WithTrashed(withTrashed).get()
                .then(({data}) => {
                    this.busy = false;
                    this.groups = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = err.message;
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            deleteGroup(group)
            {
                if(!this.busy)  {
                    this.busy = true;
                } 
                this.myEos.Group(group.id)
                .delete()
                .then(({data}) => {
                    this.busy = false;
                    this.removeGroupFromTable(group.id);
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = err.message;
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            restoreGroup(group)
            {
                if(!this.busy)  {
                    this.busy = true;
                } 
                this.myEos.Group(group.id)
                .restore()
                .then(({data}) => {
                    this.busy = false;
                    this.updateGroupTable(data);
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = err.message;
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            editGroup(group)
            {
                this.groupEditing = group;
                this.groupDialog.group_name.model = group.displayed_as;
                this.groupDialogTitle = "Edit Group";
                this.showGroupDialog = true;
            },

            addGroup()
            {
                this.groupEditing = blankGroup; 
                this.groupDialog.group_name.model = null;
                this.groupDialogTitle = "New Group";               
                this.showGroupDialog = true;
            },

            saveGroup(group)
            {
                this.groupEditing.displayed_as = group.group_name.model;
                if(this.groupEditing.id === null)
                    this.saveNewGroup(this.groupEditing);
                else
                    this.updateGroup(this.groupEditing);
            },

            saveNewGroup(group)
            {
                if(!this.busy)  {
                    this.busy = true;
                } 
                this.myEos.Group()
                .put(group)
                .then(({data}) => {
                    this.busy = false;
                    this.showGroupDialog = false;
                    this.groups.splice(0, 0, data);
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = err.message;
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            updateGroup(group)
            {
                if(!this.busy)  {
                    this.busy = true;
                } 
                this.myEos.Group(group.id)
                .patch(group)
                .then(({data}) => {
                    this.busy = false;
                    this.showGroupDialog = false;
                    this.updateGroupTable(data);
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = err.message;
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            updateGroupTable(group)
            {
                var index = this.groups.findIndex(item => {
                    return item.id === group.id;
                });
                if(index !== -1)
                    this.groups.splice(index, 1, group);
            },

            removeGroupFromTable(id)
            {
                var index = this.groups.findIndex(item => {
                    return item.id === id;
                });
                if(index !== -1)
                    this.groups.splice(index, 1);
            }

        }
    }

</script>