<template>
    <v-container v-if="$ability.can('access', 'settings')">
        <v-card class="mb-4">
            <v-card-title>Public Holidays</v-card-title>
            <v-card-text>
                <v-data-table
                :headers="BhHeaders"
                :items="bank_holidays"></v-data-table>
            </v-card-text>
        </v-card>
        <v-card>
            <v-card-title>Company Events</v-card-title>
            <v-card-text>
                <v-data-table
                :items="company_events"
                :headers="CompanyEventHeaders">
                    <template v-slot:item.actions="{ item }">
                        <v-btn
                        v-if="$ability.can('edit', 'settings')"
                        icon
                        @click="deleteEvent(item)"
                        color="pink">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn
                        v-if="$ability.can('edit', 'settings')"
                        icon
                        @click="editEvent(item)"
                        color="primary">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
                <v-btn
                v-if="$ability.can('edit', 'settings')"
                fab
                absolute
                bottom
                right
                @click="addEvent"
                color="primary">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-card-text>
        </v-card>
        <dialog-form
        persistent
        v-model="showEventDialog"
        :fields="eventDialogFields"
        :title="eventDialogTitle"
        :closeButton="eventDialogButtons.close"
        :saveContentButton="eventDialogButtons.save"
        @close="showEventDialog = false"
        @saveContent="saveEvent">            
        </dialog-form>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>

    import axios from 'axios';
    import DialogForm from '../../components/EpDialogForm';    

    const blankEvent = {
        id: null,
        event_type: null,
        displayed_as: null,
        date_of_event: null
    };

    export default{
        name: "Events",

        data: function()
        {
            return {
                bank_holidays: [],
                company_events: [],
                BhHeaders: config.events.BhHeaders,
                CompanyEventHeaders: config.events.CompanyEventHeaders,
                myEos: new EosPortalApi(config.api.root),
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,
                showEventDialog: false,
                eventEditing: null,
                eventDialogFields: {
                    event_type: {
                        type: "select",
                        label: "Event Type",
                        items: config.events.EventTypes,
                        model: null
                    },
                    displayed_as: {
                        type: "text",
                        label: "Event Name",
                        model: null
                    },
                    date_of_event: {
                        type: "date",
                        label: "Date of Event",
                        model: null
                    }
                },
                eventDialogTitle: "New Event",
                eventDialogButtons:config.dialogButtons
            }
        },

        components: {
            DialogForm
        },

        mounted(){
            this.getBankHolidays();
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);
            this.getCompanyEvents();
        },

        computed: {
            bankHolidayApi: function()
            {
                return config.bankHolidayApi;
            }
        },

        methods: {
            getBankHolidays()
            {
                axios.get(this.bankHolidayApi)
                .then(({data}) => {
                    this.bank_holidays = this.filterBankHolidaysByYear(data['england-and-wales'].events);
                })
            },

            getCompanyEvents()
            {
                if(!this.busy)  {
                    this.busy = true;
                }    
                this.myEos.CompanyEvents().get()
                .then(({data}) => {
                    this.busy = false;
                    this.company_events = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = err.message;
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            deleteEvent(event)
            {
                if(!this.busy)  {
                    this.busy = true;
                }  
                this.myEos.CompanyEvent(event.id)
                .delete()
                .then(({data}) => {
                    this.busy = false;
                    this.removeEventFromTable(event.id);
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = err.message;
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            addEvent()
            {
                this.eventEditing = blankEvent;
                this.showEventDialog = true;
            },

            editEvent(event)
            {   
                this.eventEditing = event;
                this.eventDialogTitle = "Edit Event";
                this.eventDialogFields.displayed_as.model = event.displayed_as;
                this.eventDialogFields.event_type.model = event.event_type;
                this.eventDialogFields.date_of_event.model = event.date_of_event;
                this.showEventDialog = true;
            },

            saveEvent(event)
            {
                event = this.mapDialogReturnedFields(event);
                if(event.id)
                    this.updateEvent(event);
                else
                    this.saveNewEvent(event);
            },

            saveNewEvent(event)
            {
                if(!this.busy)  {
                    this.busy = true;
                }                  
                this.myEos.CompanyEvent()
                .put(event)
                .then(({data}) => {
                    this.busy = false;
                    this.showEventDialog = false;
                    this.company_events.splice(0,0, data);
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = err.message;
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            updateEvent(event)
            {
                if(!this.busy)  {
                    this.busy = true;
                }                  
                this.myEos.CompanyEvent(event.id)
                .patch(event)
                .then(({data}) => {
                    this.busy = false;
                    this.showEventDialog = false;
                    this.updateEventTable(event);
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = err.message;
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            filterBankHolidaysByYear(data)
            {
                return data.filter(item => {
                    return dayjs().isSame(item.date, 'year');
                });
            },

            mapDialogReturnedFields(returnedFields)
            {
                Object.entries(returnedFields).forEach(([key, value]) => {
                    if(this.eventEditing.hasOwnProperty(key))
                        this.eventEditing[key] = value.model;
                });
                return this.eventEditing;
            },

            updateEventTable(event)
            {
                var index = this.company_events.findIndex(item => {
                    return item.id === event.id;
                });
                if(index !== -1)
                    this.company_events.splice(index, 1, event);
            },

            removeEventFromTable(id)
            {
                var index = this.company_events.findIndex(item => {
                    return item.id === id;
                });
                if(index !== -1)
                    this.company_events.splice(index, 1);
            }
        }
    }

</script>