<template>
    <v-card max-width="350" class="mx-auto">
        <div class="text-center p-3 black white--text">
            <v-card-title class="justify-center">{{title}}</v-card-title>           
        </div>           
        <v-card-text>                
            <div class="text-justify py-2">
                {{message}}
            </div>

            <v-text-field
            v-model="verification_code"
            label="Enter your verification code"></v-text-field>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn 
            text
            color="primary" 
            @click="$emit(callback1, verification_code)">{{buttonText}}</v-btn>

            <v-btn 
            text
            v-if="showButton2"
            color="secondary" 
            @click="$emit(callback2, verification_code)">{{button2Text}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>

    export default{
        name: 'GetVerificationCode',
        props: {
            title: String,
            message: String,
            buttonText: String,
            button2Text: String,
            showButton2: Boolean,
            callback1: String,
            callback2: String
        }, 
        data: function(){
            return {
                verification_code: null
            }
        }
    }

</script>