<template>
    <div class="overflow-x-auto ma-6">
        <div class="text-h4 mb-4">Training Record</div>
        <v-card flat>
            <v-row flat class="pa-8 mb-4">
                <v-col>
                    <v-text-field
                    @change="getTraining"
                    type="date"
                    v-model="period_start"
                    label="From"></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                    @change="getTraining"
                    type="date"
                    v-model="period_end"
                    label="To"></v-text-field>
                </v-col>
            </v-row> 
            <v-card flat>
                <v-card-text>
                    <v-data-table
                    :headers="trainingTableHeaders"
                    :items="training"> 

                        <template v-slot:item.evidence="{ item }">                                    
                            <v-btn
                            v-if="item.evidence !== null"
                            icon
                            @click="openEvidence(item)"
                            color="teal accent-3">
                                <v-icon>mdi-folder-open</v-icon>
                            </v-btn>                                                            
                        </template>                       
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-card>
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>

    export default{
        name: "TrainingUserHome",

        props: {

        },

        data: function(){
            return {
                training: [],
                mandatoryTrainingTypes: [],
                myEos: new EosPortalApi(config.api.root),
                myS3: new EosS3Client(config.cognito.identityPoolId, config.cognito.region, config.cognito.UserPoolId, this.$store.state.session.session.idToken.jwtToken),
                period_start: dayjs().startOf('year').format('YYYY-MM-DD'),
                period_end: dayjs().endOf('year').format('YYYY-MM-DD'),
                trainingTableHeaders: config.training.trainingTableHeaders,
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,  
            }
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken); 
            this.getTraining();
        },

        computed: {
            user: function(){
                return this.$store.state.user;
            }
        },

        methods: {
            getTraining(){
                if(!this.user)
                    return;
                if(!this.busy)  {
                    this.busy = true;
                }  
                this.myEos.Trainings().Relations(['User'])
                .Filters({
                    from: this.period_start,
                    to: this.period_end,
                    user_id: this.user.id
                }).get()
                .then(({data}) => {
                    this.busy = false;
                    this.training = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            openEvidence(training){
                if(!this.busy)  {
                    this.busy = true;
                }                
                this.myS3.GetObject(config.Resources.bucket, training.evidence, config.cognito.UserPoolId)
                .then(data => {    
                    this.busy = false;                   
                    return new Response(data.Body, {headers: {"Content-Type": this.returnContentType(training.evidence)}}).blob();
                })  
                .then(data => {                    
                    var uri = URL.createObjectURL(data);
                    window.open(uri, "_blank");
                })             
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                })
            },

            returnContentType(content)           
            {
                const contentTypes = config.Resources.ContentTypes;
                var fileExtension = content.split('.');
                fileExtension = fileExtension[fileExtension.length-1]; //the last element
                if(contentTypes.hasOwnProperty(fileExtension))
                    return contentTypes[fileExtension];
                return "application/octet-stream";
            },
        }
    }

</script>