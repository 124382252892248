<template>
    <v-card v-if="myuser">
        <v-card-title>Leave Requests for {{myuser.name}}</v-card-title>
        <v-card-text>
            <v-data-table
            :items="myuser.holiday_request"
            :headers="leaveRequestHeaders"> 
                <template v-slot:item.from="{item}">
                    {{ displayNiceDate(item.from, 'DD-MM-YYYY') }}
                </template>  
                <template v-slot:item.to="{item}">
                    {{ displayNiceDate(item.to, 'DD-MM-YYYY') }}
                </template> 
                <template v-slot:item.actions="{item}">
                    <v-btn
                    v-if="!item.approved"
                    @click="deleteRequest(item)"
                    icon>
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>                    
                </template>             
            </v-data-table>
        </v-card-text>
        <v-btn
        @click="newLeaveRequest"
        color="primary"
        fab
        absolute
        bottom 
        right>
            <v-icon>mdi-plus</v-icon>
        </v-btn>
        <leave-request-form
        :fields="userLeaveRequest"
        :user="myuser"
        @saveRequest="saveLeaveRequest"
        @close="showDialogForm = false"
        :showDialog="showDialogForm"></leave-request-form>
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script>

    import LeaveRequestForm from './LeaveRequestForm';    

    const blankRequest = {
        user_id: null,
        leave_type: null,
        from: null,
        to: null,        
        request_comment: null
    };

    export default{
        name: "LeaveHome", 

        props: {
            user: String
        },

        data: function()
        {
            return {
                myuser: null,
                myAws: new EosAwsCognito(EosPoolData),
                myEos: new EosPortalApi(config.api.root),
                leaveRequestHeaders: config.users.leaveRequestHeaders,
                showDialogForm: false,
                userLeaveRequest: blankRequest,
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false
            }
        },

        components: {
            LeaveRequestForm
        },

        mounted(){
            this.getUser();
        },      

        methods: {
            getUser()
            {
                if(!this.busy)  {
                    this.busy = true;
                }                
                this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken)
                .User(this.user)
                .Relations(['HolidayAllowance', 'HolidayRequest.Approver', 'HolidayRequest.User', 'Permission'])
                .get()
                .then(({data}) => {
                    this.busy = false;
                    this.myuser = data;                    
                })                               
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            newLeaveRequest()
            {
                this.userLeaveRequest = blankRequest;
                this.showDialogForm = true;
            },

            saveLeaveRequest(fields)
            {                
                if(this.IsLessThanMinimumRequestPeriod(fields.from))
                {
                    this.alertText = "Sorry leave must be requested a minimum of "+config.LeaveMinimumRequestPeriod+" days in advance";
                    this.alertType = 'error';
                    this.showAlert = true;
                    return;
                }
                if(!this.busy)  {
                    this.busy = true;
                }             
                this.myEos.HolidayRequest()
                .Relations(['Approver', 'User'])
                .put(fields)
                .then(({data}) => {
                    this.showDialogForm = false;
                    this.busy = false;
                    if(data.user_id === this.myuser.id)
                        this.myuser.holiday_request.splice(0,0, data);
                })                                
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            displayNiceDate(date)
            {
                return dayjs(date).format('DD-MM-YYYY');
            },

            deleteRequest(request)
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.HolidayRequest(request.id)
                .delete()
                .then(({data}) => { 
                    this.busy = false;
                    this.alertText = 'Request deleted';
                    this.alertType = 'success';
                    this.showAlert = true;                   
                    this.removeRequestFromList(request);
                })                                
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            removeRequestFromList(request)
            {
                var index = this.myuser.holiday_request.findIndex(req => {
                    return request.id === req.id;
                });
                if(index !== -1)
                    this.myuser.holiday_request.splice(index,1);
            },

            IsLessThanMinimumRequestPeriod(startDate)
            {
                return dayjs(startDate).diff(dayjs(), 'day') < config.LeaveMinimumRequestPeriod;
            }
        }
    }

</script>