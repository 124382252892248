import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    session: null,
    user: null,
    redirectAfterLogin: null,
    clinic_to_reconcile: null
  },
  mutations: {
    setSession(state, newsession)
    {
        state.session = newsession
    },

    setUser(state, newuser)
    {
        state.user = newuser
    },

    setClinic(state, newclinic)
    {
        state.clinic_to_reconcile = newclinic
    },

    setRedirectAfterLogin(state, path)
    {
        state.redirectAfterLogin = path
    }
  },
  actions: {
  },
  modules: {
  }
})
