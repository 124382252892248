<template>
    <div>
        <v-toolbar flat>
            <v-toolbar-title>{{title}}</v-toolbar-title>
        </v-toolbar>
        <table class="work_calendar_table">
            <thead>
                <tr>
                    <th></th>
                    <th v-for="day in daysInMonth">{{day}}</th>
                </tr>
                <tr>
                    <th></th>
                    <th v-for="day in daysInMonth">{{dayOfMonth(day)}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="member in staff">
                    <td>{{member.name}}</td>
                    <td v-for="day in daysInMonth">   
                        <template v-if="clinicOnDateWithUser(dateOfMonth(day), member).length">
                            <work-calendar-tile
                            v-for="clinic in clinicOnDateWithUser(dateOfMonth(day), member)"
                            :contentData="{
                                date: dateOfMonth(day),
                                employee: member,
                                clinic: clinic,
                                group: title,
                                bank_holiday: bankHolidayOnDate(dateOfMonth(day)),
                                company_event: companyEventOnDate(dateOfMonth(day))
                            }"
                            @click="processCalendarTileClickEvent"
                            ></work-calendar-tile>
                        </template>                     
                        <template v-else>
                            <work-calendar-tile                            
                            :contentData="{
                                date: dateOfMonth(day),
                                employee: member,
                                clinic: null,
                                group: title,
                                bank_holiday: bankHolidayOnDate(dateOfMonth(day)),
                                company_event: companyEventOnDate(dateOfMonth(day))
                            }"
                            @click="processCalendarTileClickEvent"
                            ></work-calendar-tile>
                        </template>                    
                    </td>
                </tr>
                <tr v-if="unassignedClinics.length">
                    <td>Unassigned</td>
                    <td v-for="day in daysInMonth">
                        <template v-if="clinicOnDateUnassigned(dateOfMonth(day))">
                            <work-calendar-tile
                            v-for="clinic in clinicOnDateUnassigned(dateOfMonth(day))"
                            :contentData="{
                                date: dateOfMonth(day),
                                employee: null,
                                clinic: clinic,
                                group: title,
                                bank_holiday: bankHolidayOnDate(dateOfMonth(day)),
                                company_event: companyEventOnDate(dateOfMonth(day))
                            }"
                            @click="processCalendarTileClickEvent"
                            ></work-calendar-tile> 
                        </template>                        
                    </td>
                </tr>
            </tbody>
        </table>
    </div>    
</template>

<script>
    import WorkCalendarTile from './EpWorkCalendarTile';

    export default{
        name: "WorkCalendar",

        props: {
            month: Number,
            year: Number,
            staff: Array,
            title: String,
            clinics: Array,
            bank_holidays: Array,
            company_events: Array
        },

        data: function(){
            return {

            }
        },

        components: {
            WorkCalendarTile
        },        

        computed: {
            firstOfMonth: function(){
                return dayjs().year(this.year).month(this.month).date(1);
            },

            lastOfMonth: function(){
                return this.firstOfMonth.endOf('month');
            },

            daysInMonth: function(){
                return this.lastOfMonth.daysInMonth();
            },
            unassignedClinics: function()
            {
                return this.clinics.filter(clinic => {
                    return clinic.user.length === 0;
                });
            }            
        },

        methods: {
            dateOfMonth(date)
            {
                return dayjs().year(this.year).month(this.month).date(date);
            },

            dayOfMonth(date)
            {
                return this.dateOfMonth(date).format('ddd');
            },

            clinicOnDateWithUser(date, user)
            {               
                return this.clinics.filter(clinic => {
                    return dayjs(clinic.clinic_date).isSame(date, 'day') && this.clinicIncludesUser(clinic, user);
                }) ?? null;
            },

            clinicOnDateUnassigned(date)
            {               
                return this.clinics.filter(clinic => {
                    return dayjs(clinic.clinic_date).isSame(date, 'day') && clinic.user.length === 0;
                }) ?? null;
            },

            processCalendarTileClickEvent(eventData)
            {
                this.$emit('click', eventData);
            },
            
            clinicIncludesUser(clinic, user)
            {
                return clinic.user.find(clinic_user => {
                    return clinic_user.id === user.id;
                }) !== undefined;
            },

            bankHolidayOnDate(date)            
            {
                return this.eventOnDate(date, this.bank_holidays, 'date');
            },

            companyEventOnDate(date)
            {
                return this.eventOnDate(date, this.company_events, 'date_of_event');
            },

            eventOnDate(date, eventArray, target)
            {
                return eventArray.find(event => {                    
                    return dayjs(event[target]).isSame(dayjs(date), 'day');
                }) ?? null;
            }
        }
    }

</script>