<template>
    <v-container v-if="$ability.can('view', 'reconciliations')">
        <v-data-table
        v-if="myclinic"
        :headers="ReconciliationTableHeaders"
        :items="myclinic.clinic_reconciliation">
            <template v-slot:item.actions="{item}">
                <v-btn
                icon
                :to="'/view_screening_data/'+item.id"
                color="primary">
                    <v-icon>mdi-magnify</v-icon>
                </v-btn>
            </template>
        </v-data-table>
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
    </v-container>        
</template>

<script>

    export default{
        name: "ViewReconciliation",

        props: {
            clinic: String
        },

        data: function(){
            return {
                myclinic: null,
                myEos: new EosPortalApi(config.api.root),
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,
                ReconciliationTableHeaders: config.ClinicReconciliations.ReconciliationTableHeaders
            }
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);
            this.getClinic();
        },

        computed: {

        },

        methods: {
            getClinic(){
                if(!this.busy)  {
                    this.busy = true;
                }                
                this.myEos.Clinic(this.clinic).Relations(['ClinicReconciliation.Clinic.Venue', 'User', 'Venue']).get()
                .then(({data}) => {
                    this.busy = false;
                    this.myclinic = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            }
        }
    }

</script>