<template>
    <v-card>
        <v-card-title>
            {{title}}
            <v-spacer></v-spacer>
            <v-checkbox
            v-model="showDeleted"
            label="Show Deleted"
            @change="$emit('showDeleted', showDeleted)"
            v-if="showDeletedItemsCheckbox"></v-checkbox>
        </v-card-title>
        <v-card-text>
            <v-data-table
            :items="data_items"
            :headers="data_headers">
                <template 
                v-for="items in itemSlotNames"
                v-slot:[items]="{ item }">
                    {{itemSlotCallback(items, item)}}
                </template>      
                <template v-slot:item.actions="{item}">
                    <v-btn
                    v-if="edit_button && (item.deleted_at === null || item.deleted_at === undefined)"
                    @click="$emit('editItem', item)"
                    color="primary"
                    icon>
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                    v-if="delete_button && (item.deleted_at === null || item.deleted_at === undefined)"
                    @click="$emit('deleteItem', item)"
                    color="pink"
                    dark
                    icon>
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                    v-if="restore_button && item.deleted_at !== null"
                    @click="$emit('restoreItem', item)"
                    color="teal lighten-2"
                    dark
                    icon>
                        <v-icon>mdi-delete-restore</v-icon>
                    </v-btn>                    
                </template>
            </v-data-table>
            <v-btn
            v-if="add_button"
            fab
            absolute
            bottom
            right
            @click="$emit('addItem')"
            color="primary">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>

    export default{
        name: "DataTableCard",

        props: {
            title: String,
            data_headers: Array,
            data_items: Array,
            delete_button: {
                type: Boolean,
                default: false
            },

            edit_button: {
                type: Boolean,
                default: false
            },

            add_button: {
                type: Boolean,
                default: false
            },
            showDeletedItemsCheckbox: {
                type: Boolean,
                default: false
            },
            restore_button: {
                type: Boolean,
                default: false
            },            
            itemSlotCallback: { //a callback function which takes two arguments the item name as first and the item object as second
                type: Function,                
            },
            itemSlotNames: { //and array of named slots
                type: Array,                
            }
        },

        data: function(){
            return {
                showDeleted: false
            }
        }
    }

</script>