const mainMixins = {
    methods: {
        displayApiErrors (errorReturned) {
          var alertText = ''
          if (errorReturned.response) {
            alertText = `${errorReturned.message} ${errorReturned.response.data.message}`
            if (errorReturned.response.data.errors) {
              // eslint-disable-next-line no-unused-vars
              for (const [key, value] of Object.entries(errorReturned.response.data.errors)) {
                value.forEach(message => {
                  alertText += ` ${message}`
                })
              }
            }
          } else if (errorReturned.request) {
            alertText = `${errorReturned.message} ${errorReturned.request}`
          } else {
            alertText = errorReturned.message
          }
          return alertText
        }
    }
}

export default mainMixins
export { mainMixins }