<template>
    <div>
        <data-table-card
        v-if="clinic_to_reconcile"
        :title="`${clinic_to_reconcile.venue.displayed_as} on ${formattedClinicDate}`"
        :data_items="clinic_to_reconcile.clinic_reconciliation"
        :data_headers="ReconciliationTableHeaders"
        :edit_button="clinic_to_reconcile.clinic_reconciliation? true: false"
        :delete_button="clinic_to_reconcile.clinic_reconciliation? true: false"
        :add_button="!clinic_to_reconcile.clinic_reconciliation.length"
        @deleteItem="deleteClinicReconciliation"
        @editItem="editClinicReconciliation"
        @addItem="addClinicReconciliation">            
        </data-table-card>
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>

    import DataTableCard from '../../components/EpDataTableCard';
    

    export default{
        name: "ClinicReconciliations",

        props: {
            clinic: {
                type: String
            }
        },

        data: function(){
            return {
                myEos: new EosPortalApi(config.api.root),
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,
                ReconciliationTableHeaders: config.ClinicReconciliations.ReconciliationTableHeaders
            }
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);
        },

        components: {
            DataTableCard
        },

        computed: {
            user: function(){
                return this.$store.state.user;
            },

            clinic_to_reconcile: function(){
                if(this.$store.state.clinic_to_reconcile && this.$store.state.clinic_to_reconcile.id === this.clinic)
                    return this.$store.state.clinic_to_reconcile
                this.getClinic();
            },

            formattedClinicDate: function(){
                return dayjs(this.clinic_to_reconcile.clinic_date).format('ddd Do MMM YYYY');
            }
        },

        methods: {
            getClinic()
            {
                if(!this.busy)  {
                    this.busy = true;
                }  
                this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);              
                this.myEos.Clinic(this.clinic).Relations(['ClinicReconciliation.Clinic.Venue', 'User', 'Venue', 'ClinicReconciliation.Screening.Grading']).get()
                .then(({data}) => {
                    this.busy = false;
                    this.$store.commit('setClinic', data);
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            addClinicReconciliation(){
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.ClinicReconciliation().Relations(['Screening.Grading'])
                .put({
                    clinic_id: this.clinic_to_reconcile.id,
                    user_id: this.user.id
                })
                .then(({data}) => {
                    this.busy = false;                    
                    this.$router.push(`/reconciliation/${data.id}`);
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
                
            },
            
            editClinicReconciliation(event){
                this.$router.push(`/reconciliation/${event.id}`);
            },
            
            deleteClinicReconciliation(event){   
                if(event.screening.length)
                {
                    this.alertText = "Please delete any screening data associated with this episode before trying to delete it";
                    this.alertType = 'error';
                    this.showAlert = true;
                    return;
                }
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.ClinicReconciliation(event.id).Relations([])
                .delete()
                .then(({data}) => {
                    this.busy = false;                    
                    this.$store.commit('setClinic', null);
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            }
        }
    }

</script>