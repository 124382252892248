import axios from 'axios';

class EosPortalApi
{    

    constructor(path)
    {        
        this.api_root = path;
        this.token = null;  
        this.relations = null;
        this.filters = null; 
        this.withTrashed = '';              
    }

    /////////////////////////////////////////////////////////////////////////////////
    // Actions
    ////////////////////////////////////////////////////////////////////////////////

    get()
    {
        return this.makeApiCall('get', `${this.endpoint}${this.createParamaters('get')}`);
    }

    post(data = [], additionalHeaders = [])
    {
        return this.makeApiCall('post', this.endpoint, this.addPostStyleRelations(data), additionalHeaders);
    }

    put(data = [], additionalHeaders = [])
    {
        return this.makeApiCall('put', this.endpoint, this.addPostStyleRelations(data), additionalHeaders);
    }

    patch(data = [], additionalHeaders = [])
    {
        return this.makeApiCall('patch', this.endpoint, this.addPostStyleRelations(data), additionalHeaders);
    }

    delete(data = [], additionalHeaders = [])
    {
        return this.makeApiCall('delete', this.endpoint, this.addPostStyleRelations(data), additionalHeaders);
    }

    restore(data = [], additionalHeaders = [])
    {
        return this.makeApiCall('post', this.endpoint, this.addPostStyleRelations(data), additionalHeaders);
    }

    /////////////////////////////////////////////////////////////////////////////////////
    // relations
    /////////////////////////////////////////////////////////////////////////////////////

    Relations(relations)
    {
        this.relations = relations;        
        return this;
    }

    /////////////////////////////////////////////////////////////////////////////////////
    // filters
    /////////////////////////////////////////////////////////////////////////////////////
    Filters(filters)
    {
        this.filters = '';
        Object.entries(filters).forEach(([key, value]) => {
            this.filters += `${key}=${value}&`;
        })
        return this;
    }

    /////////////////////////////////////////////////////////////////////////////////////
    // with Trashed
    /////////////////////////////////////////////////////////////////////////////////////
    WithTrashed(value)
    {
        if(value)
            this.withTrashed = 'withTrashed';
        else
            this.withTrashed = '';
        return this;
    }

    /////////////////////////////////////////////////////////////////////////////////////
    // Set Auth token
    /////////////////////////////////////////////////////////////////////////////////////

    setAuthToken(token)
    {
        this.token = token;
        return this;
    }

    /////////////////////////////////////////////////////////////////////////////////////
    // endpoint setters
    ////////////////////////////////////////////////////////////////////////////////////

    Approver(user = null)
    {
        this.endpoint = user? `/approver/${user}` : `/approver`;
        return this;
    }

    Register()
    {
        this.endpoint = '/register';
        return this;
    }

    Users()
    {
        this.endpoint = '/users';
        return this;
    }

    User(user = null)
    {
        this.endpoint = user? `/user/${user}` : `/user`;
        return this;
    }

    UserGroup(user = null)
    {
        this.endpoint = user? `/group-user/${user}` : `/group-user`;
        return this;
    }

    Permissions()
    {
        this.endpoint = `/permissions`;
        return this;
    }

    Permission(user = null)
    {
        this.endpoint = user? `/permission/${user}` : `/permission`;
        return this;
    }

    Groups()
    {
        this.endpoint = '/groups';
        return this;
    }

    Group(group = null)
    {
        this.endpoint = group? `/group/${group}` : `/group`;
        return this;    
    }

    Venues()
    {
        this.endpoint = '/venues';
        return this;
    } 

    Venue(venue = null )
    {
        this.endpoint = venue? `/venue/${venue}` : `/venue`;
        return this;
    }

    VenueContacts()
    {
        this.endpoint = '/venue_contacts';
        return this;
    } 

    VenueContact(venueContact = null)
    {
        this.endpoint = venueContact? `/venue_contact/${venueContact}` : `/venue_contact`;
        return this;
    }

    VenueAddress(venueAddress = null)
    {
        this.endpoint = venueAddress? `/venue_address/${venueAddress}` : `/venue_address`;
        return this;
    } 

    Clinics()
    {
        this.endpoint = '/clinics';
        return this;
    }

    Clinic(clinic = null)
    {
        this.endpoint = clinic? `/clinic/${clinic}` : `/clinic`;
        return this;
    } 

    ClinicUser(clinic)
    {
        if(!clinic)
            throw 'Clinic ID cannot be null';
        this.endpoint = `/clinic_user/${clinic}`;
        return this;
    } 

    ClinicReconciliations()
    {
        this.endpoint = `/clinic_reconciliations`;
        return this;
    } 

    ClinicReconciliation(clinicReconciliation = null)
    {
        this.endpoint = clinicReconciliation? `/clinic_reconciliation/${clinicReconciliation}` : `/clinic_reconciliation`;
        return this;
    } 

    Screenings()
    {
        this.endpoint = '/screenings';
        return this;
    }

    Screening(screening = null)
    {
        this.endpoint = screening? `/screening/${screening}` : `/screening`;
        return this;
    } 

    Gradings()
    {
        this.endpoint = '/gradings';
        return this;
    } 

    Grading(grading = null)
    {
        this.endpoint = grading? `/grading/${grading}` : `/grading`;
        return this;
    }   

    HolidayAllowances()
    {
        this.endpoint = '/holiday_allowances';
        return this;
    } 

    HolidayAllowance(holidayAllowance = null)
    {
        this.endpoint = holidayAllowance? `/holiday_allowance/${holidayAllowance}` : `/holiday_allowance`;
        return this;
    } 

    HolidayRequests()
    {
        this.endpoint = '/holiday_requests';
        return this;
    }

    HolidayRequest(holidayRequest = null)
    {
        this.endpoint = holidayRequest? `/holiday_request/${holidayRequest}` : `/holiday_request`;
        return this;
    }

    ScheduleTypes()
    {
        this.endpoint = '/schedule_types';
        return this;
    }

    ScheduleType(scheduleType = null)
    {
        this.endpoint = scheduleType? `/schedule_type/${scheduleType}` : `/schedule_type`;
        return this;
    }

    CompanyEvents()
    {
        this.endpoint = '/company_events';
        return this;
    }

    CompanyEvent(companyEvent = null)
    {
        this.endpoint = companyEvent? `/company_event/${companyEvent}` : `/company_event`;
        return this;
    }

    Trainings()
    {
        this.endpoint = '/trainings';
        return this;
    }

    Training(training = null)
    {
        this.endpoint = training? `/training/${training}` : `/training`;
        return this;
    }

    MandatoryTrainings()
    {
        this.endpoint = '/mandatory_trainings';
        return this;
    }

    MandatoryTraining(mandatory_training = null)
    {
        this.endpoint = mandatory_training? `/mandatory_training/${mandatory_training}` : `/mandatory_training`;
        return this;
    }

    ReferralQueues()
    {
        this.endpoint = '/referral_queues';
        return this;
    }

    ReferralQueue(referral_queue = null)
    {
        this.endpoint = referral_queue? `/referral_queue/${referral_queue}` : `/referral_queue`;
        return this;
    }

    Refresh(newPath = null)
    {
        if(newPath)
            this.path = newPath;
        this.token = null;  
        this.relations = null;
        this.filters = null;    
    }


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // private methods
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////

    makeApiCall(method, endpoint, data = null, additionalHeaders = null)
    {
        const path = this.createPath(endpoint);
        return new Promise((resolve, reject) => {
            axios({
                method: method,
                url: path, 
                data: data, 
                headers: this.createHeaders(additionalHeaders)
            })
            .then(data => {
                resolve(data);
            })
            .catch(error => {
                reject(error);
            })
        })    
    }

    createPath(endpoint)
    {
        return this.api_root + endpoint;
    }

    createParamaters(method)
    {
        var endpoint = '?';
        if(this.filters && method === 'get')
        {
            endpoint += `${this.filters}`;
        }
        if(this.relations && method === 'get')
        {
            endpoint += `${this.createGetStyleRelations()}`
        }
        if(this.withTrashed === 'withTrashed' && method === 'get')
        {
            endpoint +=`${this.withTrashed}`;
        }
        return endpoint;
    }

    createHeaders(additionalHeaders)
    {
        this.headers = {};
        if(this.token)
            this.headers['Authorization'] = `Bearer ${this.token}`;
        if(additionalHeaders)
            Object.entries(additionalHeaders).forEach(([key, value]) => {           
                this.headers[key] = value;
            });
        return this.headers;
    }

    createGetStyleRelations()
    {
        var newRelations = '';
        if(this.relations){
            this.relations.forEach(relation => {
                newRelations += `&withRelations[]=${relation}&`;
            });
        }
        return newRelations;
    } 

    addPostStyleRelations(data)
    {
        if(this.relations)
            data['withRelations'] = this.relations;
        return data;
    }     
}

export {EosPortalApi};
export default EosPortalApi;

