var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user && _vm.myuser)?_c('v-card',[_c('v-card-title',[_vm._v("Profile for "+_vm._s(_vm.myuser.name))]),_c('v-card-text',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Personal Details")]),_c('v-tab',[_vm._v("Permissions")]),_c('v-tab',[_vm._v("Groups")]),_c('v-tab',[_vm._v("Leave Allowance")]),_c('v-tab',[_vm._v("Security")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('text-field-form',{attrs:{"fields":_vm.personalDetails},on:{"contentSaved":_vm.updateAwsUserPersonalDetails},model:{value:(_vm.editUserPersonalDetails),callback:function ($$v) {_vm.editUserPersonalDetails=$$v},expression:"editUserPersonalDetails"}}),_c('text-field-form',{attrs:{"fields":_vm.passwordDetails},on:{"contentSaved":_vm.updateUserPassword},model:{value:(_vm.editUserPassword),callback:function ($$v) {_vm.editUserPassword=$$v},expression:"editUserPassword"}})],1),_c('v-tab-item',[_c('v-data-table',{attrs:{"headers":_vm.permissionHeaders,"items":_vm.myuser.permission}})],1),_c('v-tab-item',[_c('v-data-table',{attrs:{"headers":_vm.groupTableHeaders,"items":_vm.myuser.group}})],1),_c('v-tab-item',[_c('v-card',{staticClass:"mb-2"},[_c('v-card-title',[_vm._v("Leave Approvers")]),_c('v-card-text',[_c('v-simple-table',[_c('tbody',_vm._l((_vm.myuser.approver),function(approver){return _c('tr',[_c('td',[_vm._v(_vm._s(approver.name))])])}),0)])],1)],1),_c('v-card',[_c('v-card-title',[_vm._v("Leave Allowances")]),_c('v-data-table',{attrs:{"headers":_vm.holidayAllowanceTableHeaders,"items":_vm.myuser.holiday_allowance},scopedSlots:_vm._u([{key:"item.allowance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calculateDaysFromAllowances(item.allowance))+" ")]}},{key:"item.remaining",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calculateDaysFromAllowances(item.remaining))+" ")]}},{key:"item.carryover",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calculateDaysFromAllowances(item.carryover))+" ")]}}],null,false,466355344)})],1)],1),(_vm.myAwsUser)?_c('v-tab-item',[_c('v-card-actions',[(_vm.myAwsUser.PreferredMfaSetting === undefined)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.enableSmsMfa}},[_vm._v("Enable SMS MFA")]):_vm._e(),(_vm.myAwsUser.PreferredMfaSetting === undefined)?_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.enableSoftwareMfa}},[_vm._v("Enable Software MFA")]):_vm._e(),(_vm.myAwsUser.PreferredMfaSetting === 'SMS_MFA')?_c('v-btn',{attrs:{"text":"","color":"pink"},on:{"click":_vm.disableSmsMfa}},[_vm._v("Disable SMS MFA")]):_vm._e(),(_vm.myAwsUser.PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA')?_c('v-btn',{attrs:{"text":"","color":"pink"},on:{"click":_vm.disableSoftwareMfa}},[_vm._v("Disable Software MFA")]):_vm._e(),_c('v-btn',{attrs:{"icon":"","color":"teal lighten-1"},on:{"click":function($event){return _vm.requestVerification('email', 'email_verification')}}},[_c('v-icon',[_vm._v("mdi-email-check")])],1),_c('v-btn',{attrs:{"icon":"","color":"teal lighten-1"},on:{"click":function($event){return _vm.requestVerification('phone_number', 'phone_number')}}},[_c('v-icon',[_vm._v("mdi-phone-check")])],1)],1),_c('v-data-table',{attrs:{"items":_vm.myAwsUser.UserAttributes,"headers":_vm.userAttributeHeaders}}),_c('v-data-table',{attrs:{"items":[_vm.myAwsUser],"headers":_vm.awsUserTableHeaders}})],1):_vm._e()],1)],1),_c('v-snackbar',{attrs:{"top":"","color":_vm.alertType},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showAlert = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,1476558398),model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]),_c('v-dialog',{key:_vm.qrcodeKey,attrs:{"max-width":"500"},model:{value:(_vm.showQRCode),callback:function ($$v) {_vm.showQRCode=$$v},expression:"showQRCode"}},[_c('v-card',[_c('v-card-subtitle',{staticClass:"pa-4"},[_vm._v("Scan the code below with your authentication app to set up your one time password authentication, then click save to proceed")]),_c('v-card-text',{staticClass:"d-flex justify-center"},[_c('vue-q-r-code-component',{attrs:{"text":_vm.qrURL,"size":200}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","dark":"","color":"pink"},on:{"click":function($event){_vm.showQRCode = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"fab":"","color":"primary"},on:{"click":_vm.captureCode}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)],1)],1)],1),_c('v-dialog',{staticClass:"accent",attrs:{"max-width":"350"},model:{value:(_vm.showCaptureCode),callback:function ($$v) {_vm.showCaptureCode=$$v},expression:"showCaptureCode"}},[_c('get-verification-code',{attrs:{"title":"Enter Authentication Code","message":"","buttonText":"Verify","button2Text":"Request another code","showButton2":false,"callback1":"verify","callback2":"resendCode"},on:{"verify":_vm.processVerificationCode}})],1),_c('v-overlay',{attrs:{"absolute":false,"opacity":0.45,"value":_vm.busy,"z-index":1000}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"100","width":"12"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }