<template>
    <v-container fluid v-if="$ability.can('view', 'reconciliations')">
        <v-card>
            <v-card-title>Clinic Reconciliation</v-card-title>
            <v-card-text>
                <text-field-form                
                :fields="formFields"                
                ></text-field-form>
                <v-data-table
                show-expand
                v-if="my_clinic_reconciliation"
                :headers="ScreeningTableHeaders"
                :items="my_clinic_reconciliation.screening">
                    <template v-slot:expanded-item="{ item }">
                        <v-data-table
                        :headers="gradingTableHeaders"
                        :items="item.grading">
                            <template v-slot:item.eye="{ item }">
                                {{eyeValue(null, item)}}
                            </template>
                        </v-data-table>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>

    import TextFieldForm from '../../components/EpTextFieldForm';

    export default{
        name: "ViewScreeningData",
        props: {
            clinic_reconciliation: String
        },

        data: function(){
            return {
                my_clinic_reconciliation: null,
                myEos: new EosPortalApi(config.api.root),
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,
                formFields: config.ClinicReconciliations.DisplayFields,
                ScreeningTableHeaders: config.ClinicReconciliations.ScreeningTableHeaders,
                gradingTableHeaders: config.Grading.gradingTableHeaders
            }
        },

        components: {
            TextFieldForm
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);
            this.getScreeningData();
        },

        computed: {

        },

        methods: {
            getScreeningData()
            {
                if(!this.busy)  {
                    this.busy = true;
                }                
                this.myEos.ClinicReconciliation(this.clinic_reconciliation).Relations(['Screening.Grading', 'Screening.ReferralQueue']).get()
                .then(({data}) => {
                    this.busy = false;
                    this.my_clinic_reconciliation = data;
                    this.formFields.attended.model = this.my_clinic_reconciliation.attended;
                    this.formFields.cancelled.model = this.my_clinic_reconciliation.cancelled;
                    this.formFields.dna.model = this.my_clinic_reconciliation.dna;
                    this.formFields.couldnotbeseen.model = this.my_clinic_reconciliation.couldnotbeseen;
                    this.formFields.reconciliation_comment.model = this.my_clinic_reconciliation.reconciliation_comment;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },
            eyeValue(key, value)
            {
                var eye = ["Right", "Left"]
                return eye[value.eye];
            },
        }
    }

</script>