<template>
    <v-container v-if="$ability.can('access', 'settings')">
        <data-table-card
        title="Schedule Types"
        :data_items="scheduleTypes"
        :data_headers="scheduleTypeTableHeaders"
        :edit_button="$ability.can('edit', 'settings')"
        :delete_button="$ability.can('edit', 'settings')"
        :add_button="$ability.can('edit', 'settings')"
        :restore_button="$ability.can('edit', 'settings')"
        showDeletedItemsCheckbox
        @deleteItem="deleteScheduleType"
        @editItem="editScheduleType"
        @addItem="addScheduleType"
        @showDeleted="getScheduleTypes"
        @restoreItem="restoreScheduleType">            
        </data-table-card>        
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
        <dialog-form
        persistent
        v-model="showScheduleTypeDialog"
        :fields="scheduleTypeFields"
        :title="scheduleTypeDialogTitle"
        :closeButton="scheduleTypeDialogButtons.close"
        :saveContentButton="scheduleTypeDialogButtons.save"
        @close="showScheduleTypeDialog = false"
        @saveContent="saveScheduleType">            
        </dialog-form>
    </v-container>
</template>

<script>

    import DataTableCard from '../../components/EpDataTableCard';
    import DialogForm from '../../components/EpDialogForm';

    const blankScheduleType = {
        id: null,
        deleted_at: null,
        displayed_as: null,
        shortcode: null
    };

    export default{
        name: "ScheduleTypes",

        data: function(){
            return {
                scheduleTypes: [],
                myEos: new EosPortalApi(config.api.root),
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,
                scheduleTypeTableHeaders: config.scheduleTypes.scheduleTypeTableHeaders,
                showScheduleTypeDialog: false,
                scheduleTypeFields: {
                    displayed_as: {
                        type: "text",                        
                        label: "Schedule Type Name",
                        model: null
                    },
                    shortcode: {
                        type: "text",
                        label: "Shortcode",
                        model: null
                    }
                },
                scheduleTypeDialogTitle: "New Schedule Type",
                scheduleTypeDialogButtons: config.dialogButtons,
                scheduleTypeEditing: null
            }
        },

        components: {
            DialogForm,
            DataTableCard
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);
            this.getScheduleTypes();
        },

        methods: {
            getScheduleTypes(withTrashed = false)
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);
                this.myEos.ScheduleTypes().WithTrashed(withTrashed).get()
                .then(({data}) => {
                    this.busy = false;
                    this.scheduleTypes = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            addScheduleType()
            {
                this.scheduleTypeEditing = blankScheduleType;
                this.scheduleTypeDialogTitle = "New Schedule Type";
                this.scheduleTypeFields.displayed_as.model = null;
                this.scheduleTypeFields.shortcode.model = null;
                this.showScheduleTypeDialog = true;
            },

            editScheduleType(scheduleType)
            {
                this.scheduleTypeEditing = scheduleType;
                this.scheduleTypeDialogTitle = "Edit Schedule Type";
                this.scheduleTypeFields.displayed_as.model = scheduleType.displayed_as;
                this.scheduleTypeFields.shortcode.model = scheduleType.shortcode;
                this.showScheduleTypeDialog = true;
            },

            deleteScheduleType(scheduleType)
            {
                if(!this.busy)  {
                    this.busy = true;
                } 
                this.myEos.ScheduleType(scheduleType.id)
                .delete()
                .then(({data}) => {
                    this.busy = false;
                    this.removeScheduleTypeFromTable(scheduleType.id);
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            restoreScheduleType(scheduleType)
            {
                if(!this.busy)  {
                    this.busy = true;
                } 
                this.myEos.ScheduleType(scheduleType.id)
                .restore()
                .then(({data}) => {
                    this.busy = false;
                    this.updateScheduleTypeTable(data);
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            saveScheduleType(scheduleType)
            {
                this.scheduleTypeEditing = this.mapDialogReturnedFields(scheduleType);
                if(this.scheduleTypeEditing.id === null)
                    this.saveNewScheduleType();
                else
                    this.updateScheduleType();
            },

            saveNewScheduleType()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.ScheduleType()
                .put(this.scheduleTypeEditing)
                .then(({data}) => {
                    this.busy = false;
                    this.showScheduleTypeDialog = false;
                    this.scheduleTypes.splice(0,0, data);
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });

            },

            updateScheduleType()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.ScheduleType(this.scheduleTypeEditing.id)
                .patch(this.scheduleTypeEditing)
                .then(({data}) => {
                    this.busy = false;
                    this.showScheduleTypeDialog = false;
                    this.updateScheduleTypeTable(data);
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            mapDialogReturnedFields(returnedFields)
            {
                Object.entries(returnedFields).forEach(([key, value]) => {
                    if(this.scheduleTypeEditing.hasOwnProperty(key))
                        this.scheduleTypeEditing[key] = value.model;
                });
                return this.scheduleTypeEditing;
            },

            updateScheduleTypeTable(scheduleType)
            {
                var index = this.scheduleTypes.findIndex(item => {
                    return item.id === scheduleType.id;
                });
                if(index !== -1)
                    this.scheduleTypes.splice(index, 1, scheduleType);
            },

            removeScheduleTypeFromTable(id)
            {
                var index = this.scheduleTypes.findIndex(item => {
                    return item.id === id;
                });
                if(index !== -1)
                    this.scheduleTypes.splice(index, 1);
            }
        }
    }

</script>