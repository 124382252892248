<template>
    <v-card v-if="$ability.can('edit', 'referrals')">
        <v-card-title>Referral Management</v-card-title>
        <v-card-text>
            <v-toolbar flat class="pa-8 mb-4">
                <v-text-field
                @change="getReferrals"
                type="date"
                v-model="period_start"
                label="From"></v-text-field>
                <v-spacer></v-spacer>
                <v-text-field
                @change="getReferrals"
                type="date"
                v-model="period_end"
                label="To"></v-text-field>
            </v-toolbar>
            <v-tabs v-model="tab">
                <v-tab>Open referrals</v-tab>
                <v-tab>Closed Referrals</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
                <v-tab-item>
                    <v-data-table
                    :items="open_referrals"
                    :headers="referralTableHeaders">
                        <template v-slot:item.actions="{ item }">
                            <v-btn
                            color="primary"
                            v-if="!item.acknowledged"
                            @click="editReferral(item)"
                            icon>
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn
                            color="teal lighten-2"
                            v-if="!item.acknowledged"
                            @click="completeReferral(item)"
                            icon>
                                <v-icon>mdi-clipboard-check</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-tab-item>
                <v-tab-item>
                    <v-data-table
                    :items="closed_referrals"
                    :headers="referralTableHeaders"></v-data-table>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>
        <dialog-form
        persistent
        v-model="showReferralCommentDialog"
        :fields="referralCommentFields"
        :title="referralCommentDialogTitle"
        :closeButton="referralCommentDialogButtons.close"
        :saveContentButton="referralCommentDialogButtons.save"
        @close="showReferralCommentDialog = false"
        @saveContent="saveReferralComment">            
        </dialog-form>
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script>

    import DialogForm from '../../components/EpDialogForm';

    export default{
        name: "ReferralQueue",

        props: {

        },

        data: function(){
            return {
                all_referrals: [],
                open_referrals: [],
                closed_referrals: [],
                period_start: dayjs().startOf('month').format('YYYY-MM-DD'),
                period_end: dayjs().endOf('month').format('YYYY-MM-DD'),
                myEos: new EosPortalApi(config.api.root),
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: true,
                referralTableHeaders: config.Referrals.referralTableHeaders,
                tab: null,
                showReferralCommentDialog: false,
                referralCommentFields: {
                    referral_comment: {
                        type: "text",
                        label: "Comment",
                        model: null
                    }
                },
                referralCommentDialogTitle: "Edit Referral",
                referralCommentDialogButtons: config.dialogButtons,
                referralEditing: null
            }
        },

        components:{
            DialogForm
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);
            this.getReferrals();  
        },

        methods: {
            getReferrals(){
                if(!this.busy)  {
                    this.busy = true;
                } 
                this.myEos.ReferralQueues().Filters({
                    from: this.period_start,
                    to: this.period_end
                }).Relations(['Screening.ClinicReconciliation.Clinic.Venue', 'User']).get()
                .then(({data}) => {
                    this.busy = false;
                    this.all_referrals = data; 
                    this.mapToQueues();                   
                })                               
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            editReferral(referral)
            {
                this.referralCommentFields.referral_comment.model = referral.referral_comment;
                this.referralEditing = JSON.parse(JSON.stringify(referral));
                this.showReferralCommentDialog = true;
            },

            saveReferralComment(referral)
            {
                referral = this.mapDialogReturnedFields(referral);
                if(!this.busy)  {
                    this.busy = true;
                } 
                this.myEos.ReferralQueue(referral.id)
                .Relations(['Screening.ClinicReconciliation.Clinic.Venue', 'User'])
                .patch(referral)
                .then(({data}) => {
                    this.busy = false;
                    this.showReferralCommentDialog = false;
                    this.updateOpenReferralTable(data);
                    this.mapToQueues(); 
                })                               
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            completeReferral(referral)
            {
                if(!this.busy)  {
                    this.busy = true;
                } 
                referral.acknowledged = dayjs().format('YYYY-MM-DD HH:mm:ss');
                this.myEos.ReferralQueue(referral.id)
                .Relations(['Screening.ClinicReconciliation.Clinic.Venue', 'User'])
                .patch(referral)
                .then(({data}) => {
                    this.busy = false;                
                    this.updateOpenReferralTable(data);
                    this.mapToQueues(); 
                })                               
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            mapToQueues()
            {
                this.open_referrals = this.all_referrals.filter(referral => {
                    return referral.acknowledged === null;
                });
                this.closed_referrals = this.all_referrals.filter(referral => {
                    return referral.acknowledged !== null;
                })
            },

            mapDialogReturnedFields(returnedFields)
            {
                Object.entries(returnedFields).forEach(([key, value]) => {
                    if(this.referralEditing.hasOwnProperty(key))
                        this.referralEditing[key] = value.model;
                });
                return this.referralEditing;
            },

            updateOpenReferralTable(referral)
            {
                var index = this.all_referrals.findIndex(item => {
                    return item.id === referral.id;
                });
                if(index !== -1)
                    this.all_referrals.splice(index, 1, referral);
            },
        }
    }

</script>