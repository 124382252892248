<template>
    <div class="overflow-x-auto ma-6" v-if="$ability.can('view', 'reconciliations')">
        <div class="text-h4 mb-4">Clinic Attendance</div>
        <v-card flat>
            <v-row flat class="pa-8 mb-4">
                <v-col>
                    <v-text-field
                    @change="getClinics"
                    type="date"
                    v-model="period_start"
                    label="From"></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                    @change="getClinics"
                    type="date"
                    v-model="period_end"
                    label="To"></v-text-field>
                </v-col>
                <v-col>
                    <v-select
                    @change="getClinics"
                    :items="groups"                
                    item-text="displayed_as"
                    item-value="id"
                    v-model="group"
                    label="Group"></v-select>
                </v-col>                   
            </v-row> 
            <v-card flat>
                <v-card-text>
                    <v-data-table
                    :items="clinics"
                    :headers="attendanceTableHeaders">
                        <template v-slot:item.user="{ item }">
                            <div v-for="user in item.user">
                                {{user.name}}
                            </div>
                        </template>
                        <template v-slot:item.attended="{ item }">
                            <div v-for="reconciliation in item.clinic_reconciliation">
                                {{reconciliation.attended}}
                            </div>
                        </template>
                        <template v-slot:item.cancelled="{ item }">
                            <div v-for="reconciliation in item.clinic_reconciliation">
                                {{reconciliation.cancelled}}
                            </div>
                        </template>
                        <template v-slot:item.couldnotbeseen="{ item }">
                            <div v-for="reconciliation in item.clinic_reconciliation">
                                {{reconciliation.couldnotbeseen}}
                            </div>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-btn
                            icon
                            :to="'/view_reconciliation/'+item.id"
                            color="primary">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-card>
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>

    export default{
        name: "ClinicAttendance",

        props: {

        },

        data: function(){
            return {
                clinics: [],
                groups: [],
                group: null,
                myEos: new EosPortalApi(config.api.root),
                period_start: dayjs().startOf('month').format('YYYY-MM-DD'),
                period_end: dayjs().endOf('month').format('YYYY-MM-DD'),
                attendanceTableHeaders: config.Clinics.attendanceTableHeaders,
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,
            }
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);
            this.getGroups()
        },

        computed: {

        },

        methods: {
            getGroups(){
                if(!this.busy)  {
                    this.busy = true;
                }  
                this.myEos.Groups().Relations([]).get()
                .then(({data}) => {
                    this.busy = false;
                    this.groups = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            getClinics(){
                if(!this.group)
                {
                    this.alertText = "Please choose a group";
                    this.alertType = 'error';
                    this.showAlert = true;
                    return;
                }
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Clinics().Filters({
                    group_id: this.group,
                    from: dayjs(this.period_start).format('YYYY-MM-DD'),
                    to: dayjs(this.period_end).format('YYYY-MM-DD')
                }).Relations(['ClinicReconciliation', 'Venue', 'User']).get()
                .then(({data}) => {
                    this.busy = false;
                    this.clinics = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            }
        }
    }

</script>