<template>
    <v-dialog
    max-width="400"
    persistent
    v-model="showDialog">
        <v-card>
            <v-card-title>New Leave Request</v-card-title>
            <v-card-text>
                <v-select
                v-model="selectedUserId"
                @change="getUser"
                label="User"
                item-text="name"
                item-value="id"
                :items="allUsers"></v-select>
                <v-text-field
                readonly
                label="Remaining Allowance"
                v-model="calculateDaysFromAllowances"
                ></v-text-field>
                <v-select
                :disabled="!selectedUserId"
                v-model="myfields.leave_type"
                :items="leaveTypes"
                label="Leave Type"></v-select>
                <v-text-field  
                :error-messages="enoughAllowance"              
                :disabled="!myfields.leave_type"                
                v-model="from"
                type="date"                
                label="Start date"></v-text-field>
                <v-select
                :disabled="!selectedUserId"
                v-model="duration"
                :items="leaveDuration"
                label="Duration"></v-select>  
                <v-text-field
                v-if="duration === 'Greater than one day'"
                type="date"
                v-model="to"
                label="End date"></v-text-field>
                <v-text-field
                v-if="duration === 'Part of a day'"
                type="time"
                v-model="start_time"
                label="Start time"></v-text-field>
                <v-text-field
                v-if="duration === 'Part of a day'"
                type="time"
                v-model="finish_time"
                label="Finish time"></v-text-field>              
                <v-text-field
                v-model="myfields.request_comment"
                label="Comment"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                fab
                dark
                @click="$emit('close')"
                color="pink">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn
                :disabled="!selectedUserId || enoughAllowance !== ''"
                fab
                @click="sendRequest"
                color="primary">
                    <v-icon>mdi-content-save</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-dialog>
</template>

<script>

    export default{
        name: "LeaveRequestForm",
        props: {
            fields: Object,
            showDialog: Boolean,
            user: Object,
            personalRequest: {
                type: Boolean,
                default: true
            }       
        },

        data: function(){
            return {
                myfields: this.fields,                
                myEos: new EosPortalApi(config.api.root),
                showAlert: false,
                alertType: 'success',
                alertText: null,
                allUsers: [],
                from: null,
                to: null, 
                start_time: '09:00',
                finish_time: '16:30',    
                duration: null,
                selectedUserId: null,
                selectedUserObject: null                       
            }
        },

        mounted(){
            this.getUsers();
        },

        computed: {
            leaveTypes: function()
            {
                return config.leave.leaveTypes;
            },

            leaveDuration: function()
            {
                return config.leave.leaveDuration;
            },

            startdate: function()
            {
                if(this.duration === 'Part of a day')
                    return dayjs(this.from + ' ' + this.start_time, 'YYYY-MM-DD HH:mm:ss');
                return dayjs(this.from + '09:00', 'YYYY-MM-DD HH:mm:ss');
            },

            enddate: function()
            {
                if(this.duration === 'Part of a day')
                    return dayjs(this.from + ' ' + this.finish_time, 'YYYY-MM-DD HH:mm:ss')
                if(this.duration === 'Greater than one day')
                    return dayjs(this.to + '16:30', 'YYYY-MM-DD HH:mm:ss');
                return dayjs(this.from + '16:30', 'YYYY-MM-DD HH:mm:ss');
            },

            allowanceUsed: function()
            {
                if(this.myfields.leave_type !== 'Holiday')
                    return 0;
                if(this.duration === 'Part of a day')
                    return this.enddate.diff(this.startdate, 'minutes');
                if(this.duration === 'Greater than one day' && this.to)
                    return Big(dayjs(this.to).diff(dayjs(this.from), 'days')).plus(1).times(450).toNumber(); //need to add one to the day diff to account for non counting of the first day
                if(this.duration === 'All Day')
                    return 450; //number of minutes in 7.5 hours
                return 0;
            },

            leftOverAllowance: function()
            {
                if(!this.currentYearsHolidayAllowance)
                    return Big(0).minus(Big(this.allowanceUsed)).toNumber();  
                else              
                    return (new Big(this.currentYearsHolidayAllowance.remaining)).minus(Big(this.allowanceUsed)).toNumber();
                return 0;
            },

            enoughAllowance: function()
            {
                if(this.leftOverAllowance >= 0)
                    return '';
                return 'Insufficient holiday allowance remaining';
            },

            currentYearsHolidayAllowance: function()
            {
                if(!this.selectedUserObject)
                    return null;
                if(!this.selectedUserObject.holiday_allowance.length)
                    return null;
                var iscurrent = this.selectedUserObject.holiday_allowance.find(allowance => {  
                    var current = dayjs();                  
                    return dayjs(allowance.startofyear).isSameOrBefore(current, 'year') && dayjs(allowance.endofyear).isSameOrAfter(current);                    
                });
                if(iscurrent !== undefined)
                    return iscurrent;
                return null;
            },

            calculateDaysFromAllowances: function()
            {
                if(!this.currentYearsHolidayAllowance)
                    return '';
                else
                    return (new Big(this.currentYearsHolidayAllowance.remaining)).minus(Big(this.allowanceUsed)).div(450).toFixed(1).toString();
                return '';
            }
        },

        methods: {            

            getUsers(){
                if(this.$ability.can('edit', 'other users') && !this.personalRequest){
                    this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken).Users()
                    .Relations(['HolidayAllowance']).get()
                    .then(({data}) => {
                        this.allUsers = data;
                    })
                    .catch(err => {
                        this.alertText = err.message;
                        this.alertType = 'error';
                        this.showAlert = true;
                    });
                }                                        
                else this.allUsers = [this.user];
            },

            getUser(id)
            {
                this.selectedUserObject = this.allUsers.find(iUser => {
                    return iUser.id === id;
                });
            },            

            findAwsSubFromId(id)
            {
                return this.allUsers.find(user => {
                    return user.id === id;
                }).aws_sub;
            },

            sendRequest()
            {
                this.myfields.from = this.startdate.format('YYYY-MM-DD HH:mm:ss');
                this.myfields.to = this.enddate.format('YYYY-MM-DD HH:mm:ss');
                this.myfields.allowance_used = this.allowanceUsed;
                this.myfields.user_id = this.selectedUserObject.id;
                this.$emit('saveRequest', this.myfields);
            }
        }
    }

</script>