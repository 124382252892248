<script>
    import {
        VCard, 
        VCardTitle, 
        VCardText, 
        VCardActions, 
        VSpacer, 
        VBtn, 
        VIcon, 
        VDialog, 
        VTextField, 
        VCheckbox,
        VFileInput, 
        VSelect} from 'vuetify/lib';
    export default{
        name: "DialogForm",
        props: {
            fields: Object,
            value: Boolean,
            title: String,
            closeButton: Object,
            saveContentButton: Object,
            deleteButton: Object,
            restoreButton: Object,
            copyButton: Object,
            persistent: {
                type: Boolean,
                default: false
            },
            maxWidth: {
                type: Number,
                default: 450
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },

        methods: {
            createMyElements(createElement)
            {
                var list = [];
                var self = this;
                Object.entries(this.fields).forEach(([index, item]) => {
                    switch(item.type)
                    {
                        case 'text':                        
                            list.push(createElement(VTextField, {

                                props: {
                                    value: this.fields[index].model,
                                    label: item.label
                                },
                                attrs: {
                                    disabled: item.disabled
                                },

                                on: {
                                    input: function(value){
                                        self.fields[index].model = value
                                    }
                                }
                                
                            }));
                        break;

                        case 'date':                        
                            list.push(createElement(VTextField, {

                                props: {
                                    value: this.fields[index].model,
                                    label: item.label                                    
                                },

                                attrs: {
                                    type: "date",
                                    disabled: item.disabled
                                },

                                on: {
                                    input: function(value){
                                        self.fields[index].model = value
                                    }
                                }
                                
                            }));
                        break;

                        case 'time':                        
                            list.push(createElement(VTextField, {

                                props: {
                                    value: this.fields[index].model,
                                    label: item.label                                    
                                },

                                attrs: {
                                    type: "time",
                                    disabled: item.disabled
                                },

                                on: {
                                    input: function(value){
                                        self.fields[index].model = value
                                    }
                                }
                                
                            }));
                        break;

                        case 'select':                        
                            list.push(createElement(VSelect, {

                                props: {
                                    value: this.fields[index].model,
                                    label: item.label,
                                    'item-text': 'Name',
                                    'item-value': 'Value',
                                    items: item.items,
                                    multiple: item.multiple
                                },

                                attrs: {
                                    disabled: item.disabled
                                },

                                on: {
                                    change: function(value){
                                        self.fields[index].model = value
                                    }
                                }
                                
                            }));
                        break;

                        case 'check':                        
                            list.push(createElement(VCheckbox, {

                                props: {
                                    'input-value': this.fields[index].model,
                                    label: item.label,
                                    'true-value': true
                                },

                                attrs: {
                                    disabled: item.disabled
                                },

                                on: {
                                    change: function(value){
                                        self.fields[index].model = value
                                    }
                                }
                                
                            }));
                        break;

                        case 'file':                        
                            list.push(createElement(VFileInput, {

                                props: {
                                    value: this.fields[index].model,
                                    label: item.label,
                                    accept: item.accept
                                },
                                attrs: {
                                    disabled: item.disabled
                                },

                                on: {
                                    change: function(value){
                                        self.fields[index].model = value
                                    }
                                }
                                
                            }));
                        break;
                    }
                })
                return list;
            }, 

            createMyButtons(createElement)
            {
                var buttonList = [];
                var self = this;
                buttonList.push(createElement(VSpacer));
                if(this.deleteButton && this.deleteButton.isShown)
                    buttonList.push(createElement(VBtn, this.createButton(this.deleteButton, createElement), [ this.createButtonSlot(this.deleteButton, createElement, this.$slots.default)]));
                if(this.restoreButton && this.restoreButton.isShown)
                    buttonList.push(createElement(VBtn, this.createButton(this.restoreButton, createElement), [ this.createButtonSlot(this.restoreButton, createElement, this.$slots.default)]));
                if(this.copyButton && this.copyButton.isShown)
                    buttonList.push(createElement(VBtn, this.createButton(this.copyButton, createElement), [ this.createButtonSlot(this.copyButton, createElement, this.$slots.default)]));
                if(this.closeButton && this.closeButton.isShown)
                    buttonList.push(createElement(VBtn, this.createButton(this.closeButton, createElement), [ this.createButtonSlot(this.closeButton, createElement, this.$slots.default)]));
                if(this.saveContentButton && this.saveContentButton.isShown)
                    buttonList.push(createElement(VBtn, this.createButton(this.saveContentButton, createElement), [ this.createButtonSlot(this.saveContentButton, createElement, this.$slots.default)]));
                return buttonList;
            },

            createButton(button, createElement)
            {
                var data = {};
                var props = {};
                var events = {};
                props[button.type] = true;
                props['color'] = button.color;
                if(button.dark) props['dark'] = true;
                data['props'] = props;
                data['attrs'] = {
                    'data-callback': button.callback
                }
                data['on'] = {
                    click: this.handleButtonClick
                }
                return data;
            },

            createButtonSlot(button, createElement, defaultSlot)
            {
                if(button.hasIcon)
                    return createElement(VIcon, button.icon)                
                return defaultSlot = button.text;
            },

            handleButtonClick(button)
            {
                if(button.currentTarget.dataset.callback === 'saveContent' || button.currentTarget.dataset.callback === 'deleteContent' || button.currentTarget.dataset.callback === 'restoreContent')
                    this.$emit(button.currentTarget.dataset.callback, this.fields);
                else
                    this.$emit(button.currentTarget.dataset.callback);
            }            
        },

        render: function(createElement) {
            var self = this;
            return createElement(VDialog, {
                props: {
                    value: this.value,
                    persistent: this.persistent,
                    maxWidth: this.maxWidth,
                    fullscreen: this.$vuetify.breakpoint.mobile
                },
                on: {
                    input: function(value){
                        self.$emit('input', value);
                    }
                }
                                
            },
            [createElement(VCard, {}, 
                [
                    createElement(VCardTitle, this.title),
                    createElement(VCardText, {}, 
                        this.createMyElements(createElement)
                    ),
                    createElement(VCardActions, {}, 
                        this.createMyButtons(createElement)                        
                    )
                ]
            )]);
        }
    }

</script>