import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import mainMixins from './services/mixins/main_mixins';
import { abilitiesPlugin } from '@casl/vue';
import { Ability } from '@casl/ability';
import defineAbilityFor from './services/ability';
import {EosAwsCognito, EosPoolData, User, EosAwsCognitoAdmin} from 'eos_aws_cognito';
import {EosS3Client} from 'eos_aws_s3';
import config from './config.json';
import EosPortalApi from './services/eos_portal_api';
import big from 'big.js';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import './scss/calendar.scss';

dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(dayOfYear);
dayjs.extend(weekOfYear);
dayjs.extend(advancedFormat);



window.EosAwsCognito = EosAwsCognito;
window.EosPoolData = new EosPoolData(config.cognito.UserPoolId, config.cognito.ClientId);
window.User = User;
window.EosAwsCognitoAdmin = EosAwsCognitoAdmin;
window.EosS3Client = EosS3Client;

window.Store = store;
window.config = config;
window.EosPortalApi = EosPortalApi;
const rules = defineAbilityFor(null, []);
window.AbilityRules = rules;
window.Big = big;
window.dayjs = dayjs;

Vue.config.productionTip = false;
Vue.use(abilitiesPlugin, new Ability(rules), {
    useGlobalProperties: true
});
Vue.mixin(mainMixins);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
