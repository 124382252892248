<template>
    <div>
        <v-toolbar flat>
            <v-toolbar-title>Mandatory Training</v-toolbar-title>
        </v-toolbar>
        <table class="work_calendar_table">
            <thead>
                <th></th>
                <th v-for="trainingType in mandatoryTrainingTypes">{{trainingType.displayed_as}}</th>
            </thead>
            <tbody>
                <tr v-for="user in users">
                    <td >{{user.name}}</td>
                    <td v-for="trainingType in mandatoryTrainingTypes">
                        <training-grid-tile
                        :trainingType="trainingType.displayed_as"
                        :user="user"></training-grid-tile>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

    import TrainingGridTile from './EpTrainingGridTile'

    export default{
        name: "MandatoryTrainingGrid",

        props: {
            users: Array,
            mandatoryTrainingTypes: Array,            
        },

        components: {
            TrainingGridTile
        }
    }

</script>