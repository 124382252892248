<template>
    <div class="overflow-x-auto mx-6" v-if="$ability.can('edit', 'clinics')">
        <div class="text-h5 mt-8 pl-4">Work Scheduler</div>
        <v-toolbar flat class="py-8 mb-4">            
            <v-menu 
            ref="monthPicker"   
            max-width="290px"     
            :close-on-content-click="false"
            v-model="monthPicker">
                <template v-slot:activator="{on}">
                    <v-text-field                     
                    prepend-icon="mdi-calendar"
                    v-model="month"
                    label="Month"
                    v-on="on"
                    readonly>                        
                    </v-text-field>
                </template>
                <v-date-picker
                @change="monthPicker = false"
                type="month"
                no-title
                scrollable
                v-model="month">
                    <v-spacer></v-spacer>
                    <v-btn
                    text
                    dark
                    @click="monthPicker = false"
                    color="pink">Cancel</v-btn>                    
                </v-date-picker>
            </v-menu>
            <v-spacer></v-spacer>
            <v-checkbox
            v-model="showDeleted"
            label="Show Deleted Clinics"
            @change="getClinics"></v-checkbox>
        </v-toolbar>
        <work-calendar
        v-for="(group, index) in userGroups"
        :clinics="clinicGroups[index]? clinicGroups[index] : []"
        :staff="group"
        :title="index"
        @click="processClickEvent"
        :year="year"
        :company_events="company_events"
        :bank_holidays="bank_holidays"
        :month="monthNumber"></work-calendar>
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
        <dialog-form
        persistent
        v-model="showClinicDialog"
        :fields="clinicFields"
        :title="clinicDialogTitle"
        :closeButton="clinicDialogButtons.close"
        :saveContentButton="clinicDialogButtons.save"
        :deleteButton="clinicDialogButtons.delete"
        :copyButton="clinicDialogButtons.copy"
        :restoreButton="clinicDialogButtons.restore"
        @close="showClinicDialog = false"
        @copyContent="copyClinic"
        @deleteContent="deleteClinic"
        @restoreContent="restoreClinic"
        @saveContent="saveClinic">            
        </dialog-form>
    </div>
</template>

<script>

    import axios from 'axios';
    import DialogForm from '../../components/EpDialogForm';
    import WorkCalendar from '../../components/EpWorkCalendar';
    import {sortIntoGroups, findGroupIdByGroupName} from '../../services/mixins/mixins';

    const blankClinic = {
        id: null,
        user_id: null,
        group_id: null,
        venue: null,
        clinic_date: null,
        schedule_type: null,
        start_time: null,
        finish_time: null,
        lunch_time: null,
        lunch_len: null,
        has_lunch: null,
        app_len: null,
        reconcile: false
    }

    export default{
        name: "EditWorkSchedule",

        props: {

        },

        data: function()
        {
            return {
                allUsers: [],
                allClinics: [],
                scheduleTypes: [],
                venues: [],
                bank_holidays: [],
                company_events: [], 
                myEos: new EosPortalApi(config.api.root),
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,
                month: dayjs().format('YYYY-MM'),
                monthPicker: false,
                clinicEditing: null,
                clinicDialogButtons: config.dialogButtons,
                showClinicDialog: false,
                clinicFields: config.clinics.clinicFields,
                clinicDialogTitle: "New Clinic",
                showDeleted: false
            }
        },

        components: {
            DialogForm,
            WorkCalendar
        },

        mounted()
        {
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);
            this.getUsers();
            this.getClinics();
            this.getScheduleTypes();
            this.getVenues();
            this.getBankHolidays();
            this.getEvents();
        },

        watch:{
            monthNumber: function()
            {
                this.getClinics();
                this.getEvents();
            }
        },

        computed: {

            user: function()
            {
                return this.$store.state.user;
            },

            groups: function()
            {
                if(this.$store.state.user)
                    return this.$store.state.user.group;
                return [];
            },

            userGroups: function()
            {
                return sortIntoGroups(this.groups, this.allUsers);                
            },

            monthNumber: function()
            {
                return dayjs(this.month).month();
            },

            year: function()
            {
                return dayjs(this.month).year();
            },

            firstOfMonth: function()
            {
                return dayjs(this.month).startOf('month');
            },

            endOfMonth: function()
            {
                return dayjs(this.month).endOf('month');
            },

            clinicGroups: function()
            {
                return sortIntoGroups(this.groups, this.allClinics);
            },

            venueGroups: function()
            {
                return sortIntoGroups(this.groups, this.venues);
            },            

            scheduleTypeSelectArray: function()
            {
                return this.scheduleTypes.map(type => {
                    return {
                        Name: type.displayed_as,
                        Value: type.displayed_as
                    };
                });
            },            

            intervalLengths: function(){
                var lengths = [];
                for(var x = 5; x < 65; x+=5)
                {
                    lengths.push({
                        Name: x,
                        Value: x
                    })
                }
                return lengths;
            },

            bankHolidayApi: function()
            {
                return config.bankHolidayApi;
            },
        },

        methods: {
            
            getUsers(){
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Users().Relations(['Group', 'HolidayRequest']).get()
                .then(({data}) => {
                    this.busy = false;
                    this.allUsers = data;                    
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            getScheduleTypes()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.ScheduleTypes().Relations([]).get()
                .then(({data}) => {
                    this.busy = false;
                    this.scheduleTypes = data;                    
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            getVenues()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Venues().Relations(['Group']).get()
                .then(({data}) => {
                    this.busy = false;
                    this.venues = data;                    
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            getClinics(withTrashed = false)
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Clinics()
                .WithTrashed(withTrashed)
                .Filters({
                    from: this.firstOfMonth.format('YYYY-MM-DD'),
                    to: this.endOfMonth.format('YYYY-MM-DD')
                }).Relations(['Group', 'Venue', 'User']).get()
                .then(({data}) => {
                    this.busy = false;
                    this.allClinics = data;                    
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            getBankHolidays()
            {  
                if(!this.busy)  {
                    this.busy = true;
                }              
                axios.get(this.bankHolidayApi)   
                .then(({data}) => {
                    this.bank_holidays = this.filterBankHolidaysByYear(data['england-and-wales'].events);
                }) 
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });            
            },

            getEvents()
            {  
                if(!this.busy)  {
                    this.busy = true;
                } 
                this.myEos.CompanyEvents().Filters({
                    from: this.firstOfMonth.format('YYYY-MM-DD'),
                    to: this.endOfMonth.format('YYYY-MM-DD')
                }).Relations([]).get()                
                .then(({data}) => {
                    this.busy = false;
                    this.company_events = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            editClinic(clinic, date, group)
            {
                this.clinicEditing = JSON.parse(JSON.stringify(clinic));
                this.clinicFields = this.mapClinicFieldsToClinic(clinic, this.clinicFields);
                this.clinicEditing.editors_id = this.user.id;
                this.clinicFields.venue.items = this.venueSelectArray(group);
                this.clinicFields.venue.model = clinic.venue.shortcode;
                this.clinicFields.users.items = this.userSelectArray(group);
                this.clinicFields.users.model = this.getUserIdsFromClinicUsers(clinic);                
                this.clinicFields.schedule_type.items = this.scheduleTypeSelectArray;                
                this.clinicFields.lunch_len.items = this.intervalLengths;                
                this.clinicFields.app_len.items = this.intervalLengths;
                if(this.clinicEditing.deleted_at)
                {
                    this.clinicDialogButtons.delete.isShown = false;
                    this.clinicDialogButtons.save.isShown = false;
                    this.clinicDialogButtons.restore.isShown = true;
                }
                else{
                    this.clinicDialogButtons.delete.isShown = true;
                    this.clinicDialogButtons.restore.isShown = false;
                    this.clinicDialogButtons.save.isShown = true;
                }
                this.clinicDialogButtons.copy.isShown = true;
                this.clinicFields.clinic_date.disabled = true;
                this.showClinicDialog = true;
            },

            copyClinic(clinic)
            {
                this.clinicEditing.id = null;
                this.clinicFields.clinic_date.disabled = false;
                this.clinicDialogButtons.save.isShown = true;
            },

            newClinic(employee, date, group)
            {
                if(!employee)
                    return;
                this.clinicEditing = blankClinic;
                this.clinicEditing.user_id = this.user.id;
                this.clinicEditing.group_id = findGroupIdByGroupName(this.groups, group);
                this.clinicFields.venue.items = this.venueSelectArray(group);
                this.clinicFields.users.items = this.userSelectArray(group);
                this.clinicFields.users.model = [employee.id];                
                this.clinicFields.schedule_type.items = this.scheduleTypeSelectArray;
                this.clinicFields.clinic_date.model = date.format('YYYY-MM-DD');
                this.clinicFields.lunch_len.items = this.intervalLengths;
                this.clinicFields.app_len.items = this.intervalLengths;
                this.clinicFields.reconcile.model = this.clinicEditing.reconcile;
                this.clinicDialogButtons.delete.isShown = false;
                this.clinicDialogButtons.copy.isShown = false;                
                this.clinicDialogButtons.restore.isShown = false;
                this.clinicDialogButtons.save.isShown = true;
                this.clinicFields.clinic_date.disabled = true;
                this.showClinicDialog = true;
            },

            deleteClinic(clinic)
            {
                clinic = this.mapDialogReturnedFields(clinic);
                if(clinic.user.length !== 0)
                {
                    this.busy = false;
                    this.alertText = 'Please unassign all users before deleting the clinic';
                    this.alertType = 'error';
                    this.showAlert = true;
                    return;
                }
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Clinic(clinic.id)
                .delete()
                .then(({data}) => {
                    this.busy = false;  
                    this.showClinicDialog = false;                  
                    this.removeClinicFromClinicArray(clinic);                   ;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            restoreClinic(clinic)
            {
                clinic = this.mapDialogReturnedFields(clinic);
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Clinic(clinic.id).Relations(['User', 'Group', 'Venue'])
                .restore(clinic)
                .then(({data}) => {
                    this.busy = false;  
                    this.showClinicDialog = false;                  
                    this.allClinics.splice(0,0, data);                 ;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            processClickEvent(event)
            {
                if(event.clinic)
                    this.editClinic(event.clinic, event.date, event.group);
                else
                    this.newClinic(event.employee, event.date, event.group);
            },

            saveClinic(clinic)
            {
                this.clinicEditing = this.mapDialogReturnedFields(clinic);
                this.clinicEditing.users = clinic.users.model;                
                if(this.clinicEditing.id)
                    this.updateClinic(this.clinicEditing);
                else
                    this.saveNewClinic(this.clinicEditing);
                
            },

            saveNewClinic(clinic)
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Clinic().Relations(['User', 'Group', 'Venue'])
                .put(clinic)
                .then(({data}) => {
                    this.busy = false;
                    this.showClinicDialog = false;
                    this.allClinics.splice(0,0, data);                    
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            updateClinic(clinic)
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Clinic(clinic.id).Relations(['User', 'Group', 'Venue'])
                .patch(clinic)
                .then(({data}) => {
                    this.busy = false;
                    this.showClinicDialog = false;
                    this.updateClinicArray(data)                    ;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            venueSelectArray(group)
            {
                return this.venueGroups[group].map(venue => {
                    return {
                        Name: venue.displayed_as,
                        Value: venue.shortcode
                    }
                })
            },

            userSelectArray(group)
            {
                return this.userGroups[group].map(user => {
                    return {
                        Name: user.name,
                        Value: user.id
                    };
                });
            },

            getUserIdsFromClinicUsers(clinic)
            {
                return clinic.user.map(user => {
                    return user.id;
                });
            },

            mapDialogReturnedFields(returnedFields)
            {
                Object.entries(returnedFields).forEach(([key, value]) => {
                    if(this.clinicEditing.hasOwnProperty(key))
                        this.clinicEditing[key] = value.model;
                });
                return this.clinicEditing;
            },

            updateClinicArray(clinic)
            {
                var index = this.allClinics.findIndex(item => {
                    return item.id === clinic.id;
                });
                if(index !== -1)
                    this.allClinics.splice(index, 1, clinic);
            },

            removeClinicFromClinicArray(clinic)
            {
                var index = this.allClinics.findIndex(item => {
                    return item.id === clinic.id;
                });
                if(index !== -1)
                    this.allClinics.splice(index, 1);
            },

            mapClinicFieldsToClinic(clinic, clinicfields)
            {
                Object.entries(clinic).forEach(([key, value]) => {
                    if(clinicfields.hasOwnProperty(key))
                        clinicfields[key].model = value;
                });
                return clinicfields;
            },

            filterBankHolidaysByYear(data)
            {
                return data.filter(item => {
                    return dayjs().isSame(item.date, 'year');
                });
            }
        }
        
    }

</script>