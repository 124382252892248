import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/home'
import ProfileHome from '../views/profile/Home'
import LeaveHome from '../views/leave/Home'
import LeaveRequests from '../views/leave/leaveRequests'
import Login from '../views/auth/Login'
import Groups from '../views/groups/index'
import Venues from '../views/venues/index'
import Venue from '../views/venues/editVenue'
import Events from '../views/events/index'
import ScheduleTypes from '../views/schedules/index'
import MandatoryTraining from '../views/training/mandatory_training/index'
import PasswordReset from '../views/auth/PasswordReset'
import {getUser} from '../services/mixins/mixins'
import Users from '../views/users/index'
import User from '../views/users/edit'
import EditWorkSchedule from '../views/scheduling/index'
import Clinic from '../views/clinic_reconciliations/clinic'
import ViewReconciliation from '../views/clinic_reconciliations/view_reconciliation'
import ViewScreeningData from '../views/screening/view_screening_data'
import Reconciliation from '../views/clinic_reconciliations/reconcile'
import ReferralQueue from '../views/referral_queue/main'
import Training from '../views/training/index'
import TrainingUserHome from '../views/training/user_home'
import Timesheets from '../views/timesheets/timesheets'
import ClinicAttendance from '../views/clinic/attendance'
import ResourcesHome from '../views/resources/resources_home'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: "Home",
        component: Home,
        meta: { requiresAuth: true }
    },

    {
        path: '/profile/:user',
        name: "ProfileHome",
        component: ProfileHome,
        props: true,
        meta: { requiresAuth: true }
    },

    {
        path: '/login',
        name: "Login",
        component: Login,        
    },

    {
        path: '/password_reset/:username',
        name: 'PasswordReset',
        component: PasswordReset,
        props: true
    },

    {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: { requiresAuth: true }
    },

    {
        path: '/user/:user',
        name: 'User',
        component: User,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/leave/:user',
        name: 'Leave',
        component: LeaveHome,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/leave_requests',
        name: 'Leave Requests',
        component: LeaveRequests,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/groups',
        name: 'Groups',
        component: Groups,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/venues',
        name: 'Venues',
        component: Venues,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/venue/:venue',
        name: 'Venue',
        component: Venue,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/events',
        name: 'Events',
        component: Events,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/schedule_types',
        name: 'ScheduleTypes',
        component: ScheduleTypes,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/mandatory_training',
        name: 'MandatoryTraining',
        component: MandatoryTraining,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/work_schedule',
        name: 'EditWorkSchedule',
        component: EditWorkSchedule,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/clinic/:clinic',
        name: 'Clinic',
        component: Clinic,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/reconciliation/:reconciliation',
        name: 'Reconciliation',
        component: Reconciliation,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/view_reconciliation/:clinic',
        name: 'ViewReconciliation',
        component: ViewReconciliation,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/view_screening_data/:clinic_reconciliation',
        name: 'ViewScreeningData',
        component: ViewScreeningData,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/referral_queue',
        name: 'ReferralQueue',
        component: ReferralQueue,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/training',
        name: 'Training',
        component: Training,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/timesheets',
        name: 'Timesheets',
        component: Timesheets,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/attendance',
        name: 'ClinicAttendance',
        component: ClinicAttendance,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/training_user_home',
        name: 'TrainingUserHome',
        component: TrainingUserHome,
        meta: { requiresAuth: true },
        props: true
    },

    {
        path: '/resources',
        name: 'ResourcesHome',
        component: ResourcesHome,
        meta: { requiresAuth: true },
        props: true
    },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {    

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if(Store.state.session === null){
          const myAws = new EosAwsCognito(EosPoolData);
          myAws.getCurrentSession()
          .then(session => {
            Store.commit('setSession', session); 
            getUser(session.session.idToken.jwtToken, session.cognitoUser.username);
            next();
          })
          .catch(err => {
            console.log(err);
            Store.commit('setSession', null)
            next({        
              name: 'Login',
              path: '/login'
            })
          })
        }
        else if(Store.state.session !== null && Store.state.session.session.isValid())
        {
          getUser(Store.state.session.session.idToken.jwtToken, Store.state.session.cognitoUser.username);
          next();
        }
        else{
          Store.commit('setRedirectAfterLogin', to.fullPath)
          next({        
            name: 'Login',
            path: '/login'
          })
        }       
    }
    else{
      next();
    }
});

export default router
