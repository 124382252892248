var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))])],1),_c('table',{staticClass:"work_calendar_table"},[_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.daysInMonth),function(day){return _c('th',[_vm._v(_vm._s(day))])})],2),_c('tr',[_c('th'),_vm._l((_vm.daysInMonth),function(day){return _c('th',[_vm._v(_vm._s(_vm.dayOfMonth(day)))])})],2)]),_c('tbody',[_vm._l((_vm.staff),function(member){return _c('tr',[_c('td',[_vm._v(_vm._s(member.name))]),_vm._l((_vm.daysInMonth),function(day){return _c('td',[(_vm.clinicOnDateWithUser(_vm.dateOfMonth(day), member).length)?_vm._l((_vm.clinicOnDateWithUser(_vm.dateOfMonth(day), member)),function(clinic){return _c('work-calendar-tile',{attrs:{"contentData":{
                            date: _vm.dateOfMonth(day),
                            employee: member,
                            clinic: clinic,
                            group: _vm.title,
                            bank_holiday: _vm.bankHolidayOnDate(_vm.dateOfMonth(day)),
                            company_event: _vm.companyEventOnDate(_vm.dateOfMonth(day))
                        }},on:{"click":_vm.processCalendarTileClickEvent}})}):[_c('work-calendar-tile',{attrs:{"contentData":{
                            date: _vm.dateOfMonth(day),
                            employee: member,
                            clinic: null,
                            group: _vm.title,
                            bank_holiday: _vm.bankHolidayOnDate(_vm.dateOfMonth(day)),
                            company_event: _vm.companyEventOnDate(_vm.dateOfMonth(day))
                        }},on:{"click":_vm.processCalendarTileClickEvent}})]],2)})],2)}),(_vm.unassignedClinics.length)?_c('tr',[_c('td',[_vm._v("Unassigned")]),_vm._l((_vm.daysInMonth),function(day){return _c('td',[(_vm.clinicOnDateUnassigned(_vm.dateOfMonth(day)))?_vm._l((_vm.clinicOnDateUnassigned(_vm.dateOfMonth(day))),function(clinic){return _c('work-calendar-tile',{attrs:{"contentData":{
                            date: _vm.dateOfMonth(day),
                            employee: null,
                            clinic: clinic,
                            group: _vm.title,
                            bank_holiday: _vm.bankHolidayOnDate(_vm.dateOfMonth(day)),
                            company_event: _vm.companyEventOnDate(_vm.dateOfMonth(day))
                        }},on:{"click":_vm.processCalendarTileClickEvent}})}):_vm._e()],2)})],2):_vm._e()],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }