<template>
  <v-app>
    <v-app-bar
    app
    color="black"
    dark>    
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>EOS Staff Portal</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-img 
      @click="$router.push('/')" style="cursor:pointer"
      max-width="48"
      class="mr-3"
      src="./assets/eos_48.png"></v-img>
      
    </v-app-bar>
    <v-navigation-drawer
    app
    temporary
    v-model="drawer">

      <v-list>
        <v-list-item
        v-if="sessionValid && currentUser">
          <v-list-item-content>
            <v-list-item-title>
                {{currentUser.name}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
        v-if="!sessionValid"
        to="/login"        
        link>
          <v-list-item-title>
            Login
          </v-list-item-title>
        </v-list-item>
        <v-list-item
        v-if="sessionValid"
        to="/"        
        link>
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Home
          </v-list-item-title>
        </v-list-item>
        <v-list-item
        v-if="sessionValid && $ability.can('view', 'resources')"
        to="/resources"        
        link>
          <v-list-item-icon>
            <v-icon>mdi-library</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Resources
          </v-list-item-title>
        </v-list-item>
        <v-list-group
        v-if="sessionValid && currentUser"
        prepend-icon="mdi-account-circle">
        <template v-slot:activator>
          <v-list-item-title>My Profile</v-list-item-title>
        </template>
            <v-list-item        
            :to="'/profile/' + currentUser.aws_sub"
            link>
              <v-list-item-title>
                Profile
              </v-list-item-title>
            </v-list-item>
            <v-list-item        
            :to="'/leave/' + currentUser.aws_sub"
            link>
              <v-list-item-title>
                My Leave
              </v-list-item-title>
            </v-list-item>
            <v-list-item
            to="/training_user_home"
            link>
              <v-list-item-title>
                My Training
              </v-list-item-title>
            </v-list-item>
        </v-list-group>
        <v-list-group
        v-if="sessionValid && $ability.can('edit', 'other users')"
        prepend-icon="mdi-account-supervisor-circle-outline">
          <template v-slot:activator>
            <v-list-item-title>User Management</v-list-item-title>
          </template>
          <v-list-item
          to="/users"
          link>
            <v-list-item-title>
              Users
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
        v-if="sessionValid && $ability.can('edit', 'referrals')"
        prepend-icon="mdi-hospital-building">
          <template v-slot:activator>
            <v-list-item-title>Referral Management</v-list-item-title>
          </template>
          <v-list-item
          link
          to="/referral_queue">
            <v-list-item-title>Referral Queues</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
        v-if="sessionValid && $ability.can('edit', 'training')"
        prepend-icon="mdi-school">
          <template v-slot:activator>
            <v-list-item-title>Training</v-list-item-title>
          </template>
          <v-list-item
          link
          to="/training">
            <v-list-item-title>Training dashboard</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
        v-if="sessionValid && $ability.can('edit', 'leave')"
        prepend-icon="mdi-beach">
          <template v-slot:activator>
            <v-list-item-title>Leave Management</v-list-item-title>
          </template>
          <v-list-item
          link
          to="/leave_requests">
            <v-list-item-title>Leave Requests</v-list-item-title>
          </v-list-item>          
        </v-list-group>
        <v-list-group
        v-if="sessionValid && $ability.can('edit', 'clinics')"
        prepend-icon="mdi-calendar-edit">
          <template v-slot:activator>
            <v-list-item-title>Scheduling</v-list-item-title>
          </template>
          <v-list-item
          link
          to="/work_schedule">
            <v-list-item-title>Edit Work Schedule</v-list-item-title>
          </v-list-item>
          <v-list-item
          link
          to="/timesheets">
            <v-list-item-title>Timesheets</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
        v-if="sessionValid && $ability.can('view', 'reconciliations')"
        prepend-icon="mdi-glasses">
          <template v-slot:activator>
            <v-list-item-title>Clinics</v-list-item-title>
          </template>
          <v-list-item
          link
          to="/attendance">
            <v-list-item-title>Attendance</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-group
        v-if="sessionValid && ($ability.can('access', 'settings') || $ability.can('edit', 'training'))"
        prepend-icon="mdi-cog">
          <template v-slot:activator>
            <v-list-item-title>Settings</v-list-item-title>          
          </template>
          <v-list-item
          link
          v-if="$ability.can('access', 'settings')"
          to="/groups">
            <v-list-item-title>Groups</v-list-item-title>
          </v-list-item>
          <v-list-item
          link
          v-if="$ability.can('access', 'settings')"
          to="/venues">
            <v-list-item-title>Venues</v-list-item-title>
          </v-list-item>
          <v-list-item
          link
          v-if="$ability.can('access', 'settings')"
          to="/events">
            <v-list-item-title>Events</v-list-item-title>
          </v-list-item>
          <v-list-item
          link
          v-if="$ability.can('access', 'settings')"
          to="/schedule_types">
            <v-list-item-title>Schedule Types</v-list-item-title>
          </v-list-item>
          <v-list-item
          link
          v-if="$ability.can('edit', 'training')"
          to="/mandatory_training">
            <v-list-item-title>Mandatory Training</v-list-item-title>
          </v-list-item>          
        </v-list-group>
        <v-list-item
        v-if="sessionValid"
        @click="logout"
        link>
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Logout
          </v-list-item-title>
        </v-list-item> 
      </v-list>
        
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>


export default {
  name: 'App',

  data: function(){
    return {
      drawer: false
    }
  },

  computed: {
    sessionValid: function(){
      if(this.$store.state.session)
        return this.$store.state.session.session.isValid();
      return false;
    },
    currentUser: function()
    {
      if(this.sessionValid){
        this.$ability.update(AbilityRules);
        return this.$store.state.user;
      }
      return null;
    }
  },

  methods: {
    logout(){
      const myAws = new EosAwsCognito(EosPoolData);
      try{
        if(myAws.logout()){
          this.$store.commit('setSession', null); 
          this.$router.push('/login');
        }
      }
      catch(err)
      {
          console.log(err);
      }
    }
  }
  
};
</script>
