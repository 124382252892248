<template>
    <v-card v-if="$ability.can('edit', 'other users')">        
        <v-card-text>
            <v-card>
                <v-card-title>Users</v-card-title>
                <v-card-actions>
                   <v-spacer></v-spacer>
                    <v-checkbox
                    v-model="showDeletedUsers"
                    @change="showDeactivatedUsers"
                    label="Show Deactivated Users"></v-checkbox> 
                </v-card-actions>                    
                <v-card-text>
                    <v-data-table
                    :items="users"
                    :headers="tableHeaders">
                        <template v-slot:item.permission="{ item }">
                            {{displayPermissions(item.permission)}}
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <div class="d-flex">
                                <v-btn icon :to="'/user/'+item.aws_sub">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn> 
                                <v-btn icon 
                                @click="restoreUser(item)"
                                v-if="item.deleted_at !== null">
                                    <v-icon>mdi-delete-restore</v-icon>
                                </v-btn>                                
                            </div>                                                    
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-btn
                fab
                absolute
                bottom
                right
                @click="newUser"
                color="primary">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>                    
            </v-card>                
        </v-card-text>
        <v-dialog
        max-width="400"
        v-model="showNewuserDialog">
            <v-card>
                <v-card-title>User</v-card-title>
                <v-card-text>
                    <v-text-field
                    v-model="user.name"
                    label="Name"></v-text-field>
                    <v-text-field
                    v-model="user.email"
                    label="E-mail"></v-text-field>
                    <v-text-field
                    v-model="user.phone_number"
                    label="Mobile"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    fab
                    dark
                    @click="showNewuserDialog = false"
                    color="pink">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                    fab
                    @click="saveNewUser"
                    color="primary">
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script>    

    const blankUser = {
        name: null,
        email: null,
        phone_number: null                    
    };
    export default{
        name: "Users",
        data: function(){
            return {
                users: [],
                tableHeaders: config.users.tableHeaders,
                showNewuserDialog: false,
                user: blankUser,
                myAws: new EosPortalApi(config.api.root),
                myAwsAdmin: new EosAwsCognitoAdmin(config.cognito.identityPoolId, config.cognito.region, config.cognito.UserPoolId, this.$store.state.session.session.idToken.jwtToken),
                showAlert: false,
                alertType: 'success',
                alertText: null,
                showDeletedUsers: false,
                busy: false
            }
        },

        mounted()
        {
            this.getUsers();
        },

        methods: {
            getUsers(filters = [], showDeleted = false){  
                if(!this.busy)  {
                    this.busy = true;
                }             
                this.myAws.setAuthToken(this.$store.state.session.session.idToken.jwtToken)
                .Users().Filters(filters).WithTrashed(showDeleted).get()
                .then(({data}) => {
                    this.busy = false;
                    this.users = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                })
            },

            displayPermissions(permissions)
            {                
                var list = '';
                permissions.forEach(permission => {
                    list += `${permission.displayed_as}, `;
                });
                return list;
            },

            newUser()
            {
                this.user = blankUser;
                this.showNewuserDialog = true;
            },

            saveNewUser()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                var newUser = this.createAttributeList();
                this.myAwsAdmin.adminCreateNewUser(this.user.email, config.cognito.UserPoolId, newUser.attributeList)
                .then(result => {
                    return this.saveNewAwsUserToServer(result.User.Username);
                })
                .then(({data}) => {
                    this.busy = false;
                    this.users.splice(0,0,data);
                    this.showNewuserDialog = false;
                    this.alertText = 'User created';
                    this.alertType = 'success';
                    this.showAlert = true;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                })
            },

            saveNewAwsUserToServer(aws_sub)
            {                
                this.myAws.Refresh();
                return this.myAws.setAuthToken(this.$store.state.session.session.idToken.jwtToken)
                .User()
                .put({
                    name: this.user.name,
                    phone_number: this.user.phone_number,
                    email: this.user.email,
                    aws_sub: aws_sub,
                    parent_id: this.$store.state.user.id
                });                
            },

            createAttributeList()
            {
                return new User(this.user.email, null, [
                    {
                        Name: 'phone_number',
                        Value: this.user.phone_number
                    },
                    {
                        Name: 'email',
                        Value: this.user.email
                    },
                    {
                        Name: 'name',
                        Value: this.user.name
                    }
                ]);
            },

            showDeactivatedUsers(value)
            {
                if(value)
                    this.getUsers([], true);
                else
                    this.getUsers();
            },

            restoreUser(user)
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myAwsAdmin.adminEnableUser(user.aws_sub, config.cognito.UserPoolId)
                .then(result => {
                    if(result.$metadata.httpStatusCode === 200)
                    {
                        this.busy = false;
                        this.alertText = 'User re-activated';
                        this.alertType = 'success';
                        this.showAlert = true;
                        return this.myAws.User(user.id).restore();                        
                    }
                })
                .then(({data}) => {
                    if(data === 'User re-activated')
                    {
                        this.busy = false;
                        user.deleted_at = null;
                        this.alertText = 'The user has been re-activated and can login';
                        this.alertType = 'success';
                        this.showAlert = true;
                    }
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            }

        }
    }

</script>