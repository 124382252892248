<template>
    <v-container v-if="$ability.can('edit', 'leave')">        
        <v-card>
            <v-btn
            fab
            absolute
            bottom
            right
            @click="newLeaveRequest"
            color="primary">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-card-title>Leave Management</v-card-title>
            <v-card-text>
                <v-toolbar flat class="pa-8 mb-4">
                    <v-text-field
                    @change="getRequests"
                    type="date"
                    v-model="leave_year_start"
                    label="From"></v-text-field>
                    <v-spacer></v-spacer>
                    <v-text-field
                    @change="getRequests"
                    type="date"
                    v-model="leave_year_end"
                    label="To"></v-text-field>
                </v-toolbar>
                <v-tabs v-model="tab">
                    <v-tab>Open Requests</v-tab>
                    <v-tab>Closed Requests</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card>                            
                            <v-card-text>
                                <v-data-table
                                :items="open_requests"
                                :headers="leaveRequestHeaders">
                                    <template v-slot:item.user="{item}">
                                        {{ item.user.name }}
                                    </template>
                                    <template v-slot:item.from="{item}">
                                        {{ displayNiceDate(item.from, 'DD-MM-YYYY') }}
                                    </template>  
                                    <template v-slot:item.to="{item}">
                                        {{ displayNiceDate(item.to, 'DD-MM-YYYY') }}
                                    </template>                             
                                    <template v-slot:item.actions="{item}">
                                        <v-btn
                                        color="primary"
                                        v-if="!item.approved"
                                        @click="editRequest(item)"
                                        icon>
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>                                
                                        <v-btn
                                        color="teal lighten-1"
                                        v-if="!item.approved"
                                        @click="approveRequest(item)"
                                        icon>
                                            <v-icon>mdi-account-check</v-icon>
                                        </v-btn>
                                        <v-btn
                                        color="pink"
                                        v-if="!item.approved"
                                        @click="declineRequest(item)"
                                        icon>
                                            <v-icon>mdi-account-cancel</v-icon>
                                        </v-btn>                    
                                    </template>   
                                </v-data-table>
                            </v-card-text>                        
                        </v-card>                
                    </v-tab-item>
                    <v-tab-item>
                        <v-container>
                            <v-data-table
                            :items="closed_requests"
                            :headers="leaveRequestHeaders">
                                <template v-slot:item.user="{item}">
                                        {{ item.user.name }}
                                </template>
                                <template v-slot:item.from="{item}">
                                    {{ displayNiceDate(item.from, 'DD-MM-YYYY') }}
                                </template>  
                                <template v-slot:item.to="{item}">
                                    {{ displayNiceDate(item.to, 'DD-MM-YYYY') }}
                                </template>                    
                            </v-data-table>
                        </v-container>                
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>                
        </v-card>  
        <v-dialog
        persistent
        v-model="showEditRequest">
            <v-card>
                <v-card-title>Approver Comment</v-card-title>
                <v-card-text>
                    <v-text-field
                    label="Comment"
                    v-model="approver_comment"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    fab
                    dark
                    @click="showEditRequest = false"
                    color="pink">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                    fab
                    @click="saveEditRequest"
                    color="primary">
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <leave-request-form
        v-if="myuser"
        :fields="userLeaveRequest"
        :user="myuser"
        :personalRequest="false"
        @saveRequest="saveLeaveRequest"
        @close="showDialogForm = false"
        :showDialog="showDialogForm"></leave-request-form>
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>

    import LeaveRequestForm from './LeaveRequestForm';

    const blankRequest = {
        user_id: null,
        leave_type: null,
        from: null,
        to: null,        
        request_comment: null,
        approved: null,
        declined: null
    };

    export default{
        name: "LeaveRequests",
        props: {

        },

        components: {
            LeaveRequestForm
        },

        data: function(){
            return {
                tab: null,
                leaveRequestHeaders: config.users.leaveRequestHeaders,
                myEos: new EosPortalApi(config.api.root),
                all_requests: null,
                open_requests: [],
                closed_requests: [],
                leave_year_start: dayjs().dayOfYear(config.leave.leaveYearBegin).format('YYYY-MM-DD'),
                leave_year_end: dayjs().dayOfYear(config.leave.leaveYearEnd).format('YYYY-MM-DD'),
                showEditRequest: false,
                approver_comment: null,
                requestToEdit: null,
                userLeaveRequest: blankRequest,
                showDialogForm: false,
                showAlert: false,
                alertType: 'success',
                alertText: null,
            }
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);            
            this.getRequests();            
        },

        computed:{
            myuser: function(){
                return this.$store.state.user;
            }
        },

        methods: {
            getRequests(){
                this.myEos.HolidayRequests()
                .Relations(['User'])
                .Filters({
                    from: this.leave_year_start,
                    to: this.leave_year_end
                }).get()
                .then(({data}) => {
                    this.all_requests = data;  
                    this.sortRequests();                  
                })
                .catch(err => {
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            sortRequests()
            {
                this.open_requests = this.all_requests.filter(request => {
                    return request.approved === null && request.declined === null;
                });
                this.closed_requests = this.all_requests.filter(request => {
                    return request.approved !== null || request.declined !== null;
                });
            },

            updateAllRequests(request)
            {
                var index = this.all_requests.findIndex(req => {
                    return req.id === request.id;
                });
                if(index !== -1){
                    this.all_requests.splice(index, 1, request);
                    this.sortRequests();
                }                    
            },

            updateRequest(request)
            {
                this.myEos.HolidayRequest(request.id)
                .patch(request)
                .then(({data}) => {
                    this.updateAllRequests(data);
                })
                .catch(err => {
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            approveRequest(request)
            {
                request.approved = dayjs().format('YYYY-MM-DD HH:mm:ss');
                request.approver_id = this.$store.state.user.id;
                this.updateRequest(request);
            },

            declineRequest(request)
            {
                request.declined = dayjs().format('YYYY-MM-DD HH:mm:ss');
                request.approver_id = this.$store.state.user.id;
                this.updateRequest(request);
            },

            editRequest(request)
            {
                this.requestToEdit = request;
                this.showEditRequest = true;                   
            },

            saveEditRequest()
            {
                this.requestToEdit.approver_comment = this.approver_comment;
                this.updateRequest(this.requestToEdit);
                this.showEditRequest = false; 
                this.requestToEdit  = null;
            },

            displayNiceDate(date)
            {
                return dayjs(date).format('DD-MM-YYYY');
            },

            newLeaveRequest()
            {
                this.userLeaveRequest = blankRequest;
                this.showDialogForm = true;
            },

            saveLeaveRequest(fields)
            {
                this.myEos.HolidayRequest()
                .Relations(['Approver', 'User'])
                .put(fields)
                .then(({data}) => {
                    this.showDialogForm = false;
                    this.all_requests.splice(0,0, data);
                    this.sortRequests();
                })                                
                .catch(err => {
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },
        }
    }

</script>