var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.myuser)?_c('v-card',[_c('v-card-title',[_vm._v("Leave Requests for "+_vm._s(_vm.myuser.name))]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.myuser.holiday_request,"headers":_vm.leaveRequestHeaders},scopedSlots:_vm._u([{key:"item.from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayNiceDate(item.from, 'DD-MM-YYYY'))+" ")]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayNiceDate(item.to, 'DD-MM-YYYY'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.approved)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteRequest(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,false,3409739481)})],1),_c('v-btn',{attrs:{"color":"primary","fab":"","absolute":"","bottom":"","right":""},on:{"click":_vm.newLeaveRequest}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('leave-request-form',{attrs:{"fields":_vm.userLeaveRequest,"user":_vm.myuser,"showDialog":_vm.showDialogForm},on:{"saveRequest":_vm.saveLeaveRequest,"close":function($event){_vm.showDialogForm = false}}}),_c('v-snackbar',{attrs:{"top":"","color":_vm.alertType},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showAlert = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,1476558398),model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]),_c('v-overlay',{attrs:{"absolute":false,"opacity":0.45,"value":_vm.busy,"z-index":1000}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"100","width":"12"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }