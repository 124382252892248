<template>
    <div class="overflow-x-auto mx-6">
        <div class="text-h5 py-4" v-if="user">{{user.name}} Dashboard</div> 
        <v-card 
        class="mb-4"
        max-width="350"
        v-if="myclinics">
            <v-card-title>Time till next shift: {{timeTillNextShift.days}}d {{timeTillNextShift.hours}}h {{timeTillNextShift.minutes}}m</v-card-title>            
        </v-card>
        <v-card 
        max-width="350"
        v-if="myclinics">
            <v-card-title>Time till next leave: {{timeTillNextLeave.days}}d {{timeTillNextLeave.hours}}h {{timeTillNextLeave.minutes}}m</v-card-title>            
        </v-card> 
        <div class="text-h6 py-3">Work & Leave</div>
        <div>
            <v-menu 
            ref="monthPicker"   
            max-width="290px"     
            :close-on-content-click="false"
            v-model="monthPicker">
                <template v-slot:activator="{on}">
                    <v-text-field                     
                    prepend-icon="mdi-calendar"
                    v-model="month"
                    label="Month"
                    v-on="on"
                    readonly>                        
                    </v-text-field>
                </template>
                <v-date-picker
                @change="monthPicker = false"
                type="month"
                no-title
                scrollable
                v-model="month">
                    <v-spacer></v-spacer>
                    <v-btn
                    text
                    dark
                    @click="monthPicker = false"
                    color="pink">Cancel</v-btn>                    
                </v-date-picker>
            </v-menu>
        </div>  
        <home-calendar
        :user="user"
        :year="year"
        :clinics="myclinics"
        :company_events="company_events"
        :bank_holidays="bank_holidays"
        @click="processCalendarClickEvent"
        :month="monthNumber"></home-calendar>              
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>              
    </div>
</template>

<script>

    import axios from 'axios';
    import HomeCalendar from '../../components/EpHomeCalendar';

    export default{
        name: "Home",

        components: {
            HomeCalendar
        },

        data: function(){
            return {   
                myclinics: [], 
                bank_holidays: [],
                company_events: [], 
                allevents: [],                           
                myEos: new EosPortalApi(config.api.root),
                month: dayjs().format('YYYY-MM'),
                monthPicker: false,
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,
            }
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);
            this.getBankHolidays();
            this.getEvents();
            if(this.user)   
                this.getClinics();         
        },

        watch: {
            user: function()
            {
                this.getClinics();
            },
            monthNumber: function()
            {
                this.getClinics();
                this.getEvents();
            }
        },

        methods:{
            processCalendarClickEvent(event)
            {
                if(!this.$ability.can('reconcile', 'clinics'))
                {
                    this.alertText = "You do not have permission to reconcile this clinic";
                    this.alertType = 'error';
                    this.showAlert = true;
                    return;
                }
                this.$store.commit('setClinic', event.clinic);
                this.$router.push('/clinic/'+event.clinic.id);
            },

            getBankHolidays()
            {  
                if(!this.busy)  {
                    this.busy = true;
                }              
                axios.get(this.bankHolidayApi)   
                .then(({data}) => {
                    this.bank_holidays = this.filterBankHolidaysByYear(data['england-and-wales'].events);
                }) 
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });            
            },

            getEvents()
            {  
                if(!this.busy)  {
                    this.busy = true;
                } 
                this.myEos.CompanyEvents().Filters({
                    from: this.firstOfMonth.format('YYYY-MM-DD'),
                    to: this.lastOfMonth.format('YYYY-MM-DD')
                }).Relations([]).get()                
                .then(({data}) => {
                    this.busy = false;
                    this.company_events = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },
            
            getClinics(){
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Clinics().Filters({
                    with_user: this.user.id,
                    from: this.firstOfMonth.format('YYYY-MM-DD'),
                    to: this.lastOfMonth.format('YYYY-MM-DD')
                }).Relations(['User', 'Venue', 'ClinicReconciliation.Clinic.Venue', 'ClinicReconciliation.Screening.Grading']).get()
                .then(({data}) => {
                    this.busy = false;
                    this.myclinics = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            filterBankHolidaysByYear(data)
            {
                return data.filter(item => {
                    return dayjs().isSame(item.date, 'year');
                });
            }
        },

        computed: {
            bankHolidayApi: function()
            {
                return config.bankHolidayApi;
            },

            user: function(){
                return this.$store.state.user;
            },

            userGroups: function()
            {
                return this.user.group.map(group => {
                    return group.id;
                });
            },

            monthNumber: function()
            {
                return dayjs(this.month).month();
            },

            year: function()
            {
                return dayjs(this.month).year();
            },

            firstOfMonth: function()
            {                
                return dayjs(this.month).startOf('month');
            },
            
            lastOfMonth: function()
            {                
                return this.firstOfMonth.endOf('month');
            },

            minutesTillNextShift: function(){
                return this.myclinics.filter(clinic => {
                    return dayjs().isSameOrBefore(dayjs(clinic.clinic_date));
                }).map(clinic => {
                    return dayjs(`${clinic.clinic_date} ${clinic.start_time}`).diff(dayjs(), 'minutes');
                }).sort((a, b) => a - b);
            },

            timeTillNextShift: function()
            { 
                if(this.minutesTillNextShift.length)
                {
                    var minutesTotal = Big(this.minutesTillNextShift[0]);
                    var days = minutesTotal.div(1440).round(0,0);
                    var minutesLeft = minutesTotal.mod(1440);
                    var hours = minutesLeft.div(60).round(0,0);
                    minutesLeft = minutesLeft.minus(hours.times(60));
                    return {
                        days: days.toNumber(),
                        hours: hours.toNumber(),
                        minutes: minutesLeft.toNumber()
                    }
                }               
                return {
                    days: 'NA',
                    hours: 'NA',
                    minutes:'NA' 
                }    
            },

            minutesTillNextLeave: function(){
                if(!this.user)
                    return [];
                return this.user.holiday_request.filter(hol => {
                    return dayjs().isSameOrBefore(dayjs(hol.from));
                }).map(hol => {
                    return dayjs(`${hol.from}`).diff(dayjs(), 'minutes');
                }).sort((a, b) => a - b);
            },

            timeTillNextLeave: function(){
                if(this.minutesTillNextLeave.length)
                {
                    var minutesTotal = Big(this.minutesTillNextLeave[0]);
                    var days = minutesTotal.div(1440).round(0,0);
                    var minutesLeft = minutesTotal.mod(1440);
                    var hours = minutesLeft.div(60).round(0,0);
                    minutesLeft = minutesLeft.minus(hours.times(60));
                    return {
                        days: days.toNumber(),
                        hours: hours.toNumber(),
                        minutes: minutesLeft.toNumber()
                    } 
                }
                return {
                    days: 'NA',
                    hours: 'NA',
                    minutes:'NA' 
                } 
            }
        }
    }

</script>