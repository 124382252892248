<template>
    <v-card max-width="350" class="mx-auto mt-2">
        <div class="p-5 black white--text mx-auto">
            <v-card-title class="justify-center">Please login</v-card-title>            
        </div>
        <v-card-text>
            <v-text-field    
            :error-messages="errorMessages"        
            label="E-mail Address"
            v-model="Username">
            </v-text-field>

            <v-text-field 
            :error-messages="errorMessages"           
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            :type="show ? 'text' : 'password'"
            label="Password"
            v-model="Password">
            </v-text-field>
        </v-card-text>
        <v-card-actions>
            <div class="flex-col">
                <div class="flex p-4">
                    <v-btn color="black" dark @click="userLogin">Login</v-btn>   
                    <v-btn
                    text
                    color="primary"
                    @click="requestPasswordReset">Forgot your password</v-btn>                 
                </div>               
            </div>
        </v-card-actions>
        
        <v-snackbar
        top
        :color="snackBarColor"
        dark
        v-model="snackbar">                
          {{ registerMessage }}          
        </v-snackbar>
        <v-dialog
        class="accent"
        max-width="350"
        v-model="showCompleteRegistration">
            <v-card class="pa-4">
                <v-card-title>Reset Temporary Password</v-card-title>   
                <v-card-subtitle>You are required to reset your temporary password at first login</v-card-subtitle>  
                <v-text-field
                v-model="UsersNameActual"
                label="Name">                 
                </v-text-field> 
                <v-text-field
                v-model="phoneNumber"
                label="Mobile Number">                 
                </v-text-field>           
                <v-text-field 
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show1 = !show1"
                :error-messages="errorMessages"
                label="New Password"
                :type="show2 ? 'text' : 'password'"
                v-model="newPassword"></v-text-field>
                <v-text-field 
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="show2 = !show2"
                label="Confirm Password"
                :type="show2 ? 'text' : 'password'"
                v-model="confirmPassword"></v-text-field>
                <v-card-actions>                    
                    <v-btn
                    :disabled="newPassword !== confirmPassword"
                    @click="completeRegistrationForAdminUser"
                    color="primary">Reset password</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
        class="accent"
        max-width="350"
        v-model="showCaptureCode">
            <get-verification-code    
            title="Enter Authentication Code"
            message=""
            buttonText="Verify"
            button2Text="Request another code"
            :showButton2="false"
            callback1="verify"
            callback2="resendCode"
            @verify="verifyCode"
            ></get-verification-code>
            
        </v-dialog>
        
    </v-card>
</template>

<script>

    import GetVerificationCode from '../../components/EpGetVerificationCode.vue'

    export default{
        name: "Login",

        data: function(){
            return {
                Username: null,
                Password: null,
                show: false,
                showCompleteRegistration: false,
                showCaptureCode: false,
                show1: false,
                show2: false,
                newPassword: null,
                confirmPassword: null,
                errorMessages: null,
                cognitoUser: null,
                mfaCodeType: null,
                userAttributes: null,
                UsersNameActual: null,
                phoneNumber: null,
                snackBarColor: 'pink',
                snackbar: false,                
                registerMessage: null
            }
        },

        components:
        {
            GetVerificationCode
        },

        methods: {
            userLogin(){
                const myAws = new EosAwsCognito(EosPoolData);
                const user = new User(this.Username, this.Password);
                myAws.login(user)
                .then(result => {
                    switch(result.status){
                        case 'A TOTP is required':
                        case 'An SMS MFA code is required':
                            this.captureMfaCode(result.data.cognitoUser, result.data.result);
                        break;

                        case 'A new password is required':
                            this.completeNewPasswordChallenge(result);
                        break;

                        default:
                            myAws.getCurrentSession()
                            .then(session => {
                                this.setSessionAndRedirect(session)
                            })                            
                        break;
                    }
                })
                .catch(reject => {                    
                    this.errorMessages = [reject.data.message];
                });
            },

            captureMfaCode(cognitoUser, result)
            {
                this.cognitoUser = cognitoUser;
                this.mfaCodeType = result;
                this.showCaptureCode = true;
            },

            verifyCode(code)
            {
                const myAws = new EosAwsCognito(EosPoolData);
                myAws.sendMfaCode(code, this.cognitoUser, this.mfaCodeType)
                .then(result => {
                    return myAws.getCurrentSession();                    
                })
                .then(session => {
                    this.setSessionAndRedirect(session)
                })
                .catch(reject => {
                    this.registerMessage = reject.message;
                    this.snackbar = true;
                })
            },

            completeRegistrationForAdminUser()
            {
                this.userAttributes['phone_number'] = this.phoneNumber;
                this.userAttributes['name'] = this.UsersNameActual;
                if(this.userAttributes.hasOwnProperty('phone_number_verified'))
                    delete this.userAttributes.phone_number_verified;
                if(this.userAttributes.hasOwnProperty('email_verified'))
                    delete this.userAttributes.email_verified;
                const myAws = new EosAwsCognito(EosPoolData);
                myAws.completePasswordChallenge(this.cognitoUser, this.newPassword, this.userAttributes)
                .then((result) => {
                    myAws.getCurrentSession()
                    .then(session => {
                        this.setSessionAndRedirect(session)
                    })
                })
                .catch(reject => {
                   this.registerMessage = reject.message;
                   this.snackbar = true;
                });
            },
            
            completeNewPasswordChallenge(result)
            {
                this.cognitoUser = result.data.cognitoUser;
                this.userAttributes = result.data.result.userAttributes;
                this.showCompleteRegistration = true;
            },

            setSessionAndRedirect(session){                
                this.$store.commit('setSession', session); 
                this.$router.push('/');    
            },

            requestPasswordReset()
            {
                if(this.Username === null)
                {
                    this.registerMessage = "Please enter your email/username first";
                    this.snackbar = true;
                }
                else{
                    const myAws = new EosAwsCognito(EosPoolData);
                    const user = new User(this.Username, '');
                    myAws.requestUserPasswordReset(user)
                    .then(data => {
                        this.$router.push(`/password_reset/${this.Username}`); 
                    })
                    .catch(err => {
                        this.registerMessage = this.displayApiErrors(err);
                        this.snackbar = true;
                    })
                }
            }
        }
    }

</script>