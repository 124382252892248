<template>
    <v-dialog
    :fullscreen="$vuetify.breakpoint.mobile"
    min-width="450"
    max-width="700"
    persistent
    v-model="showTrainingDialog">
        <v-card>
            <v-card-title>{{title}}</v-card-title>
            <v-card-text>
                <v-select
                item-text="name"
                item-value="id"
                v-model="mytraining.user_id"
                label="User"
                :items="users"></v-select>
                <v-text-field
                label="Title"
                v-model="mytraining.displayed_as"></v-text-field>
                <v-text-field
                type="date"
                label="Started"
                v-model="mytraining.started"></v-text-field>
                <v-text-field
                type="date"
                label="Completed"
                v-model="mytraining.completed"></v-text-field>
                <v-text-field
                type="date"
                label="Expires"
                v-model="mytraining.expires"></v-text-field>
                <v-select
                item-text="displayed_as"
                item-value="displayed_as"
                label="Mandatory Type"
                hint="Leave balnk if non mandatory"
                v-model="mytraining.mandatory_type"
                :items="mandatory_types"></v-select>
                <v-text-field
                label="Outcome"
                v-model="mytraining.outcome"></v-text-field>                
                <v-text-field
                label="Trainee comment"
                v-model="mytraining.trainee_comment"></v-text-field>
                <v-text-field
                label="Supervisor comment"
                v-model="mytraining.supervisor_comment"></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                color="pink"
                dark
                fab
                @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-btn
                color="primary"
                fab
                @click="$emit('contentSaved', mytraining)">
                    <v-icon>mdi-content-save</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

    export default{
        name: "TrainingDialog",

        props: {
            showTrainingDialog: Boolean,
            title: String,
            users: Array,
            training: Object,
            mandatory_types: Array
        },

        data: function(){
            return {
                mytraining: JSON.parse(JSON.stringify(this.training))
            }
        },

        watch: {
            training: function()
            {
                this.mytraining = JSON.parse(JSON.stringify(this.training));
            }
        }
    }

</script>