<template>
    <v-hover v-slot="{ hover }">        
        <v-card         
        @click="$emit('click', contentData)"
        flat
        tile        
        :color="color"
        class="work_calendar_tile d-flex align-content-stretch">
            <v-card-text v-html="tileText">            
            </v-card-text>            
            <v-snackbar
            v-if="hasClinic"
            v-model="hover"
            top
            :color="color"
            >{{snackBartext}}</v-snackbar>
        </v-card>        
    </v-hover>
</template>

<script> 
    import {isDateInLeave} from '../services/mixins/mixins';

    export default{
        name: "WorkCalendarTile",

        props: {            
            contentData: {
                type: Object,
                default: null
            }
        },        

        computed: {
            color: function()
            {
                if(this.leaveRequest && this.leaveRequest.declined === null)
                    return `#${config.leave.leaveColorCodes[this.leaveRequest.leave_type]}`;
                if(this.contentData.clinic){
                    return `${this.contentData.clinic.venue.colour}`                                            
                }
                if(this.isBankHoliday)
                    return `${config.events.BankHolidayColor}`;
                if(this.isCompanyEvent)
                    return `${config.events.CompanyEventColor}`;
                if(this.isCurrentDay)
                    return `${config.currentDayColor}`;
                return '#ffffff';
            },

            tileText: function()
            {
                var text = '';
                if(this.leaveRequest && this.leaveRequest.declined === null){
                    text += `${config.leave.leaveTypesTileCode[this.leaveRequest.leave_type]}`;
                    if(this.leaveRequest.approved === null)
                        text += '(P)<br>';
                    else
                        text += '<br>';
                }
                if(this.contentData.clinic){
                    text += `${this.contentData.clinic.venue.shortcode}<br>`;
                }
                if(this.isBankHoliday)
                    text += `${this.contentData.bank_holiday.title}<br>`;
                if(this.isCompanyEvent)
                    text += `${this.contentData.company_event.displayed_as}<br>`;
                return text;
            },

            snackBartext: function()
            {
                var text = '';
                if(this.contentData.clinic){
                    var start = dayjs(this.contentData.clinic.clinic_date + ' ' + this.contentData.clinic.start_time);
                    var finish = dayjs(this.contentData.clinic.clinic_date + ' ' + this.contentData.clinic.finish_time);
                    var lunch = dayjs(this.contentData.clinic.clinic_date + ' ' + this.contentData.clinic.lunch_time);
                    text += `${this.contentData.clinic.venue.displayed_as} ${start.format('h:mm a')} - ${finish.format('h:mm a')}`;
                    if(this.contentData.clinic.has_lunch)
                        text += ` Lunch: ${lunch.format('h:mm a')}`;                    
                    if(this.contentData.clinic.comment)
                        text += ` ${this.contentData.clinic.comment}`;
                    text += ` clinic with ${this.clinicUsers}`;
                }
                return text;
            },

            hasClinic: function(){
                return this.contentData.clinic !== null;
            },

            leaveRequest: function(){
                if(!this.contentData.employee)
                    return null;
                if(this.contentData.employee.holiday_request)
                    return isDateInLeave(this.contentData.date, this.contentData.employee.holiday_request);
                return null;
            },

            isBankHoliday: function(){
                return this.contentData.bank_holiday;
            },

            isCompanyEvent: function()
            {
                return this.contentData.company_event;
            },

            isCurrentDay: function()
            {
                return this.contentData.date.isSame(dayjs(), 'day');
            },

            clinicUsers: function()
            {
                var userString = '';
                this.contentData.clinic.user.map(user => {
                    return user.name;
                }).forEach(user => {
                    userString += user + ', ';
                });
                return userString;
            }
        }

        
    }

</script>