import defineAbilityFor from '../ability';


function getUser(jwtID, id){
    const api = new EosPortalApi(config.api.root);
    api.setAuthToken(jwtID)
    .User(id)
    .Relations(['Permission','Group', 'HolidayRequest'])
    .get()
    .then(({data}) => {
        Store.commit('setUser', data);
        AbilityRules = defineAbilityFor(data, []);
        return data;
    })
    .catch(reject => {
        console.log(reject);
    });            
}

function sortIntoGroups(groups, target)
{
    var targetGroups = {};
    groups.forEach(group => {
        targetGroups[group.displayed_as] = target.filter(item => {
            if(Array.isArray(item.group))
                return item.group.find(itemGroup => {
                    return itemGroup.displayed_as === group.displayed_as
                }) !== undefined;
            else
                return item.group.displayed_as === group.displayed_as;
        });
    });
    return targetGroups;
}


function isDateInLeave(date, leave)
{
    var found = leave.find(leaveItem => { 
        return isDateInRange(date, leaveItem.from, leaveItem.to);        
    });
    if(found !== undefined)
        return found;
    return null;
}

function isDateInRange(date, datefrom, dateto)
{
    var isFrom =  dayjs(date.format('YYYY-MM-DD')).isSameOrAfter(dayjs(datefrom).format('YYYY-MM-DD'));
    var isTo = dayjs(date.format('YYYY-MM-DD')).isSameOrBefore(dayjs(dateto).format('YYYY-MM-DD'));
    return isFrom && isTo; 
}

function findGroupIdByGroupName(groups, name)
{
    var index = groups.findIndex(group => {
        return group.displayed_as === name;
    });
    if(index !== -1)
        return groups[index].id;
    return null;
}



export {
    getUser, 
    sortIntoGroups, 
    isDateInLeave, 
    isDateInRange, 
    findGroupIdByGroupName
};
export default {
    getUser, 
    sortIntoGroups, 
    isDateInLeave, 
    isDateInRange, 
    findGroupIdByGroupName
};