<template>
    <v-card max-width="350" class="mx-auto">
        <div class="black white--text mt-2 w-full">
            <v-card-title class="justify-center">Reset Password</v-card-title>            
        </div>
        <v-card-text>
            <v-text-field
            :error-messages="errormessages"
            label="E-mail Address"
            v-model="email">
            </v-text-field>

            <v-text-field 
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            :type="show ? 'text' : 'password'"
            :error-messages="errormessages"
            label="New Password" 
            v-model="password"> 
            </v-text-field>

            <v-text-field
            :error-messages="errormessages"
            label="Verification Code"
            v-model="verification_code">
            </v-text-field>            

        </v-card-text>
        <v-card-actions>
            <v-btn
            color="primary"
            type="button"
            @click="resetPassword">Reset Password</v-btn>
        </v-card-actions>

        <v-snackbar
          v-model="showSnackbar"
          color="primary"
          :timeout='5000'
          :top="true"
          :vertical="true">
          {{ snackBarText }}
          <v-btn
            color="white"
            text
            to="/login">                  
            login
          </v-btn>
        </v-snackbar>
    </v-card>
</template>

<script>

    export default{
        name: "PasswordReset",
        props: {
            username: {
                type: String,
                default: ''
            }
        },

        data: function(){
            return {
                errormessages: null,
                email: this.username,
                password: null,
                verification_code: null,
                show: false,
                showSnackbar: false,
                snackBarText: null
            }
        },

        methods: {
            resetPassword(){
                const myAws = new EosAwsCognito(EosPoolData);
                const user = new User(this.email, this.password);
                myAws.resetUserPassword(user, this.verification_code, this.password)
                .then(data => {
                    this.snackBarText = 'Password sucessfully reset';
                    this.showSnackbar = true;
                })
                .catch(err => {
                    this.errormessages = this.displayApiErrors(err);
                })
            }
        }
    }

</script>