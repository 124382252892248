<template>
    <v-dialog
    :fullscreen="$vuetify.breakpoint.mobile"
    persistent
    v-model="showScreeningDialog">
        <v-card
        v-if="screening">   
        <v-card-title>Screening data</v-card-title>         
            <v-card-text class="mb-4">
                <text-field-form
                v-model="editScreening"
                :fields="formFields"
                @contentSaved="saveScreening"
                ></text-field-form>                 
                <data-table-card               
                title="Grading"
                :data_items="myscreening.grading"
                :data_headers="gradingTableHeaders"
                :edit_button="true"
                :delete_button="true"
                :add_button="(myscreening.grading.length < 2 && myscreening.id !== null)"
                :itemSlotNames="['item.eye']"
                :itemSlotCallback="eyeValue"                                
                @addItem="addGrading"
                @editItem="editGrading"
                @deleteItem="deleteGrading">
                </data-table-card>
                   
            </v-card-text>               
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                fab
                dark
                @click="$emit('close', myscreening)"
                color="pink">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
        <dialog-form
        persistent
        v-model="showGradingDialog"
        :fields="gradingFields"
        :title="gradingDialogTitle"
        :closeButton="gradingDialogButtons.close"
        :saveContentButton="gradingDialogButtons.save"
        :deleteButton="gradingDialogButtons.delete"
        @close="showGradingDialog = false"
        @deleteContent="deleteGrading"       
        @saveContent="saveGrading">            
        </dialog-form>
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
    </v-dialog>
</template>

<script>

    import TextFieldForm from '../../components/EpTextFieldForm';
    import DataTableCard from '../../components/EpDataTableCard';
    import DialogForm from '../../components/EpDialogForm';

    const blankGrading = {
        id: null,
        screening_id: null,
        eye: null,
        acuity: null,
        acuity_type: null,
        retinopathy_grade: null,
        maculopathy_grade: null,
        laser: null
    }

    export default{
        name: "ScreeningDialog",

        props: {
            showScreeningDialog: {
                type: Boolean,
                default: false
            }, 
            screening: Object           
        },

        data: function(){
            return {
                myEos: new EosPortalApi(config.api.root),
                formFields: config.Screening.formFields,
                editScreening: false,
                gradingTableHeaders: config.Grading.gradingTableHeaders,
                myscreening: JSON.parse(JSON.stringify(this.screening)),
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,
                showGradingDialog: false,
                gradingFields: config.Grading.gradingFields,
                gradingDialogButtons: config.dialogButtons,
                gradingDialogTitle: "New Grading",
                gradingToEdit: null
            }
        },

        watch: {
            screening: function()
            {
                this.myscreening = JSON.parse(JSON.stringify(this.screening));
                this.formFields.nhs_number.model = this.screening.nhs_number;
                this.formFields.outcome.model = this.screening.outcome;
                this.formFields.comment.model = this.screening.comment;
            }
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);           
            this.formFields.nhs_number.model = this.screening.nhs_number;
            this.formFields.outcome.model = this.screening.outcome;
            this.formFields.comment.model = this.screening.comment;            
        },

        components: {
            DialogForm,
            TextFieldForm,
            DataTableCard
        },

        methods: {
            eyeValue(key, value)
            {
                var eye = ["Right", "Left"]
                return eye[value.eye];
            },

            saveScreening(event){
                this.myscreening.nhs_number = event.nhs_number;
                this.myscreening.outcome = event.outcome;
                this.myscreening.comment = event.comment;
                if (this.myscreening.id)
                    this.updateScreening()
                else
                    this.saveNewScreening();
            },

            saveNewScreening(){
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Screening().Relations(['Grading', 'ReferralQueue'])
                .put(this.myscreening)
                .then(({data}) => {
                    this.busy = false;
                    this.myscreening = data;
                })
                .catch(err => {                    
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            updateScreening(){
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Screening(this.myscreening.id).Relations(['Grading', 'ReferralQueue'])
                .patch(this.myscreening)
                .then(({data}) => {
                    this.busy = false;
                    this.myscreening = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            addGrading(){
                this.gradingToEdit = JSON.parse(JSON.stringify(blankGrading));
                this.gradingToEdit.screening_id = this.myscreening.id;
                this.gradingDialogTitle = "New Grading";
                this.gradingFields.eye.model = this.gradingToEdit.eye;
                this.gradingFields.acuity.model = this.gradingToEdit.acuity;
                this.gradingFields.acuity_type.model = this.gradingToEdit.acuity_type;
                this.gradingFields.retinopathy_grade.model = this.gradingToEdit.retinopathy_grade;
                this.gradingFields.maculopathy_grade.model = this.gradingToEdit.maculopathy_grade;
                this.gradingFields.laser.model = this.gradingToEdit.laser;
                this.showGradingDialog = true;
            },

            editGrading(grading){
                this.gradingToEdit = JSON.parse(JSON.stringify(grading));
                this.gradingDialogTitle = "Edit Grading";
                this.gradingFields.eye.model = grading.eye;
                this.gradingFields.acuity.model = grading.acuity;
                this.gradingFields.acuity_type.model = grading.acuity_type;
                this.gradingFields.retinopathy_grade.model = grading.retinopathy_grade;
                this.gradingFields.maculopathy_grade.model = grading.maculopathy_grade;
                this.gradingFields.laser.model = grading.laser;
                this.showGradingDialog = true;
            },

            deleteGrading(grading){               
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Grading(grading.id).Relations([])
                .delete()
                .then(({data}) => {
                    this.busy = false;
                    this.removeGradingFromArray(grading);
                })
                .catch(err => {                    
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },
            saveGrading(grading){
                this.gradingToEdit = this.mapDialogReturnedFields(grading);
                if(this.gradingToEdit.id)
                    this.updateGrading();
                else
                    this.saveNewGrading();
            },

            updateGrading()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Grading(this.gradingToEdit.id).Relations([])
                .patch(this.gradingToEdit)
                .then(({data}) => {
                    this.busy = false;
                    this.showGradingDialog = false;
                    this.updateGradingArray(data);
                })
                .catch(err => {                    
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            saveNewGrading(){
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Grading().Relations([])
                .put(this.gradingToEdit)
                .then(({data}) => {
                    this.busy = false;
                    this.showGradingDialog = false;
                    this.myscreening.grading.splice(0,0, data);
                })
                .catch(err => {                    
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },            

            mapDialogReturnedFields(returnedFields)
            {
                Object.entries(returnedFields).forEach(([key, value]) => {
                    if(this.gradingToEdit.hasOwnProperty(key))
                        this.gradingToEdit[key] = value.model;
                });
                return this.gradingToEdit;
            },

            updateGradingArray(grading)
            {   
                var index = this.findGradingIndex(grading);
                if(index !== -1)
                    this.myscreening.grading.splice(index, 1, grading);
                else
                    this.myscreening.grading.splice(0,0, grading);
            },

            removeGradingFromArray(grading)
            {
                var index = this.findGradingIndex(grading)
                if(index !== -1)
                    this.myscreening.grading.splice(index, 1);                
            },

            findGradingIndex(grading)
            {
                return this.myscreening.grading.findIndex(item => {
                    return item.id === grading.id;
                });
            }
        }
    }

</script>