<template> 
    <div>
        <div class="text-subtitle-1">{{month_year}}</div>
        <table class="home_calendar_table" width="80%">
            <thead>
                <th v-for="day in weekdays" :key="day.index">{{day.short}}</th>
            </thead>
            <tbody>
                <tr v-for="week in weeksInMonth">
                    <template
                    v-for="day in weekdays">
                        <td>
                            <div class="text-caption">{{calculateDate(week, day).format('DD')}}</div>
                            <work-calendar-tile 
                            @click="processClickEvent"                           
                            :contentData="{
                                date: calculateDate(week, day),
                                employee: user,
                                clinic: clinicOnDateWithUser(calculateDate(week, day), user),
                                bank_holiday: bankHolidayOnDate(calculateDate(week, day)),
                                company_event: companyEventOnDate(calculateDate(week, day))
                            }"
                            ></work-calendar-tile>
                        </td>
                    </template>                        
                </tr>
            </tbody>
        </table>
    </div>   
                
</template>
<script>

    import WorkCalendarTile from './EpWorkCalendarTile';

    export default{
        name: "HomeCalendar",
        props:{
            month: Number,
            year: Number,
            user: Object,
            clinics: Array,
            bank_holidays: Array,
            company_events: Array
        },

        components: {
            WorkCalendarTile
        },

        data: function(){
            return {
                weekdays: config.weekdays
            }
        },

        computed: {
                   
            weekNumberDayOne: function()
            {
                return this.firstOfMonth.week();
            },

            weekNumberDayLast: function()
            {
                return this.lastOfMonth.week();
            },

            weeksInMonth: function()
            {
                if(this.month === 11)
                    return (53 - this.weekNumberDayOne) + 1;
                return (this.weekNumberDayLast - this.weekNumberDayOne) + 1;
            },

            firstOfMonth: function()
            {                
                return dayjs().year(this.year).month(this.month).startOf('month');
            },
            
            lastOfMonth: function()
            {                
                return this.firstOfMonth.endOf('month');
            },

            month_year: function()
            {
                return this.firstOfMonth.format('MMMM YYYY')
            }
        },

        methods: {
            calculateDate(week, day)
            {
                var weeknumber = Big(week).minus(1).plus(Big(this.weekNumberDayOne));
                return this.firstOfMonth.week(weeknumber).day(day.index);
            },

            clinicOnDateWithUser(date, user)
            {               
                return this.clinics.find(clinic => {
                    return dayjs(clinic.clinic_date).isSame(date, 'day') && this.clinicIncludesUser(clinic, user);
                }) ?? null;
            },

            clinicIncludesUser(clinic, user)
            {
                return clinic.user.find(clinic_user => {
                    return clinic_user.id === user.id;
                }) !== undefined;
            },

            bankHolidayOnDate(date)            
            {
                return this.eventOnDate(date, this.bank_holidays, 'date');
            },

            companyEventOnDate(date)
            {
                return this.eventOnDate(date, this.company_events, 'date_of_event');
            },

            eventOnDate(date, eventArray, target)
            {
                return eventArray.find(event => {
                    return dayjs(event[target]).isSame(dayjs(date));
                }) ?? null;
            },

            processClickEvent(event)
            {
                this.$emit("click", event);
            }

        }
    }

</script>