<template>
    <div class="overflow-x-auto ma-6">
        <div v-if="$ability.can('edit', 'training')">
            <div class="text-h4">Training Dashboard</div>
            <v-tabs 
            v-model="tab">
                <v-tab>Mandatory Training</v-tab>
                <v-tab>All Training</v-tab>
            </v-tabs>
            <v-tabs-items
            v-model="tab">
                <v-tab-item>
                    <mandatory-training-grid
                    :mandatoryTrainingTypes="mandatoryTrainingTypes"
                    :users="users"></mandatory-training-grid>
                </v-tab-item>
                <v-tab-item>
                    <v-card class="mb-10">
                        <v-row>
                            <v-col>
                                <v-text-field
                                @change="getTraining"
                                type="date"
                                v-model="period_start"
                                label="From"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-text-field
                                @change="getTraining"
                                type="date"
                                v-model="period_end"
                                label="To"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-select
                                @change="getTraining"
                                :items="users"
                                item-text="name"
                                item-value="id"
                                v-model="user_selected"
                                label="User"></v-select>
                            </v-col>
                        </v-row>
                        <v-card>
                            <v-card-text>
                                <v-data-table
                                :headers="trainingTableHeaders"
                                :items="training">
                                    <template v-slot:item.evidence="{ item }">                                    
                                        <v-btn
                                        v-if="item.evidence"
                                        icon
                                        @click="openEvidence(item)"
                                        color="teal accent-3">
                                            <v-icon>mdi-folder-open</v-icon>
                                        </v-btn>
                                        <v-btn
                                        v-if="item.evidence"
                                        icon
                                        @click="deleteEvidence(item)"
                                        color="pink">
                                            <v-icon>mdi-delete</v-icon>
                                        </v-btn>
                                        <v-btn  
                                        v-else                                  
                                        color="teal accent-3" 
                                        @click="addEvidence(item)"                                   
                                        icon>
                                            <v-icon>mdi-paperclip</v-icon>
                                        </v-btn>
                                    </template>
                                    <template v-slot:item.actions="{ item }">
                                        <v-btn
                                        icon
                                        @click="editTraining(item)"
                                        color="primary">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                        <v-btn
                                        v-if="!item.approved && !item.rejected"
                                        icon
                                        @click="approveTraining(item)"
                                        color="teal lighten-2">
                                            <v-icon>mdi-clipboard-check</v-icon>
                                        </v-btn>
                                        <v-btn
                                        v-if="!item.approved && !item.rejected"
                                        icon
                                        @click="rejectTraining(item)"
                                        color="pink">
                                            <v-icon>mdi-cancel</v-icon>
                                        </v-btn>
                                    </template>                                        
                                </v-data-table>
                            </v-card-text>
                            <v-btn
                            fab
                            absolute
                            bottom
                            right
                            color="primary"
                            @click="addTraining">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-card>                    
                    </v-card>                    
                </v-tab-item>
            </v-tabs-items>
            <training-dialog
            :training="trainingToEdit"
            :users="users"
            :title="trainingDialogTitle"
            :mandatory_types="mandatoryTrainingTypes"
            :showTrainingDialog="showTrainingDialog"
            @contentSaved="saveTraining"
            @close="showTrainingDialog = false"></training-dialog>  
            <v-overlay
              :absolute="false"
              :opacity="0.45"
              :value="busy"
              :z-index="1000">
                <v-progress-circular
                    indeterminate
                    color="primary"
                    size="100"
                    width="12"
                ></v-progress-circular>
            </v-overlay>
            <v-snackbar
            top
            v-model="showAlert"
            :color="alertType">
                {{alertText}}
                <template v-slot:action="{ attrs }">
                    <v-btn
                    @click="showAlert = false"                
                    icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar> 
            <dialog-form
            v-model="showEvidenceDialog"
            title="Evidence"
            @close="showEvidenceDialog = false"
            :closeButton="evidenceDialogButtons.close"
            :saveContentButton="evidenceDialogButtons.save"
            @saveContent="saveEvidence"
            :fields="evidenceDialogFields"></dialog-form>
        </div>
                         
    </div>        
</template>

<script>

    import MandatoryTrainingGrid from '../../components/EpMandatoryTrainingGrid';
    import DataTableCard from '../../components/EpDataTableCard';
    import TrainingDialog from './training_dialog';
    import DialogForm from '../../components/EpDialogForm';

    const blankTraining = {
        id: null,
        users_id: null,
        editors_id: null,
        displayed_as: null,
        started: null,
        completed: null,
        expires: null,
        mandatory_type: null,
        outcome: null,
        trainee_comment: null,
        supervisor_comment: null,
        evidence: null,
        approved: null,
        rejected: null
    };

    export default{
        name: "Training",

        props: {

        },

        data: function(){
            return {
                users: [],
                training: [],
                mandatoryTrainingTypes: [],
                myEos: new EosPortalApi(config.api.root),
                myS3: new EosS3Client(config.cognito.identityPoolId, config.cognito.region, config.cognito.UserPoolId, this.$store.state.session.session.idToken.jwtToken),
                tab: null,
                period_start: dayjs().startOf('year').format('YYYY-MM-DD'),
                period_end: dayjs().endOf('year').format('YYYY-MM-DD'),
                user_selected: null,
                trainingTableHeaders: config.training.trainingTableHeaders,
                showTrainingDialog: false,
                trainingDialogTitle: 'New Training',
                trainingToEdit: JSON.parse(JSON.stringify(blankTraining)),   
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,  
                evidenceDialogFields: {
                    evidence: {
                        type: 'file',
                        label: 'Evidence',
                        accept: '.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,image/png, image/jpeg',
                        model: null
                    },
                    training: {
                        type: '',
                        model: null
                    }
                },
                showEvidenceDialog: false,
                evidenceDialogButtons: config.dialogButtons,
                buckets: null       
            }
        },

        components: {
            DialogForm,
            DataTableCard,
            TrainingDialog,
            MandatoryTrainingGrid
        },

        computed: {
            user: function()
            {
                return this.$store.state.user;
            }
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);  
            this.getMandatoryTrainingTypes();   
            this.getUsers(); 
            this.getTraining(); 

        },

        methods: {
            getMandatoryTrainingTypes(){
                if(!this.busy)  {
                    this.busy = true;
                }  
                this.myEos.MandatoryTrainings().get()
                .then(({data}) => {
                    this.busy = false;
                    this.mandatoryTrainingTypes = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });

            },

            getUsers(){
                if(!this.busy)  {
                    this.busy = true;
                }  
                this.myEos.Users().Relations(['MandatoryTraining']).get()
                .then(({data}) => {
                    this.busy = false;
                    this.users = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            getTraining(){
                if(!this.busy)  {
                    this.busy = true;
                }  
                var filters = {
                    from: this.period_start,
                    to: this.period_end,
                };
                if(this.user_selected)
                    filters['user_id'] = this.user_selected;
                this.myEos.Trainings().Relations(['User'])
                .Filters(filters).get()
                .then(({data}) => {
                    this.busy = false;
                    this.training = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            addTraining(){
                this.trainingToEdit = JSON.parse(JSON.stringify(blankTraining));
                this.showTrainingDialog = true;
            },

            editTraining(training){
                this.trainingToEdit = JSON.parse(JSON.stringify(training))
                this.showTrainingDialog = true;
            },

            approveTraining(training){
                if(training.rejected)
                {
                    this.alertText = "This training has already been rejected";
                    this.alertType = 'error';
                    this.showAlert = true;
                    return;
                }
                training.approved = dayjs().format('YYYY-MM-DD HH:mm:ss');
                this.updateTraining(training);
            },

            rejectTraining(training){
                if(training.approved){
                    this.alertText = "This training has already been approved";
                    this.alertType = 'error';
                    this.showAlert = true;
                    return;
                }
                training.rejected = dayjs().format('YYYY-MM-DD HH:mm:ss');
                this.updateTraining(training);
            },

            saveTraining(training){
                if(training.id === null)
                    this.saveNewTraining(training);
                else
                    this.updateTraining(training);                
            },

            saveNewTraining(training){
                if(!this.busy)  {
                    this.busy = true;
                }  
                this.myEos.Training().Relations(['User'])
                .put(training)
                .then(({data}) => {
                    this.busy = false;
                    this.showTrainingDialog = false;
                    this.training.splice(0,0, data);
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },
            updateTraining(training){
                if(!this.busy)  {
                    this.busy = true;
                }                  
                this.myEos.Training(training.id).Relations(['User'])
                .patch(training)
                .then(({data}) => {
                    this.busy = false;
                    this.showTrainingDialog = false;
                    this.updateTrainingArray(data);
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },
            addEvidence(training){
                this.evidenceDialogFields.training.model = training;
                this.showEvidenceDialog = true;
            },

            openEvidence(training){
                if(!this.busy)  {
                    this.busy = true;
                }                
                this.myS3.GetObject(config.Resources.bucket, training.evidence, config.cognito.UserPoolId)
                .then(data => {    
                    this.busy = false;                   
                    return new Response(data.Body, {headers: {"Content-Type": this.returnContentType(training.evidence)}}).blob();
                })  
                .then(data => {                    
                    var uri = URL.createObjectURL(data);
                    window.open(uri, "_blank");
                })             
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                })
            },

            deleteEvidence(training){
                if(!this.busy)  {
                    this.busy = true;
                }  
                this.myS3.DeleteObject(config.Resources.bucket, training.evidence, config.cognito.UserPoolId)
                .then(data => {                    
                    training.evidence = null;
                    this.updateTraining(training);
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                })
            },

            returnContentType(content)           
            {
                const contentTypes = config.Resources.ContentTypes;
                var fileExtension = content.split('.');
                fileExtension = fileExtension[fileExtension.length-1]; //the last element
                if(contentTypes.hasOwnProperty(fileExtension))
                    return contentTypes[fileExtension];
                return "application/octet-stream";
            },

            createFileName(content)
            {
                return `${dayjs().toISOString()}_${this.user.id}_${content.name}`;
            },

            saveEvidence(fields){ 
                if(!this.busy)  {
                    this.busy = true;
                }               
                this.showEvidenceDialog = false;
                var evidence = fields.evidence.model;
                var training = fields.training.model;
                var filename = this.createFileName(evidence);
                evidence.arrayBuffer()                
                .then(data => {
                    return this.myS3.PutObject(config.Resources.bucket, `${config.Resources.evidence_folder}/${filename}`, data, config.cognito.UserPoolId)
                })
                .then(data => {
                    this.busy = false;
                    const {httpStatusCode} = data.$metadata;
                    if(httpStatusCode === 200){
                        training.evidence = `${config.Resources.evidence_folder}/${filename}`;
                        this.updateTraining(training);
                    }
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                })
            },

            updateTrainingArray(training){
                var index = this.training.findIndex(item => {
                    return item.id === training.id;
                });
                if(index !== -1)
                    this.training.splice(index, 1, training);
            }
        }
    }

</script>