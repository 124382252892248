<template>
    <v-container>
        <v-card v-if="clinic_reconciliation" class="mb-4">
            <v-card-text>
                <v-text-field                        
                label="Attended"
                disabled  
                :append-icon="clinic_reconciliation_unsaved? 'mdi-content-save-alert' : null"    
                v-model="clinic_reconciliation.attended"></v-text-field>
                <text-field-form
                v-model="editReconciliation"
                :fields="formFields"
                @contentSaved="saveReconciliation"
                ></text-field-form>
            </v-card-text>
        </v-card>
        <v-card>
            <v-card-title>Screening Data</v-card-title>
            <v-card-text>
                <v-data-table
                v-if="clinic_reconciliation"
                :headers="ScreeningTableHeaders"
                :items="clinic_reconciliation.screening">
                    <template v-slot:item.actions="{ item }">
                        <v-btn                        
                        @click="editScreening(item)"
                        color="primary"
                        icon>
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                        @click="deleteScreening(item)"
                        color="pink"
                        dark
                        icon>
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-btn
                        icon
                        color="teal lighten-2"
                        v-if="item.referral_queue === null"
                        @click="trackReferral(item)">
                            <v-icon>mdi-link-variant</v-icon>
                        </v-btn>
                        <v-btn
                        icon
                        color="pink"
                        v-if="item.referral_queue"
                        @click="untrackReferral(item)">
                            <v-icon>mdi-link-variant-off</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-btn
            fab
            absolute
            bottom
            right
            @click="addScreening"
            color="primary">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </v-card>        
        <screening-dialog
        :screening="screeningToEdit"
        :showScreeningDialog="showScreeningDialog"
        @close="closeScreeningDialog"></screening-dialog>
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>

    import TextFieldForm from '../../components/EpTextFieldForm';
    import DataTableCard from '../../components/EpDataTableCard';
    import ScreeningDialog from '../screening/main_dialog';

    const blankScreening = {
        id: null,
        clinic_reconciliation_id: null,
        user_id: null,
        editor_id: null,
        nhs_number: null,
        outcome: null,
        comment: null,
        grading: []
    };

    export default{
        name: "Reconciliation",

        props: {
            reconciliation: String
        },

        data: function(){
            return {
                clinic_reconciliation: null,
                myEos: new EosPortalApi(config.api.root),
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,
                editReconciliation: false,
                formFields: config.ClinicReconciliations.FormFields,
                ScreeningTableHeaders: config.ClinicReconciliations.ScreeningTableHeaders,
                showScreeningDialog: false,
                screeningToEdit: blankScreening,
                track_button_data: {
                    callback: 'trackReferral',
                    callback_alternate: 'untrackReferral',
                    color: 'teal lighten-2',
                    color_alternate: 'pink',
                    icon: 'mdi-link-variant',
                    conditional: 'referral'
                },
                clinic_reconciliation_unsaved: false
            }
        },

        components: {
            TextFieldForm,
            DataTableCard,
            ScreeningDialog
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);
            this.getReconciliation();
        },

        computed: {
            user: function(){
                return this.$store.state.user;
            },

            attended: function()
            {
                if(this.clinic_reconciliation)
                    return this.clinic_reconciliation.screening.length;
            }            
        },

        watch: {
            attended: function()
            {
                this.clinic_reconciliation_unsaved = true;
                this.clinic_reconciliation.attended = this.attended;
            }
        },

        methods: {
            getReconciliation()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.ClinicReconciliation(this.reconciliation).Relations(['Clinic.Venue', 'Screening.Grading', 'Screening.ReferralQueue']).get()
                .then(({data}) => {
                    this.busy = false;
                    this.clinic_reconciliation = data;
                    
                    this.formFields.cancelled.model = this.clinic_reconciliation.cancelled;
                    this.formFields.dna.model = this.clinic_reconciliation.dna;
                    this.formFields.couldnotbeseen.model = this.clinic_reconciliation.couldnotbeseen;
                    this.formFields.reconciliation_comment.model = this.clinic_reconciliation.reconciliation_comment;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            saveReconciliation(event)
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.clinic_reconciliation.cancelled = event.cancelled;
                this.clinic_reconciliation.dna = event.dna;
                this.clinic_reconciliation.couldnotbeseen = event.couldnotbeseen;
                this.clinic_reconciliation.reconciliation_comment = event.reconciliation_comment;
                this.myEos.ClinicReconciliation(this.clinic_reconciliation.id).Relations(['Clinic.Venue', 'Screening.Grading', 'Screening.ReferralQueue']).patch(this.clinic_reconciliation)
                .then(({data}) => {
                    this.busy = false;
                    this.clinic_reconciliation_unsaved = false;
                    this.clinic_reconciliation = data;
                })
                .catch(err => {                    
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            addScreening(){
                this.screeningToEdit = JSON.parse(JSON.stringify(blankScreening));
                this.screeningToEdit.clinic_reconciliation_id = this.clinic_reconciliation.id;
                this.screeningToEdit.user_id = this.user.id;
                this.showScreeningDialog = true;
            },

            closeScreeningDialog(screening){
                if(screening.id)
                    this.updateScreeningArray(screening);
                this.showScreeningDialog = false;
            },

            editScreening(screening){
                this.screeningToEdit = JSON.parse(JSON.stringify(screening))
                this.screeningToEdit.editor_id = this.user.id;                
                this.showScreeningDialog = true;
            },

            deleteScreening(screening){
                if(screening.grading.length)
                {
                    this.alertText = "Please delete any gradings associated with this episode before trying to delete it";
                    this.alertType = 'error';
                    this.showAlert = true;
                    return;
                }
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Screening(screening.id).delete()
                .then(({data}) => {
                    this.busy = false;
                    this.removeScreeningFromArray(screening);
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            trackReferral(screening)
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.ReferralQueue().Relations([])
                .put({
                    screening_id: screening.id,
                    user_id: this.user.id,
                    nhs_number: screening.nhs_number
                })
                .then(({data}) => {
                    this.busy = false;
                    screening.referral_queue = data;
                    this.updateScreeningArray(screening);
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            untrackReferral(screening)
            {
                if(screening.referral_queue.acknowledged)
                {
                    this.alertText = "The referral tracking is already completed";
                    this.alertType = 'error';
                    this.showAlert = true;
                    return;
                }
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.ReferralQueue(screening.referral_queue.id).Relations([])
                .delete()
                .then(({data}) => {
                    this.busy = false;
                    screening.referral_queue = null;
                    this.updateScreeningArray(screening);
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            updateScreeningArray(screening)
            {   
                var index = this.findScreeningIndex(screening);
                if(index !== -1)
                    this.clinic_reconciliation.screening.splice(index, 1, screening);
                else
                    this.clinic_reconciliation.screening.splice(0,0, screening);
            },

            removeScreeningFromArray(screening)
            {
                var index = this.findScreeningIndex(screening)
                if(index !== -1)
                    this.clinic_reconciliation.screening.splice(index, 1);                
            },

            findScreeningIndex(screening)
            {
                return this.clinic_reconciliation.screening.findIndex(item => {
                    return item.id === screening.id;
                });
            }
        }
    }

</script>