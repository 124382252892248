<template>
    <v-hover v-slot="{ hover }">
        <v-card
        v-if="hasTrainingType"
        flat
        tile
        class="work_calendar_tile d-flex align-content-stretch"
        :color="color">
            <v-card-text>{{trainingType}}</v-card-text>
        </v-card> 
    </v-hover>
</template>

<script>

    export default{
        name: "TrainingGridTile",

        props: {
            user: Object,
            trainingType: String
        },


        computed: {
            color: function(){
                if(this.trainingAge !== null)
                {
                    if(this.trainingAge > this.amberPeriod)
                        return 'green accent-4';
                    if(this.trainingAge <= this.redPeriod)
                        return 'pink';
                    if(this.trainingAge > this.redPeriod && this.trainingAge <= this.amberPeriod)
                        return 'orange accent-4'
                    return 'white';
                }
            },

            amberPeriod: function()
            {
                return config.training.amberPeriod;
            },

            redPeriod: function()
            {
                return config.training.redPeriod;
            },

            trainingAge: function()
            {
                if(this.hasTrainingType)
                    return dayjs(this.hasTrainingType.expires).diff(dayjs(), 'month');
                return null;
            },

            hasTrainingType: function()
            {
                return this.user.mandatory_training.filter(training => {
                    return training.mandatory_type === this.trainingType;
                }).sort((a, b) => {
                    if(dayjs(a.expires).isSameOrBefore(dayjs(b.expires)))
                        return 1;
                    if(dayjs(a.expires).isAfter(dayjs(b.expires)))
                        return -1;
                })[0];
            }
        }

    }

</script>