var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$ability.can('edit', 'leave'))?_c('v-container',[_c('v-card',[_c('v-btn',{attrs:{"fab":"","absolute":"","bottom":"","right":"","color":"primary"},on:{"click":_vm.newLeaveRequest}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-card-title',[_vm._v("Leave Management")]),_c('v-card-text',[_c('v-toolbar',{staticClass:"pa-8 mb-4",attrs:{"flat":""}},[_c('v-text-field',{attrs:{"type":"date","label":"From"},on:{"change":_vm.getRequests},model:{value:(_vm.leave_year_start),callback:function ($$v) {_vm.leave_year_start=$$v},expression:"leave_year_start"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"type":"date","label":"To"},on:{"change":_vm.getRequests},model:{value:(_vm.leave_year_end),callback:function ($$v) {_vm.leave_year_end=$$v},expression:"leave_year_end"}})],1),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Open Requests")]),_c('v-tab',[_vm._v("Closed Requests")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.open_requests,"headers":_vm.leaveRequestHeaders},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.name)+" ")]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayNiceDate(item.from, 'DD-MM-YYYY'))+" ")]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayNiceDate(item.to, 'DD-MM-YYYY'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.approved)?_c('v-btn',{attrs:{"color":"primary","icon":""},on:{"click":function($event){return _vm.editRequest(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(!item.approved)?_c('v-btn',{attrs:{"color":"teal lighten-1","icon":""},on:{"click":function($event){return _vm.approveRequest(item)}}},[_c('v-icon',[_vm._v("mdi-account-check")])],1):_vm._e(),(!item.approved)?_c('v-btn',{attrs:{"color":"pink","icon":""},on:{"click":function($event){return _vm.declineRequest(item)}}},[_c('v-icon',[_vm._v("mdi-account-cancel")])],1):_vm._e()]}}],null,false,700342810)})],1)],1)],1),_c('v-tab-item',[_c('v-container',[_c('v-data-table',{attrs:{"items":_vm.closed_requests,"headers":_vm.leaveRequestHeaders},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.name)+" ")]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayNiceDate(item.from, 'DD-MM-YYYY'))+" ")]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayNiceDate(item.to, 'DD-MM-YYYY'))+" ")]}}],null,false,896287622)})],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.showEditRequest),callback:function ($$v) {_vm.showEditRequest=$$v},expression:"showEditRequest"}},[_c('v-card',[_c('v-card-title',[_vm._v("Approver Comment")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Comment"},model:{value:(_vm.approver_comment),callback:function ($$v) {_vm.approver_comment=$$v},expression:"approver_comment"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","dark":"","color":"pink"},on:{"click":function($event){_vm.showEditRequest = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"fab":"","color":"primary"},on:{"click":_vm.saveEditRequest}},[_c('v-icon',[_vm._v("mdi-content-save")])],1)],1)],1)],1),(_vm.myuser)?_c('leave-request-form',{attrs:{"fields":_vm.userLeaveRequest,"user":_vm.myuser,"personalRequest":false,"showDialog":_vm.showDialogForm},on:{"saveRequest":_vm.saveLeaveRequest,"close":function($event){_vm.showDialogForm = false}}}):_vm._e(),_c('v-snackbar',{attrs:{"top":"","color":_vm.alertType},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showAlert = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,1476558398),model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }