<template>
    <div class="overflow-x-auto ma-6" v-if="$ability.can('edit', 'clinics')">
        <div class="text-h4 mb-4">Timesheets</div>
        <v-card flat>
            <v-row flat class="pa-8 mb-4">
                <v-col>
                    <v-text-field
                    @change="getTimesheet"
                    type="date"
                    v-model="period_start"
                    label="From"></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                    @change="getTimesheet"
                    type="date"
                    v-model="period_end"
                    label="To"></v-text-field>
                </v-col>
                <v-col>
                    <v-select
                    @change="getTimesheet"
                    :items="users"                
                    item-text="name"
                    item-value="id"
                    v-model="user"
                    label="User"></v-select>
                </v-col>                   
            </v-row> 
            <v-card flat>
                <v-card-text>
                    <v-data-table
                    :items="clinics"
                    :headers="timesheetTableHeaders">
                        <template v-slot:item.hours="{ item }">
                            {{calculateHours(item)}}
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-card>
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>

    export default{
        name: "Timesheets",
        props: {

        },

        data: function(){
            return {
                clinics: [],
                users: [],
                user: null,
                myEos: new EosPortalApi(config.api.root),
                period_start: dayjs().startOf('month').format('YYYY-MM-DD'),
                period_end: dayjs().endOf('month').format('YYYY-MM-DD'),
                timesheetTableHeaders: config.Timesheets.timesheetTableHeaders,
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,
            }
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);
            this.getUsers()
        },

        computed: {
            currentUser: function(){
                return this.$store.state.user;
            }
        },

        methods: {
            getUsers(){
                if(!this.busy)  {
                    this.busy = true;
                }  
                this.myEos.Users().Relations(['MandatoryTraining']).get()
                .then(({data}) => {
                    this.busy = false;
                    this.users = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            getTimesheet()
            {
                if(!this.user)
                {
                    this.alertText = "Please choose a user";
                    this.alertType = 'error';
                    this.showAlert = true;
                    return;
                }
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Clinics().Filters({
                    user: this.user,
                    from: dayjs(this.period_start).format('YYYY-MM-DD'),
                    to: dayjs(this.period_end).format('YYYY-MM-DD')
                }).Relations([]).get()
                .then(({data}) => {
                    this.busy = false;
                    this.clinics = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            calculateHours(clinic)
            {
                return dayjs(`${clinic.clinic_date} ${clinic.finish_time}`).diff(dayjs(`${clinic.clinic_date} ${clinic.start_time}`), 'hours');
            }
        }

    }

</script>