<template>
    <v-card v-if="user && myuser">
        <v-card-title>Profile for {{myuser.name}}</v-card-title>
        <v-card-text>
            <v-tabs
            v-model="tab">
                <v-tab>Personal Details</v-tab>
                <v-tab>Permissions</v-tab>
                <v-tab>Groups</v-tab>
                <v-tab>Leave Allowance</v-tab>
                <v-tab>Security</v-tab>
            </v-tabs>
            <v-tabs-items 
            v-model="tab">
                <v-tab-item>
                    <text-field-form 
                    v-model="editUserPersonalDetails"
                    @contentSaved="updateAwsUserPersonalDetails"
                    :fields="personalDetails"></text-field-form>
                    <text-field-form 
                    v-model="editUserPassword"
                    @contentSaved="updateUserPassword"
                    :fields="passwordDetails"></text-field-form>
                </v-tab-item>
                <v-tab-item>
                   <v-data-table
                    :headers="permissionHeaders"
                    :items="myuser.permission">                        
                    </v-data-table> 
                </v-tab-item>
                <v-tab-item>
                   <v-data-table
                    :headers="groupTableHeaders"
                    :items="myuser.group">                        
                    </v-data-table> 
                </v-tab-item> 
                <v-tab-item>
                    <v-card class="mb-2">
                        <v-card-title>Leave Approvers</v-card-title>
                        <v-card-text>
                            <v-simple-table>
                                <tbody>
                                    <tr v-for="approver in myuser.approver">
                                        <td>{{approver.name}}</td>
                                    </tr>
                                </tbody>
                            </v-simple-table>
                        </v-card-text>
                    </v-card>
                    <v-card>
                        <v-card-title>Leave Allowances</v-card-title>
                        <v-data-table
                        :headers="holidayAllowanceTableHeaders"
                        :items="myuser.holiday_allowance">  
                            <template v-slot:item.allowance="{ item }">
                                {{calculateDaysFromAllowances(item.allowance)}}
                            </template>
                            <template v-slot:item.remaining="{ item }">
                                {{calculateDaysFromAllowances(item.remaining)}}
                            </template>
                            <template v-slot:item.carryover="{ item }">
                                {{calculateDaysFromAllowances(item.carryover)}}
                            </template>                      
                        </v-data-table>
                    </v-card>
                        
                </v-tab-item> 
                <v-tab-item v-if="myAwsUser">
                    <v-card-actions>
                        <v-btn 
                        text 
                        v-if="myAwsUser.PreferredMfaSetting === undefined"
                        @click="enableSmsMfa"                    
                        color="primary">Enable SMS MFA</v-btn>
                        <v-btn 
                        text 
                        v-if="myAwsUser.PreferredMfaSetting === undefined"
                        @click="enableSoftwareMfa"                    
                        color="primary">Enable Software MFA</v-btn>
                        <v-btn 
                        text 
                        v-if="myAwsUser.PreferredMfaSetting === 'SMS_MFA'"
                        @click="disableSmsMfa"  
                        color="pink">Disable SMS MFA</v-btn>
                        <v-btn 
                        text 
                        v-if="myAwsUser.PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA'"
                        @click="disableSoftwareMfa"  
                        color="pink">Disable Software MFA</v-btn>
                        <v-btn                            
                        icon        
                        @click="requestVerification('email', 'email_verification')"                    
                        color="teal lighten-1">
                            <v-icon>mdi-email-check</v-icon>
                        </v-btn>
                        <v-btn                            
                        icon    
                        @click="requestVerification('phone_number', 'phone_number')"                        
                        color="teal lighten-1">
                            <v-icon>mdi-phone-check</v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-data-table
                    :items="myAwsUser.UserAttributes"
                    :headers="userAttributeHeaders">                       
                    </v-data-table>
                    <v-data-table
                    :items="[myAwsUser]"
                    :headers="awsUserTableHeaders">                        
                    </v-data-table> 
                </v-tab-item>                      
            </v-tabs-items>                            
        </v-card-text>        
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-dialog 
        max-width="500"
        :key="qrcodeKey"      
        v-model="showQRCode">
            <v-card>
                <v-card-subtitle class="pa-4">Scan the code below with your authentication app to set up your one time password authentication, then click save to proceed</v-card-subtitle>
                <v-card-text class="d-flex justify-center">
                    <vue-q-r-code-component
                    :text="qrURL"
                    :size="200">                    
                    </vue-q-r-code-component>
                </v-card-text>                    
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    fab
                    dark
                    @click="showQRCode = false"
                    color="pink">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                    fab
                    color="primary"
                    @click="captureCode">
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
        class="accent"
        max-width="350"
        v-model="showCaptureCode">
            <get-verification-code    
            title="Enter Authentication Code"
            message=""
            buttonText="Verify"
            button2Text="Request another code"
            :showButton2="false"
            callback1="verify"
            callback2="resendCode"
            @verify="processVerificationCode"
            ></get-verification-code>            
        </v-dialog> 
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>       
    </v-card>
</template>

<script>

    import VueQRCodeComponent from 'vue-qrcode-component'
    import GetVerificationCode from '../../components/EpGetVerificationCode.vue'
    import TextFieldForm from '../../components/EpTextFieldForm'

    export default{
        name: "ProfileHome", 

        props: {
            user: String
        },      

        data: function(){
            return {
                tab: null,
                myuser: null,                
                myAws: new EosAwsCognito(EosPoolData),
                myEos: new EosPortalApi(config.api.root),
                myAwsUser: null,                
                permissionHeaders: [{
                    text: 'Permission',
                    value: 'displayed_as'
                }],
                groupTableHeaders: [
                     {
                        text: "Group Name",
                        value: "displayed_as"
                    }
                ],
                holidayAllowanceTableHeaders: config.users.holidayAllowanceTableHeaders,
                userAttributeHeaders: config.users.awsUserAttributesHeaders,
                awsUserTableHeaders: config.users.awsUserTableHeaders,
                myAwsAdmin: new EosAwsCognitoAdmin(config.cognito.identityPoolId, config.cognito.region, config.cognito.UserPoolId, this.$store.state.session.session.idToken.jwtToken),
                showAlert: false,
                alertType: 'success',
                alertText: null,
                showQRCode: false,
                qrcodeKey: 0,
                qrURL: null,
                showCaptureCode: false,
                showCaptureEmailCode: false, 
                editUserPersonalDetails: false,  
                editUserPassword: false,                
                personalDetails: {
                    name: {
                        label: "Name",
                        model: null,
                        textfield: true
                    },
                    email: {
                        label: "Email",
                        model: null,
                        textfield: true
                    },
                    mobile: {
                        label: "Mobile",
                        model: null,
                        textfield: true
                    },
                },

                passwordDetails: {
                    current_password: {
                        label: "Current Password",
                        model: null,
                        password: true,
                        showPass: false
                    },
                    new_password: {
                        label: "New Password",
                        model: null,
                        password: true,
                        showPass: false
                    },
                    confirm_password: {
                        label: "Confirm Password",
                        model: null,
                        password: true,
                        showPass: false
                    },
                },
                updatedFields: null,
                verificationCodeType: null,
                busy: true        
            }
        }, 

        components: {
            TextFieldForm,
            VueQRCodeComponent,
            GetVerificationCode
        },

        mounted(){
            this.getUser();
        },      

        methods: {
            getUser()
            { 
                if(!this.busy)  {
                    this.busy = true;
                }               
                this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken)
                .User(this.user)
                .Relations(['HolidayAllowance', 'Permission', 'Approver', 'Group'])
                .get()
                .then(({data}) => {
                    this.busy = false;
                    this.myuser = data;
                    this.personalDetails.name.model = this.myuser.name;
                    this.personalDetails.email.model = this.myuser.email;
                    this.userUpdatedEmail = data.email;
                    return this.myAws.getCurrentSession();
                })                
                .then(result => {
                    this.busy = false;
                    this.myAwsUser = result;
                    return this.myAwsAdmin.getUser(result.session.accessToken.jwtToken, config.cognito.UserPoolId);
                })
                .then(result => {
                    this.busy = false;
                    this.myAwsUser = result; 
                    this.mobileNumber();                   
                })                                
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            mobileNumber(){
                if(this.myAwsUser && this.myAwsUser.UserAttributes)
                {
                    var phone_number = this.myAwsUser.UserAttributes.filter(attribute => {
                        return attribute.Name === 'phone_number'
                    });
                    if(phone_number.length){
                        return this.personalDetails.mobile.model = phone_number[0].Value;                        
                    }
                }
                return null;
            },

            updateAwsUserPersonalDetails(fields)
            {                 
                if(!this.busy)  {
                    this.busy = true;
                }                   
                this.updatedFields = fields;
                var newEmail = this.myuser.email !== fields.email;
                //save the updated details to AWS server first
                this.myAws.updateUserAttributes(this.createUserAttributeList(fields))
                .then(result => { 
                    this.busy = false;
                    this.editUserPersonalDetails = false;
                    if(newEmail) {
                        this.verificationCodeType = "email"
                        this.showCaptureCode = true;
                    }                                          
                    else
                        this.updateEosServerWithPersonalDetails(fields);
                })                
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            createUserAttributeList(fields)
            {
                var list = [];
                list.push({
                    Name: 'email',
                    Value: fields.email
                });
                list.push({
                    Name: 'name',
                    Value: fields.name
                });
                list.push({
                    Name: 'phone_number',
                    Value: fields.mobile
                });               
                var user = new User(this.myuser.aws_sub, '', list);
                return user.attributeList;
            },

            updateEosServerWithPersonalDetails(fields)
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.User(this.myuser.id)
                .patch({
                    email: fields.email,
                    name: fields.name,
                    aws_sub: this.myuser.aws_sub
                })
                .then(({data}) => {
                    this.busy = false;
                    this.myuser = data;
                    this.userUpdatedEmail = data.email;
                    this.alertText = 'User details updated sucessfully';
                    this.alertType = 'success';
                    this.showAlert = true;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            captureCode()
            {
                this.verificationCodeType = "totp";
                this.showQRCode = false;
                this.showCaptureCode = true;
            },

            processVerificationCode(code)
            {
                if(this.verificationCodeType === 'email')
                    this.verifyEmailCode(code);
                if(this.verificationCodeType === 'email_verification')
                    this.verifyEmailCode(code, false);
                if(this.verificationCodeType === 'totp')
                    this.verifyCode(code);
                if(this.verificationCodeType === 'phone_number')
                    this.verifyPhoneNumberCode(code);
            },

            verifyCode(code)
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.showCaptureCode = false;
                this.myAws.verifyNewTotpCode(code, `${this.myuser.name}_totp_device`)
                .then(data => {
                    this.busy = false;
                    this.showCaptureCode = false;
                    return this.myAws.setMfaPreferences('Software')
                })
                .then(result => {
                    this.busy = false;
                    this.alertText = 'Software MFA has been set up sucessfully';
                    this.alertType = 'success';
                    this.showAlert = true;
                    this.myAwsUser.PreferredMfaSetting = 'SOFTWARE_TOKEN_MFA';
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            verifyAttribute(attribute, code, updateServer = true)
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myAws.verifyUserAttribute(attribute, code)
                .then(data => {  
                    this.busy = false;        
                    this.showCaptureCode = false;          
                    this.alertText = `Your new ${attribute} has been verified sucessfully`;
                    this.alertType = 'success';
                    this.showAlert = true;
                    if(updateServer)
                        this.updateEosServerWithPersonalDetails(this.updatedFields);
                })                
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            verifyEmailCode(code, updateServer = true)
            {                
                this.verifyAttribute('email', code, updateServer)                                
            },

            verifyPhoneNumberCode(code)
            {                
                this.verifyAttribute('phone_number', code, false);
            },

            updateUserPassword(fields)
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.editUserPassword = false;
                if(fields.new_password !== fields.confirm_password)
                {
                    this.busy = false;
                    this.alertText = 'Password confirmation is not a match';
                    this.alertType = 'error';
                    this.showAlert = true;
                    return;
                }
                this.myAws.changeUserPassword(this.myuser.aws_sub, fields.current_password, fields.new_password)
                .then(result => {
                    this.busy = false;
                    this.alertText = 'Your password has been updated sucessfully';
                    this.alertType = 'success';
                    this.showAlert = true;                    
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            enableSmsMfa()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myAws.setMfaPreferences('Sms')
                .then(result => {
                    this.busy = false;
                    this.alertText = 'SMS MFA has been set up, you will receive a SMS verification code at your next login';
                    this.alertType = 'success';
                    this.showAlert = true;
                    this.myAwsUser.PreferredMfaSetting = 'SMS_MFA';
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            enableSoftwareMfa()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myAws.requestSoftwareMfaSetup()
                .then(secretcode => {
                    this.busy = false;
                    this.qrURL = "otpauth://totp/"+ this.myuser.email+"?secret=" + secretcode.code + "&issuer=eosclinic.co.uk";
                    this.showQRCode = true;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            disableSoftwareMfa()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myAws.setMfaPreferences('Software', false)
                .then(result => {
                    this.busy = false;
                    this.alertText = 'Software MFA has been disabled';
                    this.alertType = 'success';
                    this.showAlert = true;
                    this.myAwsUser.PreferredMfaSetting = undefined;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            disableSmsMfa()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myAws.setMfaPreferences('Sms', false)
                .then(result => {
                    this.busy = false;
                    this.alertText = 'SMS MFA has been disabled';
                    this.alertType = 'success';
                    this.showAlert = true;
                    this.myAwsUser.PreferredMfaSetting = undefined;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },            

            calculateDaysFromAllowances(allowance)
            {
                if(allowance)
                    return Big(allowance).div(450).toFixed(2);
                return 0;
            },

            requestVerification(item, verificationCodeType)
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myAws.requestUserAttributeVerificationCode(item)
                .then(result => {
                    this.busy = false;
                    this.verificationCodeType = verificationCodeType;
                    this.showCaptureCode = true;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            }

            
        }
    }

</script>