var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$ability.can('view', 'reconciliations'))?_c('div',{staticClass:"overflow-x-auto ma-6"},[_c('div',{staticClass:"text-h4 mb-4"},[_vm._v("Clinic Attendance")]),_c('v-card',{attrs:{"flat":""}},[_c('v-row',{staticClass:"pa-8 mb-4",attrs:{"flat":""}},[_c('v-col',[_c('v-text-field',{attrs:{"type":"date","label":"From"},on:{"change":_vm.getClinics},model:{value:(_vm.period_start),callback:function ($$v) {_vm.period_start=$$v},expression:"period_start"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"date","label":"To"},on:{"change":_vm.getClinics},model:{value:(_vm.period_end),callback:function ($$v) {_vm.period_end=$$v},expression:"period_end"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.groups,"item-text":"displayed_as","item-value":"id","label":"Group"},on:{"change":_vm.getClinics},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}})],1)],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.clinics,"headers":_vm.attendanceTableHeaders},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var item = ref.item;
return _vm._l((item.user),function(user){return _c('div',[_vm._v(" "+_vm._s(user.name)+" ")])})}},{key:"item.attended",fn:function(ref){
var item = ref.item;
return _vm._l((item.clinic_reconciliation),function(reconciliation){return _c('div',[_vm._v(" "+_vm._s(reconciliation.attended)+" ")])})}},{key:"item.cancelled",fn:function(ref){
var item = ref.item;
return _vm._l((item.clinic_reconciliation),function(reconciliation){return _c('div',[_vm._v(" "+_vm._s(reconciliation.cancelled)+" ")])})}},{key:"item.couldnotbeseen",fn:function(ref){
var item = ref.item;
return _vm._l((item.clinic_reconciliation),function(reconciliation){return _c('div',[_vm._v(" "+_vm._s(reconciliation.couldnotbeseen)+" ")])})}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","to":'/view_reconciliation/'+item.id,"color":"primary"}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}],null,false,2182600700)})],1)],1)],1),_c('v-snackbar',{attrs:{"top":"","color":_vm.alertType},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showAlert = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,1476558398),model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")]),_c('v-overlay',{attrs:{"absolute":false,"opacity":0.45,"value":_vm.busy,"z-index":1000}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"100","width":"12"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }