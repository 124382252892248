<template>
    <v-container v-if="$ability.can('access', 'settings')">
        <v-tabs
        v-model="tabs">
            <v-tab>Details</v-tab>
            <v-tab>Address</v-tab>
            <v-tab>Contacts</v-tab>
        </v-tabs>
        <v-tabs-items
        v-model="tabs">
            <v-tab-item>
                <v-card v-if="myvenue">
                    <v-card-text>
                        <v-row>
                            <v-col cols=9>
                                <v-select
                                label="Group"
                                :disabled="!editingVenueDetails"
                                :items="groups"
                                item-text="displayed_as"
                                item-value="id"
                                v-model="myvenue.group_id"></v-select>
                                <v-text-field
                                :disabled="!editingVenueDetails"
                                v-model="myvenue.displayed_as"
                                label="Name"></v-text-field>
                                <v-text-field
                                :disabled="!editingVenueDetails"
                                v-model="myvenue.shortcode"
                                label="Shortcode"></v-text-field>
                                <v-color-picker
                                label="Colour"
                                :disabled="!editingVenueDetails"
                                v-model="myvenue.colour"
                                dot-size="25"
                                swatches-max-height="200"
                                ></v-color-picker>
                                <v-text-field
                                :disabled="!editingVenueDetails"
                                v-model="myvenue.comment"
                                label="Comment"></v-text-field>
                            </v-col>
                            <v-col>
                                <v-btn
                                fab
                                @click="editingVenueDetails = true"
                                v-if="!editingVenueDetails"
                                color="primary"
                                icon>
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>                            
                                <v-btn
                                fab
                                icon
                                dark   
                                @click="editingVenueDetails = false"
                                v-if="editingVenueDetails"                         
                                color="pink">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-btn
                                fab
                                icon 
                                @click="saveVenueDetails"   
                                v-if="editingVenueDetails"                        
                                color="primary">
                                    <v-icon>mdi-content-save</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card class="mb-8">
                    <v-card-text>
                        <text-field-form
                        v-if="myvenue && myvenue.address"
                        @contentSaved="saveVenueAddress"
                        v-model="addressEditor"
                        :fields="addressFields"
                        ></text-field-form>
                        <dialog-form v-else
                        persistent
                        v-model="showVenueAddressDialog"
                        title="New Address"
                        :closeButton="venueDialogButtons.close"
                        :saveContentButton="venueDialogButtons.save"
                        @saveContent="saveNewAddress"
                        @close="showVenueAddressDialog = false"
                        :fields="addressFields"></dialog-form>
                    </v-card-text>
                    <v-btn
                    v-if="myvenue && !myvenue.address"
                    fab
                    absolute
                    bottom
                    right
                    @click="showVenueAddressDialog = true"
                    color="primary">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card class="mb-8">
                    <v-card-text>
                        <text-field-form
                        v-if="myvenue && myvenue.contact.length"
                        @contentSaved="saveVenueContacts"
                        v-model="contactEditor"
                        :fields="contactFields"
                        ></text-field-form>
                        <dialog-form v-else
                        persistent
                        v-model="showVenueContactDialog"
                        title="New Contact"
                        @saveContent="saveNewContact"
                        :closeButton="venueDialogButtons.close"
                        :saveContentButton="venueDialogButtons.save"
                        @close="showVenueContactDialog = false"
                        :fields="contactFields"></dialog-form>
                    </v-card-text>
                    <v-btn
                    v-if="myvenue && !myvenue.contact.length"
                    fab
                    absolute
                    bottom
                    right
                    @click="showVenueContactDialog = true"
                    color="primary">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>            
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
    </v-container>
</template>

<script>

    import TextFieldForm from '../../components/EpTextFieldForm';
    import DialogForm from '../../components/EpDialogForm'

    export default{
        name: "VenueDetails",

        props: {
            venue: String
        },

        components: {
            DialogForm,
            TextFieldForm
        },

        data: function(){
            return {
                myvenue: null,
                groups: [],
                tabs: null,
                myEos: new EosPortalApi(config.api.root),
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,
                editingVenueDetails: false,
                addressFields: config.venues.addressFields,
                addressEditor: false,
                contactFields: config.venues.contactFields,
                contactEditor: false,
                showVenueAddressDialog: false,
                showVenueContactDialog: false,
                venueDialogButtons: config.dialogButtons,               
            }
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);
            this.getGroups();
            this.getVenue();
        },

        methods: {

            getGroups(){
                if(!this.busy)  {
                    this.busy = true;
                }                 
                this.myEos.Groups().get()
                .then(({data}) => {
                    this.busy = false;
                    this.groups = data;
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            getVenue()
            {
                if(!this.busy)  {
                    this.busy = true;
                }                 
                this.myEos.Venue(this.venue).Relations(['Group', 'Address', 'Contact']).get()
                .then(({data}) => {
                    this.busy = false;
                    this.myvenue = data;
                    this.mapAddressFields(data.address);
                    this.mapContactFields(data.contact);
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            saveVenueDetails()
            {
                if(!this.busy)  {
                    this.busy = true;
                }                 
                this.myEos.Venue(this.myvenue.id).Relations(['Group', 'Address', 'Contact'])
                .patch(this.myvenue)
                .then(({data}) => {
                    this.busy = false;
                    this.editingVenueDetails = false;
                    this.myvenue = data;
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            saveVenueAddress(address)
            {
                if(!this.busy)  {
                    this.busy = true;
                }                 
                this.myEos.VenueAddress(address.id).Relations([])
                .patch(address)
                .then(({data}) => {
                    this.busy = false;      
                    this.addressEditor = false;              
                    this.myvenue.address = data;
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            saveNewAddress(address)
            {
                address = this.mapDialogReturnedFields(address);
                address.venue_id = this.myvenue.id;
                this.myEos.VenueAddress().Relations([])
                .put(address)
                .then(({data}) => {
                    this.showVenueAddressDialog = false;
                    this.busy = false;                          
                    this.myvenue.address = data;
                    this.mapAddressFields(data);
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            saveVenueContacts(contact)
            {
                if(!this.busy)  {
                    this.busy = true;
                }                 
                this.myEos.VenueContact(contact.id).Relations([])
                .patch(contact)
                .then(({data}) => {
                    this.busy = false;      
                    this.contactEditor = false;              
                    this.myvenue.contact.splice(0,1, data);
                    this.mapContactFields(this.myvenue.contact);
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            saveNewContact(contact)
            {
                contact = this.mapDialogReturnedFields(contact);
                contact.venue_id = this.myvenue.id;
                this.myEos.VenueContact().Relations([])
                .put(contact)
                .then(({data}) => {
                    this.showVenueContactDialog = false;
                    this.busy = false;                          
                    this.myvenue.contact.splice(0,0, data);
                    this.mapContactFields(this.myvenue.contact);
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            mapDialogReturnedFields(returnedFields)
            {
                var fields = {};
                Object.entries(returnedFields).forEach(([key, value]) => {
                    fields[value.value] = value.model;
                });
                return fields;
            },

            mapAddressFields(venue)
            {
                if(venue){
                    Object.entries(venue).forEach(([key, value]) => {
                        if(this.addressFields.hasOwnProperty(key))
                        {
                            this.addressFields[key].model = value;
                        }
                    });
                }
                else
                {
                    Object.entries(this.addressFields).forEach(([key, value]) => {
                        this.addressFields[key].model = null;
                    });
                }
            },

            mapContactFields(venue)
            {
                if(venue.length){
                    venue.forEach(ven => {
                        Object.entries(ven).forEach(([key, value]) => {
                            if(this.contactFields.hasOwnProperty(key))
                            {
                                this.contactFields[key].model = value;
                            }
                        });
                    })
                }
                else {
                    Object.entries(this.contactFields).forEach(([key, value]) => {
                        this.contactFields[key].model = null;
                    });
                }                        
            }
        }

    }

</script>