<template>
    <div>
        <v-treeview
        v-if="$ability.can('view', 'resources')"
        item-text="name"
        :items="documents"> 
            <template v-slot:label="{ item }">
                <template v-if="!item.children">
                    <v-btn @click="getDocument(item)" text color="primary">{{item.name}}</v-btn>
                </template>
                <template v-else>
                    {{item.name}}
                </template>
            </template>           
        </v-treeview>
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
    </div>
        
</template>

<script>

    import {EosNode, EosNodes} from 'eos_aws_s3';

    export default{
        name: "ResourcesHome",

        props: {

        },

        data: function(){
            return {
                documentKeys: [],
                documents: [],
                myS3: new EosS3Client(config.cognito.identityPoolId, config.cognito.region, config.cognito.UserPoolId, this.$store.state.session.session.idToken.jwtToken),
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,
                nodes: new EosNodes
            }
        },

        mounted(){           
            this.getDocuments();
        },

        computed: {

        },

        methods: {
            getDocuments()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myS3.ListObjects(config.Resources.bucket, config.cognito.UserPoolId)
                .then(data => {
                    this.busy = false;
                    this.documentKeys = data.Contents;
                    this.documentKeys.forEach(path => {                 
                        var dirs = path.Key.split('/');
                        dirs.forEach((dir, i, a) => {
                            if(i === 0){
                                if(dir !== "training")
                                    this.nodes.createNode(dir);
                            }
                            else if(i > 0){
                                this.nodes.createNode(dir, a[i-1]);
                            }
                        });                                       
                    });
                    this.documents = this.nodes.getPrimaryNodes();
                    this.documents.forEach(primary => {                        
                        primary.children = this.nodes.createDocumentTreeNode(primary.name);
                    });
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            getDocument(filename)
            {
                var path = `${filename.parent}/${filename.name}`;
                var key = this.getDocumentKey(path);
                if(!this.busy)  {
                    this.busy = true;
                }                
                this.myS3.GetObject(config.Resources.bucket, key, config.cognito.UserPoolId)
                .then(data => {    
                    this.busy = false;                   
                    return new Response(data.Body, {headers: {"Content-Type": this.returnContentType(key)}}).blob();
                })  
                .then(data => {                    
                    var uri = URL.createObjectURL(data);
                    window.open(uri, "_blank");
                })             
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                })
            },

            getDocumentKey(path)
            {
                var key = this.documentKeys.find(key => {
                    return key.Key.substr(-(path.length), path.length) === path;
                });
                if(key !== undefined)
                    return key.Key;
                return key;
            },

            returnContentType(content)           
            {
                const contentTypes = config.Resources.ContentTypes;
                var fileExtension = content.split('.');
                fileExtension = fileExtension[fileExtension.length-1]; //the last element
                if(contentTypes.hasOwnProperty(fileExtension))
                    return contentTypes[fileExtension];
                return "application/octet-stream";
            },
        }

    }

</script>