<template>
    <v-card v-if="myuser && $ability.can('edit', 'other users')">
        <v-card-title>{{myuser.name}}</v-card-title>
        <v-tabs
        v-model="tab">     
            <v-tab>Groups</v-tab>       
            <v-tab>Permissions</v-tab>
            <v-tab>Leave</v-tab>
            <v-tab>Security</v-tab>
        </v-tabs>
        <v-tabs-items 
        v-model="tab">
            <v-tab-item>
                <v-card>
                    <v-data-table
                    show-select
                    v-model="selectedGroups"
                    :headers="groupTableHeaders"
                    :items="groups"></v-data-table>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                        v-if="selectedGroups.length"
                        dark
                        color="primary" 
                        @click="updateGroups">Update</v-btn>
                    </v-card-actions> 
                </v-card>
            </v-tab-item>
            <v-tab-item> 
                <v-card>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                        v-if="myuser.deleted_at === null"
                        text 
                        color="pink" 
                        @click="deactivateUser">Deactivate User</v-btn>
                        <v-btn 
                        v-if="myuser.deleted_at !== null"
                        text 
                        color="primary" 
                        @click="activateUser">Activate User</v-btn>
                    </v-card-actions>
                    <v-card-text>
                        <v-data-table
                        v-model="selected"
                        show-select
                        :items="permissions"
                        :headers="permissionTableHeaders">                                      
                        </v-data-table>
                    </v-card-text>   
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn 
                        v-if="selected.length"
                        dark
                        color="primary" 
                        @click="updatePermissions">Update</v-btn>
                    </v-card-actions> 
                </v-card>               
                                    
            </v-tab-item>
            <v-tab-item>
                <v-card class="mb-12">
                    <v-card-text>
                        <v-card class="mb-4"> 
                        <v-card-title>Leave Approvers</v-card-title>                       
                            <v-card-text>
                                <v-data-table
                                show-select
                                v-model="leave_approvers"
                                :headers="approverTableHeaders"
                                :items="users">                                
                                </v-data-table>
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                                <v-btn 
                                v-if="leave_approvers.length"
                                dark
                                color="primary" 
                                @click="updateApprovers">Update</v-btn>
                            </v-card-actions>
                        </v-card>  
                        <v-card>
                            <v-card-title>Leave Allowances</v-card-title>
                            <v-card-text>
                                <v-data-table
                                :items="myuser.holiday_allowance"
                                :headers="holidayAllowanceTableHeaders">
                                    <template v-slot:item.allowance="{ item }">
                                        {{calculateDaysFromAllowances(item.allowance)}}
                                    </template>
                                    <template v-slot:item.remaining="{ item }">
                                        {{calculateDaysFromAllowances(item.remaining)}}
                                    </template>
                                    <template v-slot:item.carryover="{ item }">
                                        {{calculateDaysFromAllowances(item.carryover)}}
                                    </template>
                                    <template v-slot:item.actions="{ item }">
                                        <v-btn 
                                        @click="editAllowance(item)"
                                        icon>
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>                                
                                    </template>
                                </v-data-table>                    
                            </v-card-text>
                            <v-btn
                            fab
                            absolute
                            bottom
                            right
                            color="primary"
                            @click="addAllowance">
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                        </v-card>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item v-if="myAwsUser">
                <v-card>
                    <v-card-actions>
                        <v-btn 
                        text 
                        v-if="myAwsUser.PreferredMfaSetting === undefined"
                        @click="enableSmsMfa"                    
                        color="primary">Enable SMS MFA</v-btn>
                        <v-btn 
                        text 
                        v-if="myAwsUser.PreferredMfaSetting === 'SMS_MFA'"
                        @click="disableSmsMfa"  
                        color="pink">Disable SMS MFA</v-btn>
                        <v-btn 
                        text 
                        v-if="myAwsUser.PreferredMfaSetting === 'SOFTWARE_TOKEN_MFA'"
                        @click="disableSoftwareMfa"  
                        color="pink">Disable Software MFA</v-btn>
                        <v-btn 
                        text                         
                        @click="resetUserPassword"  
                        color="pink">Reset User Password</v-btn>
                        <v-btn 
                        text                         
                        @click="resendUserInvitation"  
                        color="teal lighten-1">Resend User Invitation</v-btn>                        
                    </v-card-actions>
                    <v-card-text >
                        <v-data-table
                        :items="myAwsUser.UserAttributes"
                        :headers="userAttributeHeaders">                        
                        </v-data-table>
                        <v-data-table
                        :items="[myAwsUser]"
                        :headers="awsUserTableHeaders">                        
                        </v-data-table>
                    </v-card-text>
                </v-card>                    
            </v-tab-item>
        </v-tabs-items>
        
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-dialog
        persistent
        max-width="400"
        v-model="showHolidayAllowanceDialog">
            <v-card>
                <v-card-title>Allowance</v-card-title>
                <v-card-text>
                    <v-text-field
                    type="number"
                    v-model="holidayAllowance.allowance"
                    label="Allowance"></v-text-field>
                    <v-text-field
                    type="number"
                    v-model="holidayAllowance.remaining"
                    label="Remaining"></v-text-field>
                    <v-text-field
                    type="number"
                    v-model="holidayAllowance.carryover"
                    label="Carry Over"></v-text-field>
                    <v-text-field
                    type="date"
                    v-model="holidayAllowance.carryover_expires"
                    label="Carry Over Expires"></v-text-field>
                    <v-text-field
                    type="date"
                    v-model="holidayAllowance.startofyear"
                    label="Year begins"></v-text-field>
                    <v-text-field
                    type="date"
                    v-model="holidayAllowance.endofyear"
                    label="Year ends"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                    dark 
                    color="pink"
                    @click="showHolidayAllowanceDialog = false"
                    fab>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                    dark 
                    color="primary"
                    v-on="{click: (holidayAllowance.id)? updateAllowance: saveNewAllowance}"
                    fab>
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script>
    const blankAllowance = {
        allowance: null,
        remaining: null,
        carryover: null,
        carryover_expires: null,
        startofyear: null,
        endofyear: null,                    
    };
    export default{
        name: "User",
        props: {
            user: String
        },

        data: function(){
            return {
                tab: null,
                users: [],
                myuser: null,
                groupTableHeaders: config.groups.groupTableHeaders,
                selectedGroups: [],
                permissionTableHeaders: config.users.permissionTableHeaders,
                selected: [],
                leave_approvers: [],
                myEos: new EosPortalApi(config.api.root),
                permissions: [],
                showAlert: false,
                alertType: 'success',
                alertText: null,
                holidayAllowanceTableHeaders: config.users.holidayAllowanceTableHeaders,
                showHolidayAllowanceDialog: false,
                holidayAllowance: blankAllowance,                
                myAwsAdmin: new EosAwsCognitoAdmin(config.cognito.identityPoolId, config.cognito.region, config.cognito.UserPoolId, this.$store.state.session.session.idToken.jwtToken),
                myAwsUser: null,
                userAttributeHeaders: config.users.awsUserAttributesHeaders,
                awsUserTableHeaders: config.users.awsUserTableHeaders,
                approverTableHeaders: [
                    {
                        text: "Leave Approver",
                        value: "name"
                    }
                ],
                busy: false      
            }
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);
            this.getPermissions();
            this.getGroups();
            this.getUser();  
            this.getUsers();          
        },

        methods: {
            getUser()
            {
                if(!this.busy)  {
                    this.busy = true;
                }                
                this.myEos.User(this.user)
                .Relations(['HolidayAllowance', 'Permission', 'Approver', 'Group'])
                .get()
                .then(({data}) => {
                    this.busy = false;
                    this.myuser = data;
                    this.selected = this.myuser.permission;
                    this.selectedGroups = this.myuser.group;
                    this.leave_approvers = this.myuser.approver;
                    return this.myAwsAdmin.adminGetUser(this.myuser.aws_sub, config.cognito.UserPoolId);
                })
                .then(result => {
                    this.busy = false;
                    this.myAwsUser = result; 
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            getUsers(){ 
                if(!this.busy)  {
                    this.busy = true;
                }              
                this.myEos.Users().get()                
                .then(({data}) => {
                    this.busy = false;
                    this.users = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                })
            },

            getGroups(){
                if(!this.busy)  {
                    this.busy = true;
                }              
                this.myEos.Groups().get()                
                .then(({data}) => {
                    this.busy = false;
                    this.groups = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                })
            },

            getPermissions()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.Permissions().get()
                .then(({data}) => {
                    this.busy = false;
                    this.permissions = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            updatePermissions()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                var permissionIdList = this.selected.map(permission => {
                    return permission.id;
                });
                this.myEos.Permission(this.myuser.id)
                .Relations(['HolidayAllowance', 'Permission', 'Approver'])
                .patch({
                    permissions: permissionIdList
                })
                .then(({data}) => {
                    this.busy = false;
                    this.myuser = data;
                    this.alertText = 'Permissions updated';
                    this.alertType = 'success';
                    this.showAlert = true;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            updateGroups()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                var groupIdList = this.selectedGroups.map(group => {
                    return group.id;
                });
                this.myEos.UserGroup(this.myuser.id)                
                .patch({
                    group: groupIdList
                })
                .then(({data}) => {
                    this.busy = false;
                    this.myuser = data;
                    this.alertText = 'Groups updated';
                    this.alertType = 'success';
                    this.showAlert = true;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            updateApprovers()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                var approverIdList = this.leave_approvers.map(approver => {
                    return approver.id;
                });
                this.myEos.Approver(this.myuser.id)
                .Relations(['HolidayAllowance', 'Permission', 'Approver'])
                .patch({
                    approvers: approverIdList
                })
                .then(({data}) => {
                    this.busy = false;
                    this.myuser = data;
                    this.alertText = 'Approvers updated';
                    this.alertType = 'success';
                    this.showAlert = true;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            calculateDaysFromAllowances(allowance)
            {
                if(allowance)
                    return Big(allowance).div(450).toFixed(2);
                return 0;
            },

            calculateAllowancesFromDays(allowance)
            {
                if(allowance)
                    return Big(allowance).times(450).toFixed(0);
                return 0;
            },

            editAllowance(allowance)
            {
                this.holidayAllowance = this.convertAllowancesToDays(allowance);
                this.showHolidayAllowanceDialog = true;
            },

            addAllowance()
            {
                this.holidayAllowance = blankAllowance;
                this.holidayAllowance['user_id'] = this.myuser.id;
                this.showHolidayAllowanceDialog = true;
            },

            saveNewAllowance()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.holidayAllowance = this.convertDaysToAllowances(this.holidayAllowance);
                this.showHolidayAllowanceDialog = false;
                this.myEos.Refresh();
                this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken)
                .HolidayAllowance()
                .Relations(['HolidayAllowance', 'Permission', 'Approver'])
                .put(this.holidayAllowance)                
                .then(({data}) => {
                    this.busy = false;
                    this.alertText = 'Allowance saved';
                    this.alertType = 'success';
                    this.showAlert = true;
                    this.myuser.holiday_allowance.splice(0,0, data);
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            updateAllowance()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.holidayAllowance = this.convertDaysToAllowances(this.holidayAllowance);
                this.showHolidayAllowanceDialog = false;
                this.myEos.Refresh();
                this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken)
                .HolidayAllowance(this.holidayAllowance.id)
                .patch(this.holidayAllowance)
                .then(({data}) => {
                    this.busy = false;
                    this.alertText = 'Allowance updated';
                    this.alertType = 'success';
                    this.showAlert = true;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            convertAllowancesToDays(allowance)
            {
                allowance.allowance = this.calculateDaysFromAllowances(allowance.allowance);
                allowance.remaining = this.calculateDaysFromAllowances(allowance.remaining);
                allowance.carryover = this.calculateDaysFromAllowances(allowance.carryover);
                return allowance;
            },

            convertDaysToAllowances(allowance)
            {
                allowance.allowance = this.calculateAllowancesFromDays(allowance.allowance);
                allowance.remaining = this.calculateAllowancesFromDays(allowance.remaining);
                allowance.carryover = this.calculateAllowancesFromDays(allowance.carryover);
                return allowance;
            },

            deactivateUser()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myAwsAdmin.adminDisableUser(this.myuser.aws_sub, config.cognito.UserPoolId)
                .then(result => {
                    if(result.$metadata.httpStatusCode === 200)
                    {
                        this.busy = false;
                        this.alertText = 'User deactivated';
                        this.alertType = 'success';
                        this.showAlert = true;
                        return this.myEos.User(this.myuser.id).delete();                        
                    }
                })
                .then(({data}) => {
                    if(data === 'User deleted')
                    {
                        this.busy = false;
                        this.alertText = 'The user will no longer be able to login, but you can view this user in the users list by selecting to show deactivated users';
                        this.alertType = 'success';
                        this.showAlert = true;
                    }
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            enableSmsMfa()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myAwsAdmin.adminEnableSmsMfa(this.myAwsUser.Username, config.cognito.UserPoolId)
                .then(result => {
                    this.busy = false;
                    this.alertText = 'SMS Mfa enabled for user';
                    this.alertType = 'success';
                    this.showAlert = true;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            disableSmsMfa()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myAwsAdmin.adminDisableSmsMfa(this.myAwsUser.Username, config.cognito.UserPoolId)
                .then(result => {
                    this.busy = false;
                    this.alertText = 'SMS Mfa disabled for user';
                    this.alertType = 'success';
                    this.showAlert = true;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            disableSoftwareMfa()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myAwsAdmin.adminDisableSoftwareMfa(this.myAwsUser.Username, config.cognito.UserPoolId)
                .then(result => {
                    this.busy = false;
                    this.alertText = 'Software Mfa disabled for user';
                    this.alertType = 'success';
                    this.showAlert = true;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            resetUserPassword()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myAwsAdmin.adminResetUserPassword(this.myAwsUser.Username, config.cognito.UserPoolId)
                .then(result => {
                    this.busy = false;
                    this.alertText = 'Password reset for user';
                    this.alertType = 'success';
                    this.showAlert = true;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            resendUserInvitation()
            {
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myAwsAdmin.adminCreateNewUser(this.myuser.email, config.cognito.UserPoolId, [], 'RESEND')
                .then(result => {
                    this.busy = false;
                    this.alertText = 'Invitation resent to user';
                    this.alertType = 'success';
                    this.showAlert = true;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            }            
        }
    }

</script>