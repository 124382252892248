<template>
    <v-container v-if="$ability.can('access', 'settings')">
        <data-table-card
        title="Mandatory Training"
        :data_items="mandatory_training"
        :data_headers="mandatoryTrainingTableHeaders"
        :edit_button="$ability.can('edit', 'training')"
        :delete_button="$ability.can('edit', 'training')"
        :add_button="$ability.can('edit', 'training')"
        :restore_button="$ability.can('edit', 'training')"
        showDeletedItemsCheckbox
        @deleteItem="deleteMandatoryTraining"
        @editItem="editMandatoryTraining"
        @addItem="addMandatoryTraining"
        @showDeleted="getMandatoryTrainings"
        @restoreItem="restoreMandatoryTraining"> 
        </data-table-card>
        <dialog-form
        persistent
        v-model="showMandatoryTrainingDialog"
        :fields="mandatoryTrainingFields"
        :title="mandatoryTrainingDialogTitle"
        :closeButton="mandatoryTrainingDialogButtons.close"
        :saveContentButton="mandatoryTrainingDialogButtons.save"
        @close="showMandatoryTrainingDialog = false"
        @saveContent="saveMandatoryTraining">            
        </dialog-form>
        <v-snackbar
        top
        v-model="showAlert"
        :color="alertType">
            {{alertText}}
            <template v-slot:action="{ attrs }">
                <v-btn
                @click="showAlert = false"                
                icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>            
        <v-overlay
          :absolute="false"
          :opacity="0.45"
          :value="busy"
          :z-index="1000">
            <v-progress-circular
                indeterminate
                color="primary"
                size="100"
                width="12"
            ></v-progress-circular>
        </v-overlay>   
    </v-container>
</template>

<script>

    import DataTableCard from '../../../components/EpDataTableCard';
    import DialogForm from '../../../components/EpDialogForm';

    const blankTraining = {
        id: null,
        displayed_as: null,
        shortcode: null,
        deleted_at: null
    }

    export default{
        name: "MandatoryTraining",

        data: function(){
            return {
                mandatory_training: [],
                mandatoryTrainingTableHeaders: config.training.mandatoryTrainingTableHeaders,
                myEos: new EosPortalApi(config.api.root),
                trainingEditing: null,
                showAlert: false,
                alertType: 'success',
                alertText: null,
                busy: false,
                showMandatoryTrainingDialog: false,
                mandatoryTrainingFields: {
                    shortcode: {
                        type: "text",
                        label: "Shortcode",
                        model: null
                    },
                    displayed_as: {
                        type: "text",
                        label: "Name",
                        model: null
                    }
                },
                mandatoryTrainingDialogButtons: config.dialogButtons,
                mandatoryTrainingDialogTitle: "New Mandatory Training"
            }
        },

        components: {
            DialogForm,
            DataTableCard
        },

        mounted(){
            this.myEos.setAuthToken(this.$store.state.session.session.idToken.jwtToken);  
            this.getMandatoryTrainings();          
        },

        methods: {
            getMandatoryTrainings(withTrashed = false){
                if(!this.busy)  {
                    this.busy = true;
                }  
                this.myEos.MandatoryTrainings().WithTrashed(withTrashed).get()
                .then(({data}) => {
                    this.busy = false;
                    this.mandatory_training = data;
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });

            },
            addMandatoryTraining(){
                this.trainingEditing = blankTraining;
                this.mandatoryTrainingFields.displayed_as.model = null;
                this.mandatoryTrainingFields.shortcode.model = null;
                this.mandatoryTrainingDialogTitle = "New Mandatory Training";
                this.showMandatoryTrainingDialog = true;
            },
            editMandatoryTraining(training){
                this.trainingEditing = training;
                this.mandatoryTrainingFields.displayed_as.model = training.displayed_as;
                this.mandatoryTrainingFields.shortcode.model = training.shortcode;
                this.mandatoryTrainingDialogTitle = "Edit Mandatory Training";
                this.showMandatoryTrainingDialog = true;
            },
            saveMandatoryTraining(training){
                training = this.mapDialogReturnedFields(training);
                if(training.id === null)
                    this.saveNewMandatoryTraining(training);
                else
                    this.updateMandatoryTraining(training);
            },
            saveNewMandatoryTraining(training){
                if(!this.busy)  {
                    this.busy = true;
                }  
                this.myEos.MandatoryTraining().put(training)
                .then(({data}) => {
                    this.busy = false;
                    this.showMandatoryTrainingDialog = false;
                    this.mandatory_training.splice(0,0, data);
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },
            updateMandatoryTraining(training){
                if(!this.busy)  {
                    this.busy = true;
                }
                this.myEos.MandatoryTraining(this.trainingEditing.id)
                .patch(this.trainingEditing)
                .then(({data}) => {
                    this.busy = false;
                    this.showMandatoryTrainingDialog = false;
                    this.updateMandatoryTrainingTable(data);
                })
                .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },
            deleteMandatoryTraining(training){
                if(!this.busy)  {
                    this.busy = true;
                } 
                this.myEos.MandatoryTraining(training.id)
                .delete()
                .then(({data}) => {
                    this.busy = false;
                    this.removeMandatoryTrainingFromTable(training.id);
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },
            restoreMandatoryTraining(training){
                if(!this.busy)  {
                    this.busy = true;
                } 
                this.myEos.MandatoryTraining(training.id)
                .restore()
                .then(({data}) => {
                    this.busy = false;
                    this.updateMandatoryTrainingTable(data);
                })
                 .catch(err => {
                    this.busy = false;
                    this.alertText = this.displayApiErrors(err);
                    this.alertType = 'error';
                    this.showAlert = true;
                });
            },

            mapDialogReturnedFields(returnedFields)
            {
                Object.entries(returnedFields).forEach(([key, value]) => {
                    if(this.trainingEditing.hasOwnProperty(key))
                        this.trainingEditing[key] = value.model;
                });
                return this.trainingEditing;
            },

            updateMandatoryTrainingTable(training)
            {
                var index = this.mandatory_training.findIndex(item => {
                    return item.id === training.id;
                });
                if(index !== -1)
                    this.mandatory_training.splice(index, 1, training);
            },

            removeMandatoryTrainingFromTable(id)
            {
                var index = this.mandatory_training.findIndex(item => {
                    return item.id === id;
                });
                if(index !== -1)
                    this.mandatory_training.splice(index, 1);
            }
        }
    }

</script>