import { AbilityBuilder } from '@casl/ability';

const defineAbilityFor = (user, abilityArray) => {
    const { can, rules } = new AbilityBuilder();

    if(!user)
        return rules;
    var permission = user.permission.map(perm => {
        return perm.displayed_as;
    });
    if(permission.includes('View Appointments'))
        can('view', 'appointments');
    if(permission.includes('Access Settings'))
        can('access', 'settings');
    if(permission.includes('Edit Other Users'))
        can('edit', 'other users');
    if(permission.includes('View Reports'))
        can('view', 'reports');
    if(permission.includes('Is Super User'))
        can('manage', 'all');
    if(permission.includes('View Clinics'))
        can('view', 'clinics');
    if(permission.includes('Edit Settings'))
        can('edit', 'settings');
    if(permission.includes('Edit Clinics'))
        can('edit', 'clinics');
    if(permission.includes('Reconcile Clinics'))
        can('reconcile', 'clinics');
    if(permission.includes('View Reconciliations'))
        can('view', 'reconciliations');
    if(permission.includes('Edit Referrals'))
        can('edit', 'referrals');
    if(permission.includes('View Resources'))
        can('view', 'resources');
    if(permission.includes('Edit Leave'))
        can('edit', 'leave');
    if(permission.includes('Edit Training'))
        can('edit', 'training');

    return rules;
};

export {defineAbilityFor};
export default defineAbilityFor;